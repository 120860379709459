/**
 * @classdesc Abstract base class for the model needed in ShowContentItemAlgorithm
 */
class ShowContentItemModel {
	constructor() { }

	/**
	 * Returns the content item ID of the content item that is at the given scenePath and is closest in the
	 * ordered content item list to the currently display content item (prefering same listing over the full list).
	 * @param {string} scenePath
	 * @param {string=} currentContentItemId
	 * @returns {string=} contentItemId - Null if there are no other content items in this scenePath.
	 */
	getNearestContentItemIdFromContentItemList(scenePath, currentContentItemId) {
		return null;
	}
}

export { ShowContentItemModel }
