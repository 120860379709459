
class GuideState {
	constructor(clientInstanceId) {
		this.clientInstanceId = clientInstanceId;
		this.followingMe = []; // clientInstanceIds
		this.guidingMe = null; // clientInstanceId
		this.lastReceivedTelemetryTimestamp = -1; // Numeric; in UTC seconds with milliseconds.
		this.lastReceivedTelemetry = null;
		this._initializeGuideFollow();

		/**
		 * Hook this to receive callback when guide/follow state for this client changes.
		 * ClientInstanceIds of who's guiding this client (or null) and who's following this client are sent on change.
		 * {followingMe: [string], guidingMe: string=}
		 */
		this.onGuideFollowChanged = function(_guideFollow) { };
	}

	follow(clientInstanceId) {
		this.guideFollow.following = { clientInstanceId: clientInstanceId, timestamp: this.lastReceivedTelemetryTimestamp };
		return this;
	}

	guideAll() {
		this.guideFollow.guiding = { isGuiding: true, timestamp: this.lastReceivedTelemetryTimestamp };
		return this;
	}

	/**
	 * Received the state of the entire room.
	 * @param {object} data {timestamp:, room: {id:, created:, client_instances: {'abc': {...}, 'def': {...}}}}
	 * @return {GuideState} this
	 */
	processReceivedRoomState(data) {
		if (!data || !data.room || !data.room.client_instances) {
			return this;
		}
		this.lastReceivedTelemetry = data;
		this.lastReceivedTelemetryTimestamp = _parseTimestamp(data.timestamp);
		this.lastReceivedTelemetry.localTimestamp = Date.now() * .001;
		this._processGuideFollow(data.room.client_instances);
		return this;
	}

	stopFollowing() {
		this.guideFollow.following = { clientInstanceId: '', timestamp: this.lastReceivedTelemetryTimestamp };
		return this;
	}

	stopGuidingAll() {
		this.guideFollow.following = { clientInstanceId: '', timestamp: this.lastReceivedTelemetryTimestamp };
		this.guideFollow.guiding = { isGuiding: false, timestamp: this.lastReceivedTelemetryTimestamp };
		return this;
	}

	/**
	 * Apply guide-follow rules to the map of clientInstanceId => followingClientInstanceIdOrNull.
	 * Basically run the rules in deterministic order to modify the "who's following/guiding who" state.
	 * Each participant only submits their desired guide/follow state to the server at some point in time.
	 * @param {object[]} rules
	 * @param {object} gfMap Map updated in place. Expected to already have every clientInstanceId in it (with null for who they're following).
	 * @return {GuideFollow} this
	 */
	_applyGuideFollowRules = function (rules, gfState) {
		rules.forEach(function (rule) {
			if (rule.followRule) {
				const followingId = rule.following;
				const followerId = rule.id;
				if (!followingId || !(followingId in gfState) || (followingId === followerId)) {
					gfState[followerId] = null; // Stopped following (or never was).
				} else {
					gfState[followerId] = followingId; // Started following.
				}
			} else {
				const guiderId = rule.id;
				if (rule.guiding) {
					for (const followerId in gfState) {
						if (followerId !== guiderId) {
							gfState[followerId] = guiderId; // Started guiding.
						}
					}
				} else {
					for (const followerId in gfState) {
						if (gfState[followerId] === guiderId) {
							gfState[followerId] = null; // Stopped guiding (or never was).
						}
					}
				}
			}
		});
		return this;
	}

	/**
	* Look at the state of the room (from telemetry server data), and run the guide-follow rules as specified by each,
	* to build a map of who's following and guiding who.
	* Rules are ordered deterministically so that each person seeing the room broadcast can turn that into the
	* same "guide-follow" map.
	* @private
	* @param {object} clientInstances an object from json like {'client-instance-id-1': {guide_follow:, view:, ...}, 'client-instance-id-n': {...}}
	* @return {object} gfState guide-follow state based on the incoming room state broadcast.
	*/
	_buildGuideFollowMap(clientInstances) {
		const gfState = {}; // clientInstanceId => clientInstanceIdBeingFollowed
		const rules = [];

		// Push local rule (what this client instance has set, but possibly not yet sent).
		// Everyone will see the change shortly, but this makes the UI changes later on this loop be more responsive
		// (not waiting for the next telemetry send _then_ wait for the response before updating UI state).
		gfState[this.clientInstanceId] = null;
		this._pushGuideFollowRules(rules, this.clientInstanceId, this.guideFollow);

		for (const [clientInstanceId, clientInstance] of Object.entries(clientInstances)) {
			// In building the map, ignore the last broadcast room state's version of _this_ browser's state,
			// and skip any clients that aren't providing correct guide/follow data for themselves.
			if (!clientInstance || (clientInstanceId === this.clientInstance)) { continue; }
			const gf = clientInstance.guide_follow;
			if (!gf || !gf.following || !gf.guiding) { continue; }

			gfState[clientInstanceId] = null;
			this._pushGuideFollowRules(rules, clientInstanceId, gf);
		}

		this._sortGuideFollowRules(rules);
		this._applyGuideFollowRules(rules, gfState);

		return gfState;
	}

	_initializeGuideFollow() {
		/**
		 * How this client instance perceives its guide/follow state in the room.
		 */
		this.guideFollow = {
			following: {
				/**
				 * Who this client is following, whether due to that client guiding the whole room or this client
				 * deciding to follow someone explicitly.
				 */
				clientInstanceId: '',

				/**
				 * The most recent time this client _explicitly_ decide to start or stop following someone.
				 * Numeric; in UTC seconds with milliseconds.
				 */
				timestamp: -1
			},
			guiding: {
				/**
				 * Was the most recent decision by this participant to guide or to cancel/stop guiding.
				 */
				isGuiding: false,
				/**
				 * The most recent time this client _explicitly_ attempted to guide the entire room or to stop guiding (whoever was following).
				 * Numeric; in UTC seconds with milliseconds.
				 */
				timestamp: -1
			}
		};
		return this;
	}

	/**
	 * Determine if this client is guiding or following and if anyone is following this client.
	 * @private
	 * @param {object} clientInstances an object from json like {'client-instance-id-1': {guide_follow:, view:, ...}, 'client-instance-id-n': {...}}
	 * @return {GuideFollow} this
	 */
	_processGuideFollow(clientInstances) {
		const gfState = this._buildGuideFollowMap(clientInstances);

		const followingMe = [];
		for (const followerClientInstanceId in gfState) {
			if (gfState[followerClientInstanceId] === this.clientInstanceId) {
				followingMe.push(followerClientInstanceId);
			}
		}

		const guidingMe = gfState[this.clientInstanceId];

		return this._updateStateAndNotifyOfChange(guidingMe, followingMe);
	}

	/**
	 * Push the rules from the specified clientInstance into the rules list.
	 * Each rule is "what a particular client instance last explicitly tried to do in terms of guiding or following".
	 * @param {object[]} rules Array of rules that gets appended to.
	 * @param {object} clientInstanceId
	 * @param {object} gf The {guiding: {timestamp, isGuiding}} or {following: {timestamp, clientInstanceId}} for that client instance.
	 * @return {GuideFollow} this
	 */
	_pushGuideFollowRules(rules, clientInstanceId, gf) {
		rules.push({
			id: clientInstanceId,
			ts: gf.guiding.timestamp,
			followRule: false,
			guiding: gf.guiding.isGuiding
		});
		rules.push({
			id: clientInstanceId,
			ts: gf.following.timestamp,
			followRule: true,
			following: gf.following.clientInstanceId
		});
		return this;
	}

	/**
	 * Sort the guide-follow rules deterministically (so everybody has the same view).
	 * The most important part is by timestamp.
	 * The rest is just for the sake of being deterministic.
	 * @param {object[]} rules Sorted in place.
	 * @return {AcTelemetryClient} this
	 */
	_sortGuideFollowRules(rules) {
		rules.sort(function (a, b) {
			if (a.ts < b.ts) { return -1; }
			if (a.ts > b.ts) { return 1; }
			if (a.id < b.id) { return -1; }
			if (a.id > b.id) { return 1; }
			if (a.followRule && !b.followRule) { return -1; }
			if (!a.followRule && b.followRule) { return 1; }
			return 0;
		});
		return this;
	}

	_updateStateAndNotifyOfChange(guidingMe, followingMe) {
		if ((guidingMe !== this.guidingMe) || (followingMe.sort().join(' ') !== this.followingMe.sort().join(' '))) {
			this.guidingMe = guidingMe;
			this.followingMe = followingMe;
			this.onGuideFollowChanged({ guidingMe: guidingMe, followingMe: followingMe });
		}
		return this;
	}
}

/**
 * Parse a timestamp in the possible formats used in telemetry
 * @param {(number|string)} timestamp Number of seconds (with subsecond part) since epoch UTC, or string in ISO 8601
 * format or some other format supported by Date.parse().
 * @return {number} NaN if the input param can't be parsed otherwise seconds (with subsecond part) since epoch UTC.
 */
function _parseTimestamp(timestamp) {
	let ts = NaN;
	if ('number' === typeof (timestamp)) {
		const d = new Date(0);
		d.setUTCMilliseconds(timestamp * 1000.0);
		ts = d.getTime() * .001;
	} else if ('string' === typeof (timestamp)) {
		ts = Date.parse(timestamp) * .001;
	}
	return ts;
}


export { GuideState }
