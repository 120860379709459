const DEFAULT_PAUSE_WAIT_MS = 3500;
const DEFAULT_INITIAL_PAUSE_WAIT_MS = 10000;
const DEFAULT_LOCALE = 'en-US';
const DEFAULT_LOGGER = ((typeof (console) !== 'undefined') ? console : {
	debug: function () { },
	error: function () { },
	info: function () { },
	log: function () { },
	warn: function () { },
});
const CANCELLED_EVENT_NAME = 'cancelled';

function nonNegativeOrDefault(value, defaultValue) {
	if (null === value) {
		return defaultValue;
	}
	return ((+value) >= 0) ? value : defaultValue;
}

function nowEpochSeconds() {
	return (Date.now() * .001).toFixed(3);
}

function isBrokenAndroidImplementation(result) {
	for (let i = 0; i < result.length; ++i) {
		if (!result[i].isFinal) {
			return false;
		}
		for (let j = 0; j < result[i].length; ++j) {
			if (result[i][j]?.confidence != 0) {
				return false;
			}
		}
	}
	return true;
}

class WebkitSpeechToText {
	/**
	 * Using browser webkitSpeechRecognition (if available).
	 * start(callback) starts processing, then callbacks are called: onAudioStart(),
	 * onUpdate(transcriptionSoFar) and onEnd(finalTranscription, error).
	 * There's a timer to stop after silent pauses, or caller can call stop() or cancel().
	 * Supported on chrome, safari and a few others (not firefox/edge).
	 * @param {object=} options
	 * @param {number=} options.initialPauseWaitMs How long a timer will wait after audio recording starts
	 * until some audio is received.
	 * @param {number=} options.pauseWaitMs How long a pause of a silence between audio being heard until
	 * the timer kicks in
	 */
	constructor(options) {
		options ??= {};

		this._debug = !!(options?.debug);
		this._emulatorChecked = false;
		this._initialPauseWaitMs = nonNegativeOrDefault(options.initialPauseWaitMs,
			DEFAULT_INITIAL_PAUSE_WAIT_MS);

		// This is only based on the userAgent, which will screw up chrome emulator.
		this._isAndroid = (typeof (options.isAndroid) !== 'undefined') ? !!options.isAndroid :
			(((typeof (navigator) !== 'undefined') && navigator) ?
				/android/i.test(navigator.userAgent) : false);

		this._locale = options.locale || (((typeof (navigator) !== 'undefined') && navigator) ? navigator?.language : null) || DEFAULT_LOCALE;
		this._logger = options.logger || DEFAULT_LOGGER;
		this._on = false;
		this._pauseWaitMs = nonNegativeOrDefault(options.pauseWaitMs, DEFAULT_PAUSE_WAIT_MS);
		this._recognizerClass = options?.recognizerClass || ((typeof(window) !== 'undefined') ? window?.webkitSpeechRecognition : null);
		this._timeoutHandle = null;
		this._transcript = '';

		// Hooks that caller can attach to.
		this.onAudioStart = () => { return false; };
		this.onUpdate = (_text) => { return false; }; // Accumulated transcription as you go.
		this.onEnd = (_text, _error) => { return false; };

		this._initializeRecognizer(options);
		this._resetDebugEventAccumulator();
	}

	/**
	 * Cancels the transcription, calling onEnd hook with empty string and 'cancelled'.
	 * @returns {WebkitSpeechToText}
	 */
	cancel() {
		return this._stop(CANCELLED_EVENT_NAME);
	}

	_debugEvent(evt, level) {
		if (!this._debug || !evt) { return this; }
		level ??= 'debug';

		const now = nowEpochSeconds();
		if ('start' === evt.type) {
			this._resetDebugEventAccumulator();
		}

		evt.timestamp = now - this._transcriptionStart;
		this._debugEvents.push(evt);

		this._logger[level]('[stt]', `on${evt.type}`, evt, evt.timestamp);
		if ('end' === evt.type) {
			this._logger[level]('[stt]', `transcriptionEvents`, JSON.stringify(this._debugEvents), now);
		}
		return this;
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognitionResultList
	_handleRecognizerResult(evt) {
		if (!this._on) { return this; }

		// Hack to detect _actual_ broken android implementation vs chrome debug-mode emulator.
		if (!this._emulatorChecked) {
			if (this._isAndroid && !isBrokenAndroidImplementation(evt.results)) {
				this._isAndroid = false; // We're actually in an emulator.
			}
			this._emulatorChecked = true;
		}

		if (this._isAndroid) {
			this._transcript = evt.results[evt.results.length - 1][0]?.transcript?.trim() || '';
		} else {
			const transcriptParts = [''];
			for (let i = 0; i < evt.results.length; ++i) {
				const result = evt.results[i];
				const part = result[0]?.transcript?.trim() || '';
				if (result.isFinal) {
					if (part) {
						transcriptParts.push(part);
					}
				} else {
					if (part) {
						transcriptParts[transcriptParts.length - 1] += ' ' + part;
					}
				}
			}
			this._transcript = transcriptParts.filter((p) => { return !!p; }).join(' ');
		}
	

		const logEvent = { type: 'result', results: [] };

		Array.from(evt.results).forEach(srr => {
			const logResult = { isFinal: srr.isFinal, items: [] };
			logEvent.results.push(logResult);
			Array.from(srr).forEach(sra => {
				logResult.items.push({ transcript: sra.transcript, confidence: sra.confidence });
			});
		});
		this._debugEvent(logEvent)
			.onUpdate(this._transcript);

		if (this._pauseWaitMs && this._pauseWaitMs !== Number.POSITIVE_INFINITY) {
			this._resetTimer(this._pauseWaitMs);
		}

		return this;
	}

	_initializeRecognizer(options) {
		if (!this.isSupported) { return this; }

		const recognizer = new this._recognizerClass();
		this._recognizer = recognizer;
		recognizer.continuous = true;
		recognizer.interimResults = true;
		recognizer.lang = this._locale;
		recognizer.maxAlternatives = 1;

		recognizer.onerror = (event) => {
			this._logger.error('[stt]', 'onerror', event.error, nowEpochSeconds());
			this._stop(event.error);
		};
		recognizer.onresult = (evt) => { this._handleRecognizerResult(evt); };
		recognizer.onstart = () => { this._debugEvent({ type: 'start' }); };
		recognizer.onend = () => {
			this._debugEvent({ type: 'end' });
			if (this._on) {
				this._on = false;
				const transcript = this._transcript;
				this._transcript = '';
				this._resetTimer();
				this._debug && this._logger.debug('[stt]', 'transcription complete', transcript, nowEpochSeconds());
				this.onEnd(transcript);
			}
		};
		recognizer.onaudiostart = () => { this._debugEvent({ type: 'audiostart' }).onAudioStart(); };
		recognizer.onspeechstart = () => { this._debugEvent({ type: 'speechstart' }); }
		recognizer.onaudioend = () => { this._debugEvent({ type: 'audioend' }); };
		recognizer.onspeechend = () => { this._debugEvent({ type: 'speechend' }) };
		return this;
	}

	get isSupported() {
		return !!this._recognizerClass;
	}

	_onTimeout() {
		this._timeoutHandle = null;
		return this._stop();
	}

	/**
	 * Accumulates speech recognition related events during an attempted transcription.
	 * @returns {}
	 */
	_resetDebugEventAccumulator() {
		this._debugEvents = [];
		this._transcriptionStart = nowEpochSeconds();
		return this;
	}

	_resetTimer(timeout) {
		timeout = Math.max(+timeout);

		if (this._timeoutHandle) {
			clearTimeout(this._timeoutHandle);
			this._timeoutHandle = null;
		}

		if (!isNaN(timeout) && timeout !== Number.POSITIVE_INFINITY) {
			this._timeoutHandle = setTimeout(() => {
				this._onTimeout();
			}, timeout);
		}
		return this;
	}

	start() {
		if (this.isSupported && !this._on) {
			this._on = true;
			this._transcript = '';
			this._resetTimer(this._initialPauseWaitMs);
			this._debug && this._logger.debug('[stt]', 'start', nowEpochSeconds());
			this._recognizer.start();
		}
		return this;
	}

	get transcript() {
		return this._transcript;
	}

	_stop(error) {
		if (this._on) {
			this._recognizer.stop();
			if (error) {
				this._on = false;
				const transcript = this._transcript;
				this._transcript = '';
				this._resetTimer();
				this._debug && this._logger.debug('[stt]', 'transcription complete', transcript, nowEpochSeconds());
				this.onEnd(transcript, error);
			}
		}
		return this;
	}

	stop() {
		this._debug && this._logger.debug('[stt]', 'user-initiated stop', nowEpochSeconds());
		return this._stop();
	}
}

export { WebkitSpeechToText };
