import { ShowContentItemModel } from './show-content-item-model.es13.js';

/**
 * @classdesc Abstract base class for the model needed in ShowContentItemAlgorithm
 */
class ShowContentItemModelMobile extends ShowContentItemModel {
	constructor(contentModel) {
		super();
		this._contentModel = contentModel;
	}

	/**
	 * Returns the content item ID of the content item that is at the given scenePath and is closest in the
	 * ordered content item list to the currently display content item (prefering same listing over the full list).
	 * @param {string} scenePath
	 * @param {string=} currentContentItemId
	 * @returns {string=} contentItemId - Null if there are no other content items in this scenePath.
	 */
	getNearestContentItemIdFromContentItemList(scenePath, currentContentItemId) {
		const contentItems = this._contentModel.contentItems;
		if (!currentContentItemId) {
			// Find first one to match scenePath.
			return contentItems.find((ci) => ci.contentPath === scenePath)?.id;
		} else {
			// Iterate forward and backward
			let contentItemIndex = contentItems.findIndex((ci) => ci.id === currentContentItemId);
			if (contentItemIndex < 0) {
				return contentItems.find((ci) => ci.contentPath === scenePath)?.id;
			}
			const forwardResult = this._find(contentItems, contentItemIndex, scenePath, 1);
			const reverseResult = this._find(contentItems, contentItemIndex, scenePath, -1);

			if (forwardResult.found) {
				if (forwardResult.sameListing === reverseResult.sameListing) {
					return (forwardResult.distance >= reverseResult.distance) ?
						forwardResult.contentItemId : reverseResult.contentItemId;
				} else {
					return forwardResult.sameListing ? forwardResult.contentItemId
						: reverseResult.contentItemId;
				}
			} else {
				return null;
			}
		}
	}

	_find(contentItems, startIndex, scenePath, inc) {
		const maxIndex = contentItems.length - 1;
		let i = startIndex;
		let distance = 0;
		let item = contentItems[i];
		let listingId = item.listingId;
		if (item.contentPath === scenePath) {
			return { found: true, distance: distance, sameListing: true, contentItemId: item.id };
		}
		while (++distance < contentItems.length) {
			i += inc;
			if (i > maxIndex) {
				i = 0;
			} else if (i < 0) {
				i = maxIndex;
			}
			item = contentItems[i];
			if (item.contentPath === scenePath) {
				return { found: true, distance: distance, sameListing: (item.listingId === listingId), contentItemId: item.id };
			}
		}
		return { found: false };
	}
}

export { ShowContentItemModelMobile }
