// External dependencies on EveryScape.SyncV2.FloorPlanViewer.Descriptor
import { getContent } from "../api/api.es6.js"
import { geocode } from "../utils/geocode.es6.js"
import { IrUtils } from "../utils/utils.es13.js"

const UNIT_TYPES = {
	AMENITY: 'Amenity',
	MARKETABLE: 'Marketable',
	UNKNOWN: 'unknown'
}

/**
 * Content model/view-model.
 * Data modeling of content (projects, listings, etc) and some view-model data about the state of the view
 * in regards to selected contentItems.
 * ABSOLUTELY NO UI LOGIC. Nothing touching the DOM.
 * No telemetry - that is a separate model.
 * View should not update this directly.
 */
class ContentModel {
	constructor(brandId, contentId) {
		this.brandId = brandId;
		this.contentId = contentId;
		this.clearContent();
	}

	get aiSettings() {
		return this.project?.ExtraJson?.AI;
	}

	get chatLeadCaptureDialogMarkdown() {
		return this.project?.ExtraJson?.ChatLeadCapture?.DialogMarkdown;
	}

	get isChatLeadCaptureEnabled() {
		return !!(this.project?.ExtraJson?.ChatLeadCapture?.Enabled);
	}

	get isRequestAccessButtonEnabled() {
		return !!(this.project?.ExtraJson?.RequestAccess?.Enabled);
	}

	get isSplashScreenEnabled() {
		return !!(this.project?.ExtraJson?.SplashScreen?.Enabled);
	}

	get splashScreenFields() {
		const visible = new Map();
		const required = new Map();
		for (const [key, value] of Object.entries(this.project?.ExtraJson?.SplashScreen?.InputFields || {})) {
			if (value.Visible) {
				const lcKey = key.toLowerCase()
				visible.set(lcKey, true);
				if (value.Required) {
					required.set(lcKey, true);
				}
			}
		}

		return {
			visible: visible,
			required: required
		}
	}

	get unitTypeFilter() {
		// return a function that takes a dynamicDataPropertyUnit as an argument,
		// and returns true if it matches the project's filter value
		return (u) => {
			const filter = this.project?.ExtraJson?.UnitTypeFilter; // Single string
			const flags = u?.Flags || []; // Array of strings
			if (!filter) { // default is !affordable
				return !flags.includes('affordable');
			} else if ('all' === filter) {
				return true;
			} else {
				return flags.includes(filter);
			}
		};
	}

	get projectId() {
		return this.project?.Id;
	}

	get requestAccessButtonText() {
		return this.project?.ExtraJson?.RequestAccess?.ButtonText;
	}

	get requestAccessDialogMarkdown() {
		return this.project?.ExtraJson?.RequestAccess?.DialogMarkdown;
	}

	/**
	 * The index in the listing-specific content item list (of the currently selected item).
	 */
	get selectedListingSelectedContentItemIndex() {
		const ci = this.contentItems[this.selectedContentItemIndex];
		return this.selectedListingContentItems.findIndex((el) => el === ci);
	}

	get useAi() {
		const aiEnabled = window.siteSettings.InfinityyAiEnabled;
		const projectAiEnabled = this.project?.ExtraJson?.AIEnabled;
		return (
			aiEnabled === "all" ||
			aiEnabled === "project" && projectAiEnabled
		);
	}

	clearContent() {
		this.buildings = new Map();
		this.campaignId = null;
		this.content = null;
		this.contentFloorplans = null;
		this.contentItems = []; // All of them for all buildings/listings.
		this.contentPathLinks = new Map();
		this.displayOrder = { Properties: [] };
		this.explicitDisplayOrder = false;
		this.floorPlanDescriptors = null;
		this.listingPropertyMap = new Map();
		this.listingUdgMap = new Map();
		this.project = null;
		this._propertyUnitDynamicData = new Map();
		this.selectedContentItemIndex = 0; // Index in contentItems (whole list).
		this.selectedListingId = null;
		this.selectedListingContentItems = []; // Only the items for the currently selected listing.
	}

	getContentItem(contentItemId) {
		return this.contentItems.find((ci) => ci.id === contentItemId);
	}

	getContentName() {
		return this.content ? this.content.Label : '';
	}

	getDefaultContentItemForBuilding(buildingId) {
		let listingId;
		const displayOrderBuilding = this.displayOrder.Properties.find(p => p.Id === buildingId && p.DefaultListingId);
		if (displayOrderBuilding) {
			listingId = displayOrderBuilding.DefaultListingId;
		} else {
			this.listingPropertyMap.forEach((b, l) => {
				if (b.Id === buildingId && !listingId) {
					listingId = l;
				}
			});
		}
		return this.contentItems.find((ci) => ci.listingId == listingId);
	}

	getDynamicListingData(listing) {
		return this._propertyUnitDynamicData.get(+(listing?.PropertyUnitId));
	}

	getListing(listingId) {
		if ((null === listingId) || !this.content || !this.content.Listings) { return null; }
		const marketableListing = this.content.Listings.find((l) => l.Id == listingId);
		return (marketableListing ? marketableListing : this.listingUdgMap.get(listingId));
	}

	getSelectedBuilding() {
		const listing = this.getSelectedListing();
		return listing ? this.buildings.get(listing.PropertyId) : null;
	}

	getSelectedContentItem() {
		if ((this.selectedContentItemIndex < 0) || (this.selectedContentItemIndex > this.contentItems.length)) { return null; }
		return this.contentItems[this.selectedContentItemIndex];
	}

	getSelectedListing() {
		return this.getListing(this.selectedListingId);
	}

	getSelectedListingName() {
		const sl = this.getSelectedListing();
		return sl ? sl.Name : '';
	}

	/**
	 * Load content from the server into this model.
	 * @return {Promise}
	 */
	loadContent() {
		return new Promise((resolve, reject) => {
			getContent(this.brandId, this.contentId).then((data) => {
				this.clearContent();
				if (data.Campaign) {
					this.contentId.type = 'campaign';
					this.contentId.id = data.Campaign.PublicId;
				} else /* data.Project */ {
					this.contentId.type = 'project';
					this.contentId.id = data.Project.PublicId;
				}
				const project = cloneObject(('project' === this.contentId.type) ? data.Project : data.Campaign.Project);
				try {
					project.ExtraJson = JSON.parse(project.ExtraJson)
				} catch (ex) {
					project.ExtraJson = {};
					console.error('While parsing project ExtraJson', ex);
				}
				this.project = project;
				IrUtils.deepStripNullies(project, { maxDepth: 8 });
				this.campaignId = 'campaign' === contentId.type ? IrUtils.normalizeContentId(contentId?.id) : null;
				this.content = project;
				this.contentFloorplans = data.Floorplans;
				this.displayOrder = project.DisplayOrderJson ? JSON.parse(project.DisplayOrderJson) : { Properties: [] };
				this.explicitDisplayOrder = !!project.DisplayOrderJson;

				this.selectedContentItemIndex = 0;
				const listings = project.Listings || [];

				this._setProjectListingDefault();

				// Invert data returned from Listings->PropertyUnits->Properties to Properties->PropertyUnits->Listings
				for (const listing of listings) {
					if (listing && listing.PropertyUnit && listing.PropertyUnit.Property) {
						listing.PropertyUnitId = listing.PropertyUnit.Id;
						const property = listing.PropertyUnit.Property;
						let building = this.buildings.get(property.Id);
						this.listingPropertyMap.set(listing.Id, property);
						this._addPropertyToDisplayOrderIfMissing(property, listing);
						let buildingAddress = null;
						if (!building) {
							building = property;
							building.UnitTypes = {};
							buildingAddress = this._getBuildingAddress(building);
							this._geocodeBuildingAddress(building, buildingAddress);
							this.buildings.set(building.Id, building);
							this._addListingUdgMapItems(building);
						}
						const listingAddress = this._getListingAddress(listing);
						this._geocodeListingAddress(listing, listingAddress, building, buildingAddress);
						invertPropertyUnitListing(listing, building);
					}
				}
				this._setPropertyListingDefaults();
				this._setFloorPlanDescriptors();
				this._setContentItemList();
				this._setSelectedListingContentItems();
				this._setContentPathLinks();
				this._setPropertyUnitDynamicData();

				resolve(this);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	setSelectedListingId(selectedListingId) {
		this.selectedListingId = selectedListingId;
		return this._setSelectedListingContentItems();
	}

	_setSelectedListingContentItems() {
		this.selectedListingContentItems = this.contentItems.filter((el) => el.listingId == this.selectedListingId);
		return this;
	}

	/**
	 * Add a connection between contentPath and a reference to it (listing and listingcontentitem/contentsetitem)
	 * to `this.contentPathLinks` (a contentPath => reference[] map).
	 * @param {string} contentPath non-empty contentpath (empty is ignored).
	 * @param {{listing: number, reference: {type: number, id: number}}} referringContentSetItem
	 * @return {ContentModel}
	 */
	_addContentPathLink(contentPath, referringContentSetItem) {
		if (!contentPath) {
			return this;
		}

		let existing = this.contentPathLinks.get(contentPath);
		if (!existing) {
			this.contentPathLinks.set(contentPath, existing = []);
		}
		existing.push(referringContentSetItem);
		return this;
	}

	_addListingUdgMapItems(building) {
		(building.UserDefinedGroups || []).forEach((pudg) => {
			if (!pudg.UserDefinedGroup) { return; }
			(pudg.UserDefinedGroup.ListingUserDefinedGroups || []).forEach((ludg) => {
				if (ludg.Listing) {
					if (!ludg.Listing.PropertyId) {
						ludg.Listing.UserDefinedGroupId = ludg.UserDefinedGroupId;
						ludg.Listing.PropertyId = building.Id;
					}
					this.listingUdgMap.set(ludg.Listing.Id, ludg.Listing);
				}
			});
		});
		return this;
	}

	_addPropertyToDisplayOrderIfMissing(property, listing) {
		let propertyOrder = null;
		const index = this.displayOrder.Properties.findIndex(p => p.Id === property.Id);

		if (index >= 0) {
			propertyOrder = this.displayOrder.Properties[index];
		} else {
			propertyOrder = {
				Id: property.Id,
				Listings: {
					Marketable: [],
					Amenities: []
				}
			};
			this.displayOrder.Properties.push(propertyOrder);
		}
		return this._addListingDisplayOrderIfMissing(listing, propertyOrder);
	}

	_addListingDisplayOrderIfMissing(listing, propertyOrder) {
		const unitType = listing.PropertyUnit.UnitType ? listing.PropertyUnit.UnitType : UNIT_TYPES.UNKNOWN;
		const subArray = (unitType.toLowerCase() === UNIT_TYPES.AMENITY.toLowerCase()) ? propertyOrder.Listings.Amenities : propertyOrder.Listings.Marketable;
		if (!subArray.includes(listing.Id)) { subArray.push(listing.Id); }
		return this;
	}

	_setProjectListingDefault() {
		if (this.content?.Listings?.length > 0) {
			if (this.displayOrder?.ProjectDefaultListingId && this.content?.Listings?.some((l) => l.Id == this.displayOrder?.ProjectDefaultListingId)) {
				this.selectedListingId = this.displayOrder.ProjectDefaultListingId;
			} else {
				this.selectedListingId = this.content.Listings[0].Id;
			}
		}
		return this;
	}

	_setPropertyListingDefaults() {
		this.displayOrder.Properties.filter(p => !p.DefaultListingId || !this.content?.Listings?.some(l => l.Id === p.DefaultListingId)).forEach(property => {
			if (property?.Listings?.Amenities?.length > 0) {
				property.DefaultListingId = property.Listings.Amenities[0];
			} else if (property?.Listings?.Marketable?.length > 0) {
				property.DefaultListingId = property.Listings.Marketable[0];
			}
		});
		return this;
	}

	_setPropertyUnitDynamicData() {
		this._propertyUnitDynamicData = new Map();
		const unitTypeFilter = this.unitTypeFilter;
		if (this.content.PropertyDynamicData) {
			this.content.PropertyDynamicData.forEach((dd) => {
				try {
					const propertyIn = JSON.parse(dd.DynamicDataJson);
					propertyIn.PropertyUnits.forEach((propertyUnitIn) => {
						const propertyUnitId = propertyUnitIn.Id;
						let propertyUnit = this._propertyUnitDynamicData.get(propertyUnitId);
						if (!propertyUnit) {
							propertyUnit = {
								id: +propertyUnitId,
								property: {
									id: propertyIn.PropertyId,
									SourcePropertyId: propertyIn.SourcePropertyId,
									RealPageSubdomainId: propertyIn.RealPageSubdomainId
								},
								units: [],
								urlOverride: propertyUnitIn.UrlOverride
							};
							this._propertyUnitDynamicData.set(propertyUnitId, propertyUnit);
						}
						propertyUnitIn.Units.forEach((unitIn) => {
							if (unitTypeFilter(unitIn)) {
								propertyUnit.units.push($.extend({}, unitIn, { propertyUnit: propertyUnit }));
							}
						});
					});
				} catch (ex) {
					console.error('Error parsing property dynamic data.', ex);
				}
			});
		}
	}
	_geocodeBuildingAddress(building, buildingAddress) {
		building._onGeocoded = function () { return true; };
		geocode(building.LocationJson ? JSON.parse(building.LocationJson) : null, buildingAddress, null, null, (location) => {
			building.location = location;
			building._onGeocoded();
		});
		return this;
	}

	_geocodeListingAddress(listing, listingAddress, building, buildingAddress) {
		listing._onGeocoded = function () { return true; };
		geocode(listing.LocationJson ? JSON.parse(listing.LocationJson) : null, listingAddress, building.location, buildingAddress, (location) => {
			listing.location = location;
			listing._onGeocoded();
		});
		return this;
	}

	_getBuildingAddress(building) {
		return [
			building.Address1,
			building.Address2,
			building.Address3,
			building.Address4,
			building.Locality,
			building.Region,
			building.PostalCode,
			building.Country
		].join(' ');
	}

	_getListingAddress(listing) {
		return [
			listing.AddressStreet1,
			listing.AddressStreet2,
			listing.AddressCity,
			listing.AddressState,
			listing.AddressPostalCode,
			listing.AddressCountry
		].join(' ');
	}

	_getListingContentItems(listingId) {
		const listing = this.getListing(listingId);
		let listingContentItems = [];
		((listing && listing.ListingContentItems) || []).forEach((item) => {
			listingContentItems.push({
				id: `ListingContentItem:${item.Id}`,
				contentPath: getContentPathFromLCI(item),
				contentReferenceId: item.Id,
				contentReferenceType: 'ListingContentItem',
				description: item.Description,
				displayOrder: item.DisplaySequence,
				listingId: listingId,
				name: item.Name,
				position: defaultPositionJsonToArray(item.DefaultPositionJson),
				thumbnailUrl: item.ThumbnailUrl,
				sequence: item.DisplaySequence
			});
		});
		((listing && listing.ListingUrls) || []).forEach((listingUrl) => {
			listingContentItems.push({
				id: `ListingUrl:${listingUrl.Id}`,
				contentPath: 'overlay://default',
				contentReferenceId: listingUrl.Id,
				contentReferenceType: 'ListingUrl',
				description: listingUrl.Description,
				displayOrder: listingUrl.DisplayOrder,
				listingId: listingId,
				name: listingUrl.Label,
				position: [],
				thumbnailUrl: listingUrl.ThumbnailUrl,
				url: listingUrl.Url,
				sequence: listingUrl.DisplayOrder
			});
		});
		listingContentItems.sort(this._compareContentItems); // Per-listing sort.
		return listingContentItems;
	}

	/**
	 * Create a huge long list of all content items using the order for buildings, going through marketable
	 * listings, then amenities, then neighborhood listings.
	 * @returns {ContentModel}
	 */
	_setContentItemList() {
		this.selectedContentItemIndex = 0;
		this.contentItems = [];
		(this.displayOrder?.Properties || []).forEach((displayOrderForProperty) => {
			const building = this.buildings.get(displayOrderForProperty.Id);
			const orderedListingIds = (displayOrderForProperty.Listings?.Amenities || [])
				.concat(displayOrderForProperty.Listings?.Marketable || []);
			(building?.UserDefinedGroups || []).forEach((udg) => {
				(udg?.UserDefinedGroup?.ListingUserDefinedGroups || [])
					.forEach((ludg) => {
						orderedListingIds.push(ludg.ListingId);
					});
			});
			orderedListingIds.forEach((listingId) => {
				this.contentItems = this.contentItems.concat(this._getListingContentItems(listingId));
			});
		});

		return this;
	}

	_compareContentItems(a, b) {
		if (!a && !b) { return 0; }
		else if (!a) { return 1; }
		else if (!b) { return -1; }

		if (!isNaN(a.sequence)) {
			if (!isNaN(b.sequence)) {
				if (a.sequence < b.sequence) return -1;
				if (a.sequence > b.sequence) return 1;
			} else {
				return -1;
			}
		} else if (b.sequence) {
			return 1;
		}

		if (a.contentReferenceType) {
			const objTypeComp = a.contentReferenceType.localeCompare(b.contentReferenceType);
			if (objTypeComp != 0) return objTypeComp;
		} else if (b.contentReferenceType) {
			return -1
		}

		if (a.contentReferenceId < b.contentReferenceId) return -1;
		if (a.contentReferenceId > b.contentReferenceId) return 1;

		return 0;
	}

	_setContentPathLinks() {
		this.content.Listings.forEach((listing) => {
			((listing && listing.ListingContentItems) || []).forEach((lci) => {
				this._addContentPathLink(lci.ContentId, {
					listingId: lci.ListingId, reference: {
						type: 'listingcontentitem',
						id: lci.Id
					}
				});
			});
		});
		this.buildings.forEach((building) => {
			if (!building.UserDefinedGroups) { return; }
			building.UserDefinedGroups.forEach((pudg) => {
				if (!pudg.UserDefinedGroup?.ListingUserDefinedGroups) { return; }
				pudg.UserDefinedGroup.ListingUserDefinedGroups.forEach((ludg) => {
					if (!ludg.Listing?.ListingContentItems) { return; }
					ludg.Listing.ListingContentItems.forEach((lci) => {
						this._addContentPathLink(lci.ContentId, {
							listingId: lci.ListingId, reference: {
								type: 'listingcontentitem',
								id: lci.Id
							}
						});
					});
				});
			});
		});
		return this;
	}

	_setFloorPlanDescriptors() {
		this.floorPlanDescriptors = [];
		this.contentFloorplans?.forEach((floorPlan) => {
			const descriptor = EveryScape.SyncV2.FloorPlanViewer.Descriptor({
				defaultCenterX: floorPlan.DefaultCenterX,
				defaultCenterY: floorPlan.DefaultCenterY,
				defaultZoom: floorPlan.DefaultZoom,
				id: 'FloorPlan:' + floorPlan.Id,
				imageUrl: floorPlan.ImageUrl,
				name: floorPlan.Name,
				naturalHeight: floorPlan.NaturalHeight,
				naturalWidth: floorPlan.NaturalWidth
			});

			floorPlan.ContentPositions.forEach((pos) => {
				const csi = pos.ContentSetItem;
				if (csi) {
					descriptor.addPosition(EveryScape.SyncV2.FloorPlanViewer.Descriptor.Position({
						contentPath: csi.ContentPath,
						contentType: csi.ContentTypeCode,
						displayIcon: pos.DisplayIcon,
						heading: pos.Heading,
						name: csi.Name,
						x: pos.PositionX,
						y: pos.PositionY
					}));
				}
			});
			this.floorPlanDescriptors.push(descriptor);
		});
		return this;
	}
}

function cloneObject(o) {
	return JSON.parse(JSON.stringify(o));
}

function defaultPositionJsonToArray(json) {
	let obj = null;
	if (json) { obj = JSON.parse(json); }
	if (!obj || ('object' !== typeof (obj))) { obj = {}; }

	// format: [ 1.23, 4.56, 7.89 ]
	if (Array.isArray(obj)) { return obj.map(parseFloat); }

	// format: {"hlookat":0,"vlookat":0,"fov":1} (EveryScape Panorama)
	if (isNumeric(obj.hlookat) && isNumeric(obj.vlookat) && isNumeric(obj.fov)) {
		return [obj.hlookat, obj.vlookat, obj.fov].map(parseFloat);
	}

	// format: {'page': 1, 'zoom': 1, 'x': 0, 'y': 0 } (PDF)
	if (isNumeric(obj.page) && isNumeric(obj.zoom) && isNumeric(obj.x) && isNumeric(obj.y)) {
		return [obj.page, obj.zoom, obj.x, obj.y].map(parseFloat);
	}

	// format: {'time': 0, 'playing': 1, 'x': 0, 'y': 0 } (Video)
	if (isNumeric(obj.time) && isNumeric(obj.playing) && isNumeric(obj.x) && isNumeric(obj.y)) {
		return [obj.time, obj.playing, obj.x, obj.y].map(parseFloat);
	}

	// format: {'px': 0, 'py': 0, 'pz': 0, 'rx': 0, 'ry': 0, 'zoom': 0 } (Matterport)
	if (isNumeric(obj.px) && isNumeric(obj.py) && isNumeric(obj.pz) && isNumeric(obj.rx) && isNumeric(obj.ry) && isNumeric(obj.zoom)) {
		return [obj.px, obj.py, obj.pz, obj.rx, obj.ry, obj.zoom].map(parseFloat);
	}

	// format: {'x': 0, 'y': 0, 'z': 1 } (2D Content)
	if (isNumeric(obj.x) && isNumeric(obj.y) && isNumeric(obj.z)) {
		return [obj.x, obj.y, obj.z].map(parseFloat);
	}

	return []; // Invalid format.
}

function getContentPathFromLCI(lci) {
	if (lci.ContentSetItem) {
		return lci.ContentSetItem.ContentPath;
	}
	switch (lci.ContentTypeCode) {
		case 'panorama':
		case 'tour':
		case 'script':
		case 'videoscape':
		case 'poi':
			return 'everyscape://' + lci.ContentTypeCode + '/' + lci.ContentId;
		case 'image':
			prefix = 'olio://';
			idparts = lci.ContentId.split('/');
			return 'olio://container/' + idparts[0] + '/file/' + idparts[1];
		default:
			return null;
	}
}

function invertPropertyUnitListing(listing, b) {
	let pu = listing.PropertyUnit;
	delete (pu.Property);

	if (!pu.UnitType) { pu.UnitType = UNIT_TYPES.UNKNOWN; }
	let ut = b.UnitTypes[pu.UnitType.toLowerCase()];

	// Allocate the unitType if it doesn't already exist
	if (!ut) {
		ut = new Map();
		b.UnitTypes[pu.UnitType.toLowerCase()] = ut;
	}

	let existingPU = ut.get(pu.Id);
	if (existingPU) { pu = existingPU; }

	if (!pu.Listings || !Array.isArray(pu.Listings)) { pu.Listings = []; }
	pu.Listings.push(listing);
	delete (listing.PropertyUnit);

	if (!pu.UnitType) { pu.UnitType = UNIT_TYPES.MARKETABLE; }
	listing.PropertyUnitType = pu.UnitType;

	ut.set(pu.Id, pu);
	return listing;
}

function isNumeric(val) {
	return ((null !== val) && !isNaN(+val));
}

export { ContentModel, UNIT_TYPES }
