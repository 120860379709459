import IGuideViewer from "@iguide/viewer"
import { IrUtils } from "../utils/utils.es13.js"
import { Spherical } from "../utils/spherical.es13.js"

const INVALID_CONTENT_PATH = 'iguide://';
const IGUIDE = 'iguide';
const IGUIDE_COLON = IGUIDE + ':';
const TWOPI = Math.PI * 2;
const HALFPI = Math.PI * .5;
const MINUS_HALFPI = 0 - HALFPI;
const MIN_ZOOM = 0;
const MAX_ZOOM = 100;
const DEFAULT_OPTIONS = {
	apiCallTimeoutMs: 3000, // Timeout for api calls once the model's loaded
	closeSceneRadians: 0.03491, // 2 degrees
	closeSceneZoom: 1, // zoom goes from 0 to 100.
	debug: false,
	enableCloseFloorplanButton: true,
	enableFloorplanCloseOnOutsideClick: true,
	// Max time between setting the iframe src and receiving a 'ready' from the API, before we assume it failed.
	loadToReadyTimeoutMs: 8000,
	// Delay between receiving the model's ready callback and trying to move around (because it doesn't seem to
	// work right away in the ready callback).
	readyToMoveDelayMs: 800
};

/**
 * Immutable camera view details.
 * @property {number} angle - horizontal view angle in radians
 * @property {number} elevation - vertical view angle in radians (-Pi/2..Pi/2)
 * @property {number} zoom - 0..100 0 is the default
 */
class IguideCamera {
	/**
	 * Create a camera from 3 arguments angle, elevation, zoom.
	 * Or another IguideCamera or object (angle,elevation,zoom).
	 * @param {object|IguideCamera|number=} arg1 camera object or angle
	 * @param {number=} [elevation=0]
	 * @param {number=} [zoom=0]
	 */
	constructor(arg1 = 0, elevation = 0, zoom = 0) {
		if (('object' === typeof (arg1)) && (null !== arg1)) {
			this._angle = arg1.angle || 0;
			this._elevation = arg1.elevation || 0;
			this._zoom = arg1.zoom || 0;
		} else {
			this._angle = arg1 || 0;
			this._elevation = elevation || 0;
			this._zoom = zoom || 0;
		}
	}

	get angle() { return this._angle; }
	get elevation() { return this._elevation; }
	get zoom() { return this._zoom; }

	/**
	 * Returns a copy of this.
	 * @returns {IguideCamera}
	 */
	clone() {
		return new IguideCamera(this._angle, this._elevation, this._zoom);
	}

	/**
	 * Returns a normalized copy of this.
	 * @returns {IguideCamera}
	 */
	cloneNormalized() {
		return new IguideCamera(this._normalizedAngle, this._normalizedElevation, this._zoom);
	}

	extend(camera) {
		return new IguideCamera(IrUtils.firstDefined(camera?.angle, this?._angle),
			IrUtils.firstDefined(camera?.elevation, this?._elevation),
			IrUtils.firstDefined(camera?.zoom, this?._zoom));
	}

	/**
	 * @returns {boolean}
	 */
	isValid() {
		return isFinite(this._angle)
			&& (this._elevation <= HALFPI) && (this._elevation >= MINUS_HALFPI)
			&& (this._zoom >= MIN_ZOOM) && (this._zoom <= MAX_ZOOM);
	}

	toJSON() {
		return {
			angle: this._angle,
			elevation: this._elevation,
			zoom: this._zoom
		};
	}

	/**
	 * Normalize and reduce the precision of a camera angle (fixed 3 decimal places).
	 * @returns {number}
	 */
	get _normalizedAngle() {
		let angle = this._angle % TWOPI;
		if (angle < 0) { // js modulo: -7 mod 6 = -1 (not 5 - we want 5)
			angle += TWOPI;
		}
		return +(angle.toFixed(3));
	}

	/**
	 * Reduce the precision of a camera elevation (fixed 3 decimal places).
	 * @returns {number}
	 */
	get _normalizedElevation() {
		return +(this._elevation.toFixed(3));
	}
}

/**
 * Immutable description of an IGuide visible scene (model, location/position/panorama, and camera look
 * direction/zoom).
 * @property {string} viewerType
 * @property {string=} modelId
 * @property {number=} panoramaId
 * @property {IguideCamera=} camera
 */
class IguideScene {
	/**
	 * Create a scene from 3 arguments modelId, panoramaId, and camera.
	 * Or another IguideScene or object (angle,elevation,zoom).
	 * @param {object|IguideScene|number=} arg1 scene or modelId
	 * @param {number=} panoramaId
	 * @param {IguideCamera=} camera
	 */
	constructor(arg1 = null, panoramaId = null, camera = null) {
		if (('object' === typeof (arg1)) && (null !== arg1)) {
			this._modelId = arg1.modelId;
			this._panoramaId = arg1.panoramaId;
			this._camera = arg1.camera;
		} else {
			this._modelId = arg1;
			this._panoramaId = panoramaId;
			this._camera = camera;
		}
	}

	get modelId() { return this._modelId; }
	get panoramaId() { return this._panoramaId; }
	get camera() { return this._camera; }
	get viewerType() { return IGUIDE; }

	/**
	 * Returns a copy of this.
	 * @returns {IguideScene}
	 */
	clone() {
		return new IguideScene(this._modelId, this._panoramaId, this._camera);
	}

	extend(scene) {
		return new IguideScene(IrUtils.firstDefined(scene?.modelId, this._modelId),
			IrUtils.firstDefined(scene?.panoramaId, this._panoramaId),
			IrUtils.firstDefined(scene?.camera, this._camera));
	}

	toJSON() {
		return IrUtils.shallowStripNullies({
			viewerType: this.viewerType,
			modelId: this._modelId,
			panoramaId: this._panoramaId,
			camera: this._camera
		});
	}
}

/**
 * IGuide viewer wrapper for Infinityy.
 */
class IguideViewer {
	/**
	 * @param {any} parentViewerState the state of the top-level _viewer_ which has many subviewers in it;
	 * matterport, iguide, etc.
	 * @param {jQuerySelector|HtmlElement} domElement
	 * @param {object=} options
	 * @param {boolean=} options.debug
	 */
	constructor(parentViewerState, domElement, options) {
		this._apiCallTimer = null;
		this._domElement = domElement;
		this._floorplanClickHandler = null;
		this._iguideFloorplanMaximized = false;
		/** @property {IGuideViewer} _iguideViewer - Underlying IGuideViewer (iguide api) */
		this._iguideViewer = null;
		this._iguideViewerReady = false;
		this._options = $.extend({}, DEFAULT_OPTIONS, options);
		this._outstandingApiCall = null; // {modelId: modelId} or {panoramaId: panoramaId} or {camera: camera}
		this._parentViewerState = parentViewerState;
		/**
		 * @property {number=} _readyTimer - Timer ID used to delay between ready callback from iguide API, to
		 * when it seems we can actually call some methods on the viewer.
		 */
		this._readyTimer = null;
		/**
		 * @property {IguideScene} _scene - Current "state" of the scene being displayed in the iguide viewer
		 * (modelId, panoramaId, camera).
		 */
		this._scene = new IguideScene();
		this._sceneBeingProcessed = null;
	}

	get viewerType() {
		return IGUIDE;
	}

	/**
	 * Are 2 iguide scenes "close enough" that they're viewing effectively the same thing?
	 * @param {IguideScene} a
	 * @param {IguideScene} b
	 * @returns
	 */
	areScenesClose(a, b) {
		if ((IGUIDE !== a?.viewerType) || (IGUIDE !== b?.viewerType)) {
			return false;
		}
		return (a.modelId === b.modelId)
			&& (a.panoramaId === b.panoramaId)
			&& this._areCamerasClose(a.camera, b.camera);
	}

	/**
	 * Set the scene to `scene`.
	 * The parent viewer state will be updated when the scene changes and doneProcessingViewRequest will be
	 * called when the desired scene is in view.
	 * @param {IguideScene} scene
	 * @returns
	 */
	processSceneChange(scene) {
		if ((scene !== this._sceneBeingProcessed) && (IGUIDE === scene.viewerType)) {
			this._logDebug('Setting scene.', scene);
			this._sceneBeingProcessed = scene;
			return this._processSceneChangeStep();
		}
		return this;
	}

	/**
	 * Marks this viewer as the active viewer (or not).
	 * @param {truthy} isActive
	 * @returns
	 */
	toggleActive(isActive) {
		if (isActive) {
			if (!this._$closeFloorplanButton && this._options.enableCloseFloorplanButton) {
				this._$closeFloorplanButton = $('<div>').addClass('close-iguide-floorplan-button infinityyButton brandCTAButtonColors').text('Close');
				$(this._domElement).parent().append(this._$closeFloorplanButton);
				this._$closeFloorplanButton.click(() => {
					this._minimizeFloorplanWidget();
				});
			}
		} else if (this._$closeFloorplanButton) {
			this._$closeFloorplanButton.remove();
			this._$closeFloorplanButton = null;
			this._minimizeFloorplanWidget();
		}

		return this;
	}

	translateSceneToTelemetry(scene) {
		let contentPath = IGUIDE + '://';
		if (scene.modelId) {
			contentPath += scene.modelId;
			if (scene.panoramaId) {
				contentPath += '/' + scene.panoramaId;
			}
		} else {
			return { content: { contentPath: INVALID_CONTENT_PATH } };
		}
		const telemetry = {
			content: {
				contentPath: contentPath
			}
		};
		if (scene.camera) {
			telemetry.viewerPosition = this._telemetrizeViewerPosition(scene.camera);
		}
		return telemetry;
	}

	translateTelemetryToScene(telemetry) {
		const cpParts = telemetry.content?.contentPath?.split('/');
		if (!cpParts || (cpParts[0] !== IGUIDE_COLON) || IrUtils.isNully(cpParts[1])) {
			return new IguideScene();
		}

		const scene = {};
		if (cpParts.length >= 3) {
			scene.modelId = cpParts[2];
			if (cpParts.length > 3) {
				const p = +(cpParts[3]);
				if (p) {
					scene.panoramaId = p;
				}
			}
		}

		const vp = telemetry.viewerPosition;
		if (vp) {
			if (('number' === typeof(vp.length)) && (vp.length >= 3)) {
				const camera = new IguideCamera(+(vp[0]), +(vp[1]), +(vp[2]));
				if (camera.isValid()) {
					scene.camera = camera;
				}
			}
			if (!scene.camera) {
				this._logError('Invalid viewer position in telemetry.', vp);
			}
		}
		return new IguideScene(scene);
	}

	/**
	 * Given 2 scenes, are they close enough to indicate that an API call to set the camera
	 * @param {object} a {angle, elevation}
	 * @param {object} b
	 * @returns
	 */
	_areCamerasClose(a, b) {
		if (IrUtils.isNully(a) || IrUtils.isNully(b)) {
			return false;
		}
		const gcdAngleRadians = Spherical.radianDistance({ x: a.angle, y: a.elevation }, { x: b.angle, y: b.elevation });

		return (gcdAngleRadians < this._options.closeSceneRadians) &&
			(Math.abs(a.zoom - b.zoom) < this._options.closeSceneZoom);
	}

	_clearOutstandingApiCall() {
		this._outstandingApiCall = null;
		if (this._apiCallTimer) {
			clearTimeout(this._apiCallTimer);
			this._apiCallTimer = null;
		}
		return this;
	}

	_doneProcessingSceneChange() {
		if (this._apiCallTimer) {
			clearTimeout(this._apiCallTimer);
		}
		if (this._readyTimer) {
			clearTimeout(this._readyTimer);
		}
		this._apiCallTimer = this._readyTimer = null;

		const scene = this._sceneBeingProcessed;
		this._sceneBeingProcessed = null;
		this._clearOutstandingApiCall();

		if (!this._scene.modelId) {
			if (this._iguideViewer) {
				this._iguideViewer.disconnect();
				this._iguideViewer = null;
				this._onFloorplanMaximized(false);
				$(this._domElement).attr('src', 'about:blank');
			}
		}

		if (scene && (scene === this._parentViewerState.movingTo)) {
			this._logDebug('Finished setting scene.', scene);
			this._parentViewerState.doneProcessingViewRequest();
		}

		return this;
	}

	_hookupViewerEventHandlers() {
		this._iguideViewer.addEventListener('tour:move-start', (position, camera, transition) => {
			this._logDebug('Received move-start event.', position, camera, transition);
		});
		this._iguideViewer.addEventListener('tour:move-end', (position, camera, transition) => {
			this._logDebug('Received move-end event.', position, camera, transition);
			this._scene = new IguideScene(this._scene.modelId, position, new IguideCamera(camera));
			this._updateParentViewerScene();
			this._processSceneChangeStep();
		});
		this._iguideViewer.addEventListener('tour:camera-move-start', (camera) => {
			this._logDebug('Received camera-move-start event.', camera);
		});
		this._iguideViewer.addEventListener('tour:camera-move', (camera) => {
			this._logDebug('Received camera-move event.', camera);
		});
		this._iguideViewer.addEventListener('tour:camera-move-end', (camera) => {
			this._logDebug('Received camera-move-end event.', camera);
			this._scene = new IguideScene(this._scene.modelId, this._scene.panoramaId, new IguideCamera(camera));
			this._updateParentViewerScene();
			this._processSceneChangeStep();
		});
		this._iguideViewer.addEventListener('tour:floorplan-resize-end', (state) => {
			this._logDebug('Received floorplan-resize-end event.', state);
			this._onFloorplanMaximized('max' === state);
		});
		return this;
	}

	_loadModel(modelId) {
		this._logDebug('Loading model.', modelId);

		if (this._iguideViewer) {
			this._iguideViewer.disconnect();
		}

		this._scene = new IguideScene();
		this._setOutstandingApiCallWithTimeout({ modelId: modelId }, this._options.loadToReadyTimeoutMs);

		$(this._domElement).attr('src', `https://unbranded.youriguide.com/${modelId}?` +
			$.param({ api: 1, autostart: 1, minfp: 1, nocontrols: 1, nomenu: 1 }));
		this._iguideViewer = new IGuideViewer($(this._domElement).get(0));
		this._onFloorplanMaximized(false);
		this._iguideViewerReady = false;
		if (this._readyTimer) {
			clearTimeout(this._readyTimer);
			this._readyTimer = null;
		}

		this._iguideViewer.ready().then(() => {
			this._logDebug('Model is loaded and API is ready.', modelId);
			this._scene = new IguideScene(modelId);
			this._updateParentViewerScene();
			this._clearOutstandingApiCall();
			this._stepTimer = null;
			this._readyTimer = setTimeout(() => {
				this._logDebug('Delayed after API ready callback. Should be able to set position/camera now.');
				this._readyTimer = null;
				this._iguideViewerReady = true;
				this._processSceneChangeStep();
			}, this._options.readyToMoveDelayMs);
		}).catch((e) => {
			this._logError('Failed to load model.', modelId, e);
			this._scene = new IguideScene();
			this._doneProcessingViewRequest();
		});

		return this._hookupViewerEventHandlers();
	}

	_logDebug() {
		if (this._options.debug) {
			const args = Array.from(arguments);
			args.unshift('[iguide]');
			args.push(Date.now() * .001);
			console.debug.apply(this, args);
		}
		return this;
	}

	_logError() {
		const args = Array.from(arguments);
		args.unshift('[iguide]');
		args.push(Date.now() * .001);
		console.error.apply(this, args);
		return this;
	}

	_minimizeFloorplanWidget() {
		if (this._iguideViewer) {
			this._logDebug('Minimizing floorplan widget.');
			this._iguideViewer.tour.floorplanResize('min');
		}
		return this;
	}

	/**
	 * Attach a body click handler so that clicking outside the iguide floorplan widget will minimize it.
	 * @param {truthy} isMaximized
	 * @returns
	 */
	_onFloorplanMaximized(isMaximized) {
		if (!this._options.enableFloorplanCloseOnOutsideClick) {
			return this;
		}
		if (this._floorplanClickHandler) {
			$('body').off('click', this._floorplanClickHandler);
			this._floorplanClickHandler = null;
		}
		if (isMaximized) {
			this._logDebug('Attaching click handler outside of viewer (to close iguide floorplan widget).');
			this._floorplanClickHandler = () => {
				this._minimizeFloorplanWidget();
			};
			$('body').on('click', this._floorplanClickHandler);
		}
		this._iguideFloorplanMaximized = !!isMaximized;
		$('.close-iguide-floorplan-button').toggle(this._iguideFloorplanMaximized);
		return this;
	}

	_processSceneChangeStep() {
		//
		// State machine:
		//  load model:
		//    set iframe src and hookup IGuideViewer api object to iframe
		//    wait for ready callback from iguide api
		//    wait a little longer after "ready" since tour.move and tour.moveCamera don't work right immediately
		//  possibly set panorama:
		//    set panorama and wait for callback indicating panorama has been set
		//    if there is a tour:move-start event, the panorama is valid
		//    if not, it's either invalid or the default panorama (if we haven't set a pano explicitly yet)
		//  possibly set camera and wait for callback
		//
		// Notes as of 2023-04-11:
		//  If the delay isn't long enough between the API signalling 'ready' and:
		//   - an attempt to set pano/position: never receive tour:move-end callback.
		//   - an attempt to set camera: receive a camera:move-end callback, but that's not actually where
		//     the camera is looking.
		//
		const scene = this._sceneBeingProcessed;

		if (!scene) {
			return this._doneProcessingSceneChange();
		}

		// Set the model ID if needed.
		const modelId = scene.modelId;
		if (IrUtils.isNully(modelId)) {
			this._scene = new IguideScene();
			return this._doneProcessingSceneChange();
		}
		if ((modelId !== this._scene.modelId) && (modelId !== this._outstandingApiCall?.modelId)) {
			return this._loadModel(modelId);
		}
		if (this._outstandingApiCall?.modelId) {
			this._clearOutstandingApiCall();
		}
		if (!this._iguideViewerReady) {
			return this;
		}

		// Set the panorama/position if needed.
		const panoramaId = scene.panoramaId;
		let camera = scene.camera;
		if (!camera?.isValid()) {
			camera = new IguideCamera();
		}
		if (panoramaId && (panoramaId !== this._scene.panoramaId) &&
			(panoramaId !== this._outstandingApiCall?.panoramaId))
		{
			this._logDebug('Setting panorama.', panoramaId);
			this._setOutstandingApiCallWithTimeout({ panoramaId: panoramaId },
				this._options.apiCallTimeoutMs);
			// We just have to assume this works, because if the panorama is the default one or
			// an invalid one - nothing happens.
			this._scene = this._scene.extend({ panoramaId: panoramaId, camera: null });
			this._iguideViewer.tour.move(panoramaId, camera?.toJSON(), 'navigate');
			return this;
		}
		if (this._outstandingApiCall?.panoramaId) {
			this._clearOutstandingApiCall();
		}

		// Set the camera if needed.
		if (!this._outstandingApiCall?.camera) {
			this._logDebug('Setting camera.', camera);
			this._setOutstandingApiCallWithTimeout({ camera: camera }, this._options.apiCallTimeoutMs);
			this._iguideViewer.tour.moveCamera(camera.toJSON());
			return this;
		} else if (this._areCamerasClose(camera, this._scene.camera)) {
			return this._doneProcessingSceneChange();
		}
	}

	_setOutstandingApiCallWithTimeout(apiCallDetails, timeoutMs) {
		if (this._apiCallTimer) {
			clearTimeout(this._apiCallTimer);
		}
		this._apiCallTimer = setTimeout(() => {
			this._logDebug('Api call timed out.', apiCallDetails, timeoutMs);
			this._apiCallTimer = this._outstandingApiCall = null;
			this._processSceneChangeStep();
		}, timeoutMs);
		this._outstandingApiCall = apiCallDetails;
		return this;
	}

	_telemetrizeViewerPosition(camera) {
		camera = camera.cloneNormalized();
		return [camera.angle, camera.elevation, camera.zoom];
	}

	/**
	 * When the actual iguide viewer has told us what scene is being shown,
	 * if it's still the active viewer, update the overall viewerState.
	 * @returns
	 */
	_updateParentViewerScene() {
		if (IGUIDE === this._parentViewerState.currentViewerType) {
			this._parentViewerState.currentView = this._scene;
		}
		return this;
	}
}

export { IguideCamera, IguideViewer, IguideScene }
