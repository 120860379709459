class ViewerView {
	/**
	 * Initialize the view.
	 * @param {object=} options
	 */
	constructor(options) {
		this._contentModel = options.contentModel;
		this._leadModel = options.leadModel;
		this.onLeadCaptureDialogOpen = () => { return false; };
		this.onLeadCaptureDialogSuccess = () => { return false; };
	}

	initializeView() {
		return this._initializeSplashLeadCaptureDialog();
	}

	_initializeSplashLeadCaptureDialog() {
		this._$splashLeadCaptureDialog = $('#SplashLeadCaptureDialogWrapper');
		this._$splashLeadCaptureForm = $('#SplashLeadCaptureForm');
		this._$splashLeadCaptureFormFields = this._$splashLeadCaptureForm.find('input,textarea');
		this._$splashLeadCaptureEmail = $('#SplashLeadCaptureEmail');
		this._$splashLeadCaptureName = $('#SplashLeadCaptureName');
		this._$splashLeadCapturePhone = $('#SplashLeadCapturePhone');
		this._$splashLeadCaptureButton = $('#SplashLeadCaptureButton');

		this._$splashLeadCaptureFormFields.on('input', () => {
			this._toggleDisabledSplashLeadCaptureDialogSubmitButton(!this._isSplashLeadCaptureFormValid());
		});

		this._$splashLeadCaptureFormFields.on('keydown keyup', (evt) => {
			if (27 === evt.which) {
				return false;
			}
		});

		// show that the form will autofill a name when one isn't specified, and the name field isn't required
		this._$splashLeadCaptureEmail.find('input').on('keyup', (evt) => {
			if (!this._$splashLeadCaptureName.hasClass('required')) {
				const $nameField = this._$splashLeadCaptureName.find('input');
				const inputVal = $(evt.target).val().split('@', 2)[0] || "";
				$nameField.attr('placeholder', inputVal || $nameField.attr('defaultplaceholder'));
			}
		});

		this._$splashLeadCaptureButton.on('click', () => {
			if (!this._$splashLeadCaptureButton.hasClass('disabled')) {
				this._$splashLeadCaptureDialog.modal('hide');
				const emailAddress = this._$splashLeadCaptureEmail.find('input').val();
				const autoName = this._contentModel.splashScreenFields.required.get('name') ? null : emailAddress.split('@', 2)[0];
				const name = this._$splashLeadCaptureName.find('input').val() || autoName;
				const phone = this._$splashLeadCapturePhone.find('input').val();
				if (emailAddress || name || phone) {
					this._leadModel.setLeadContact({ email: emailAddress, name: name, phone: phone });
				}
				this.onLeadCaptureDialogSuccess();
			}
		});
		this._$splashLeadCaptureDialog.on('show.bs.modal', (_evt) => {
			setTimeout(() => {
				this._$splashLeadCaptureEmail.trigger('focus');
			}, 250);
		});
		return this;
	}

	showSplashLeadCaptureDialog() {
		const customBackgroundUrl = window.mobileWebController?.preloadContent?.content?.CoverPhotoUrl;
		if (customBackgroundUrl) {
			this._$splashLeadCaptureDialog.css('background-image', `url('${customBackgroundUrl}')`);
		}
		const fields = this._contentModel.splashScreenFields;
		let $focusTarget = null;

		this._toggleDisabledSplashLeadCaptureDialogSubmitButton(!this._isSplashLeadCaptureFormValid());

		// sanity check: if no fields are set to visible, default to email visible and required
		if (fields.visible.size == 0) {
			fields.visible.set('email', true);
			fields.required.set('email', true);
		}

		const setupField = ($el, key) => {
			$el.toggleClass('required', !!fields.required.get(key)).toggleClass('hidden', !fields.visible.get(key));
			if (!$focusTarget && !!fields.required.get(key)) {
				$focusTarget = $el.find('input');
			}
		}
		setupField(this._$splashLeadCaptureName, 'name');
		setupField(this._$splashLeadCaptureEmail, 'email');
		setupField(this._$splashLeadCapturePhone, 'phone');

		this._$splashLeadCaptureDialog.one('shown.bs.modal', () => {
			$focusTarget?.trigger('focus');
		}).modal('show');

		return this;
	}

	_isFormValid($form, requiredFieldsMap) {
		requiredFieldsMap = requiredFieldsMap ?? new Map();
		let result = true;
		$form.find('input,textarea').each((_i, el) => {
			const name = $(el).attr('name');
			const validator = this._leadModel.validators[name];
			let fieldStateInvalid = false;
			const fieldValue = $(el).val();
			if (requiredFieldsMap.get(name) && !fieldValue) {
				result = false;
				fieldStateInvalid = true;
			}
			if (fieldValue && validator && !validator(fieldValue)) {
				result = false;
				fieldStateInvalid = true;
			}
			$(el).toggleClass('invalid', fieldStateInvalid);
		});
		return result;
	}

	_isSplashLeadCaptureFormValid() {
		return this._isFormValid(this._$splashLeadCaptureForm, this._contentModel.splashScreenFields.required);
	}

	_toggleDisabledSplashLeadCaptureDialogSubmitButton(disabled) {
		this._$splashLeadCaptureButton.attr('disabled', disabled).toggleClass('disabled', disabled);
		return this;
	}
}

export { ViewerView }
