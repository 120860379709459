import React from "react";

export const TopInfoSection = () => {
	const exploreClick = () => window.bottomDrawerView.openDrawer();
	const useAi = window.contentModel?.useAi;

	return <div id="TopInfoSection" className={`flex-shrink-0 p-2 ${useAi && 'pt-0'}`}>
		<div className="flex flex-row items-center align-middle">
			{!useAi &&
				<button
					id="NonAiExplore"
					onClick={exploreClick}
					className="flex items-center flex-none p-3 px-4 mr-2 text-xs border-none rounded-full portrait-only"
				>
					Explore
				</button>
			}
			<div className="flex-grow truncate portrait-only">
				<div className="flex flex-col">
					<div className="truncate">
						<span id="TopInfoContext" className="m-1 text-sm truncate brand-cta-text"></span>
						<br/>
						<span id="TopInfoTitle" className="m-1 text-sm truncate">Loading...</span>
					</div>
					<div className="truncate">
						<span id="TopInfoAttributes" className="m-1 text-xs">Some Stuff Here</span>
					</div>
				</div>
			</div>
			<div id="NextPreviousButtons" style={{ display: "none" }} className="z-20 flex flex-row items-center">
				<button id="PrevInfoButton" className="flex items-center p-1 mr-3 border-none rounded-full grow-0">
					<i className="material-icons">chevron_left</i>
				</button>
				<button id="NextInfoButton" className="flex items-center p-3 border-none rounded-full">
					<i className="material-icons">chevron_right</i>
				</button>
			</div>
		</div>
	</div>
};
