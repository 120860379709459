class PipWhereMediaDescriptor {
	constructor(config) {
		const defaultConfig = {
			expiry: 180000
		};

		config = $.extend({}, defaultConfig, config);

		this.expires = Date.now() + config.expiry;
		this._expiry = config.expiry;
		this.isSphere = false;
		this._mediaIndex = null;
		this._mediaPath = null; // some sort of not found image URL value would be cool here.
		this._pending = true;
	}

	// mediaIndex
	get mediaIndex() {
		return this._mediaIndex;
	}

	set mediaIndex(val) {
		this._mediaIndex = val;
		this._resetExpiry();
		return this._mediaIndex;
	}

	// mediaPath
	get mediaPath() {
		this._resetExpiry();
		if (this._pending) {
			return null;
		} else {
			return this._mediaPath;
		}
	}

	set mediaPath(val) {
		this._resetExpiry();
		this._pending = false;
		this._mediaPath = val;
		return this._mediaPath;
	}

	_resetExpiry() {
		this.expires = Date.now() + this._expiry;
	}
}

export { PipWhereMediaDescriptor }
