// Requires jquery

/**
 * A utility class for getting browser mic/camera permissions and mic/camera devices for use with twilio
 * video chat rooms.
 * @param {function} isSupported Dependency injection. Function returning true/false if the browser supports video/audio chat.
 */
function ChatroomUtils(isSupported) {
	this._isSupported = isSupported;
	this._criticalSection = { in: false };
};

/**
 * @returns {boolean} True or false if the browser is supported by this voice chat.
 */
ChatroomUtils.prototype.isSupported = function () {
	return navigator.mediaDevices && navigator.mediaDevices.enumerateDevices && this._isSupported();
};

/**
 * Get permissions on the microphone and camera if they exist.
 * This should be called for the first time in a secure context and via a User Action (click handler).
 * For certain browser, lacking capabilities, we make assumptions that devices may exist and may require prompting.
 * @returns {Promise} That resolves with {microphone: status, camera: status} of "granted", "prompt", "denied", or "none"
 * Rejects with an error.name of "unsupported" for unsupported browser.
 * Rejects with {microphone: status, camera: status}
 * The returned Promise does not await user interaction and should resolve quickly.
 */
ChatroomUtils.prototype.getBrowserMediaPermissions = function () {
	var resultAssumingPromptRequired = {
		microphone: { state: 'prompt' }, camera: { state: 'prompt' }
	};

	// Check permissions for microphone and camera.
	// Not supported on IE, Safari. Query for microphone or camera not supported on firefox.
	if (!navigator.permissions || !navigator.permissions.query || navigator.userAgent.match(/ Firefox/i)) {
		// Assume the devices exist and may require prompting.
		console.info('No access to navigator.permissions API. Assuming client will need to be prompted.');
		return Promise.resolve(resultAssumingPromptRequired);
		return;
	}

	// Some browsers don't support the navigator.permissions.query()
	var permissionsQuery = {
		audio: navigator.permissions.query({ name: 'microphone' }),
		video: navigator.permissions.query({ name: 'camera' })
	};

	// Wait for both camera and microphone permission checks.
	return Promise.all([permissionsQuery.audio, permissionsQuery.video]).then(function (result) {
		if (!['prompt', 'granted'].includes(result[0].state)) {
			console.warn(`Microphone permissions are currently ${result[0].state}.`);
		}
		return Promise.resolve({ microphone: result[0], camera: result[1] });
	}, function (error) {
		console.warn(`Failed getBrowserMediaPermissions check. User will be prompted. ${error.name}: ${error.message}`);
		return Promise.resolve(resultAssumingPromptRequired);
	});
};

/**
 * Get media devices for the specified options (audio/video).
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
 * @param {any} options
 * @returns {Promise} resolving with list of media devices.
 */
ChatroomUtils.prototype.getMediaStream = function (options) {
	if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		return navigator.mediaDevices.getUserMedia(options);
	} else if (navigator.getUserMedia) {
		return navigator.getUserMedia(options);
	} else if (navigator.webkitGetUserMedia) {
		return navigator.webkitGetUserMedia(options);
	} else if (navigator.mozGetUserMedia) {
		return navigator.mozGetUserMedia(options);
	} else if (navigator.msGetUserMedia) {
		return navigator.msGetUserMedia(options);
	} else {
		return Promise.reject({ name: 'NotSupported', message: '' });
	}
};

/**
 * Get a list of media devices that we can connect to.
 * This may popup the browser's camera/mic permission check, which blocks this promise until approval/disapproval.
 * If both mic/camera permissions a "granted" or "none" - just get the list of devices if the browser supports it.
 * Otherwise if either mic/camera permissions are "prompt" - force the browser to prompt the user by opening the
 * default mic/camera streams, then close the mediastream so the mic/camera aren't being recorded, but return the
 * devices.
 * @returns {Promise} containing {microphones: [devices], cameras: [devices], permissions: []}
 * In the event that either microphone or camera are on "prompt" permission - this Promise will not return until
 * the user interacts with the browser to Allow or Block the camera/mic request.
 */
ChatroomUtils.prototype.getBrowserMediaDevices = function () {
	var me = this;

	// Check permissions (if we're already granted/blocked).
	var promise = me.getBrowserMediaPermissions().then(function (_permissions) {
		return Promise.resolve();
	}, function (_error) {
		return Promise.resolve();
	});

	// See what devices we have access to at this point.
	promise = promise.then(function () {
		console.log('Enumerating audio/video devices.', Date.now() * .001);
		return navigator.mediaDevices.enumerateDevices().then(function (devices) {
			return Promise.resolve({
				microphones: devices.filter(function (device) {
					return ('audioinput' === device.kind);
				}),
				cameras: devices.filter(function (device) {
					return ('videoinput' === device.kind);
				})
			});
		}, function (error) {
			console.warn(`Failed enumerating audio/video devices. ${error.name}: ${error.message}`);
			return Promise.reject(error);
		});
	});

	// Force prompt for browser permissions or we may be finished.
	promise = promise.then(function (devices) {
		const hasCamera = (devices.cameras.length > 0);
		const allowedCameras = devices.cameras.filter(function (d) { return ('videoinput' === d.kind) && ('' !== d.label); });
		const allowedMicrophones = devices.microphones.filter(function (d) { return ('audioinput' === d.kind) && ('' !== d.label); });
		if ((allowedMicrophones.length > 0) && (allowedCameras.length > 0)) {
			return Promise.resolve({ microphones: allowedMicrophones, cameras: allowedCameras });
		} else {
			// Force prompt. Then list allowed devices. This may be long waiting (waiting for user input on browser permission prompt).
			return me._promptUserForPermissions(hasCamera).then(function () {
				return navigator.mediaDevices.enumerateDevices().then(function (devices) {
					return Promise.resolve({
						microphones: devices.filter(function (device) {
							return ('audioinput' === device.kind) && ('' !== device.label);
						}),
						cameras: devices.filter(function (device) {
							return ('videoinput' === device.kind) && ('' !== device.label);
						})
					});
				}, function (error) {
					console.warn(`Failed enumerating audio and video devices. ${error.name}: ${error.message}`);
					return Promise.reject(error);
				});
			}, function (error) {
				if ('NotAllowedError' === error.name) {
					console.warn('Permissions for microphone and/or camera have been rejected.');
				} else {
					console.error(`Failed to retrieve allowed cameras/microphones. ${error.name}: ${error.message}`);
				}
				return Promise.reject(error);
			});
		}
	}, function (error) {
		return Promise.reject(error);
	});

	return promise;
};

/**
 * Attempts to popup the browser's mic/camera permission check.
 * Potentially long until the promise resolves (until user Allows/Blocks permissions).
 * @private
 * @returns {Promise}
 */
ChatroomUtils.prototype._promptUserForPermissions = function (hasCamera) {
	var me = this;

	console.log('Potentially popping up browser microphone/camera permissions check.', Date.now() * .001);

	return new Promise(function (resolve, reject) {
		var cs = me._criticalSection;
		if (cs.in) {
			$(cs).one('out', function (_, data) {
				('undefined' === typeof (data)) ? resolve() : reject(data);
			});
			return;
		}

		cs.in = true;
		var options = {
			audio: true,
			video: hasCamera
		};
		var mediaPromise;
		// Try to get the camera/mic to force browser to popup the permission check.
		// mediaDevices, etc require "this/context" to be navigator. Don't use temp variable.
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			mediaPromise = navigator.mediaDevices.getUserMedia(options);
		} else if (navigator.getUserMedia) {
			mediaPromise = navigator.getUserMedia(options);
		} else if (navigator.webkitGetUserMedia) {
			mediaPromise = navigator.webkitGetUserMedia(options);
		} else if (navigator.mozGetUserMedia) {
			mediaPromise = navigator.mozGetUserMedia(options);
		} else if (navigator.msGetUserMedia) {
			mediaPromise = navigator.msGetUserMedia(options);
		} else {
			// Notify other waiting.
			var error = {
				name: 'unsupported', message: 'This browser/device is not supported for camera or microphone use.'
			};
			cs.in = false; $(cs).trigger('out', error); // Notify waiting promises.
			reject(error);
			return;
		}

		mediaPromise.then(function (mediaStream) {
			mediaStream.getTracks().forEach(function (track) {
				// Firefox requires a media device to be active in order for enumerateMediaDevices to return devices with labels.
				// TODO - fix this mediastream leak; it'll need to get cleaned up after the preview/devices/connect dialog closes.
				if (!navigator.userAgent.match(/ Firefox/i) || ('audioinput' === track.kind)) {
					track.stop();
				}
			});
			cs.in = false; $(cs).trigger('out'); // Notify waiting promises.
			resolve();
		}, function (error) {
			cs.in = false; $(cs).trigger('out', error); // Notify waiting promises.
			reject(error);
		});
	});
};

export { ChatroomUtils }
