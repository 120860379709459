import { IrUtils } from "./utils.es13.js"
const POSITION = 'position';
const PRIORITY = 'priority';
const VALUE = 'value';

// DynamicDataElement
/** A representation of a value, in the context of a DynamicDataSequence, with display priority
 * being held distinct from position in the sequence.
 */
class DynamicDataElement {
	/**
	 * Create a DynamicDataElement
	 * @param {any} value - the value of the item
	 * @param {Number} priority - the display priority of the item (low is more important)
	 * @param {Number} position - the display position of the item
	 * @returns {DynamicDataElement}
	 */
	constructor(value, priority, position) {
		this[VALUE] = value;
		this[PRIORITY] = isNaN(priority) ? -1 : priority;
		this[POSITION] = isNaN(position) ? -1 : position;
	}
}

// DynamicDataSequence
/** A sequence of DynamicDataElement objects, with the goal being
 * that a set of data can be defined, and then retrieved filtered
 * by priority, and ordered by position, so that the importance of
 * an element is held distinct from its desired position in the list.
 */
class DynamicDataSequence {
	constructor() {
		this.elements = []; // Only {DynamicDataElement} objects
	}

	/**
	 * Retrieve the `count` most important items, ordered by position
	 * @param {Number} count Number of elements to retrieve
	 * @param {Boolean} sanitize Whether or not to sanitize characters with HTML entities
	 * @returns {any[]} An array of the elements' value property
	 */
	topX(count, sanitize) {
		const top = this._sortedByMultiple(this.elements, [PRIORITY, POSITION, VALUE]).slice(0, count);
		const vals = this._sortedByMultiple(top, [POSITION, PRIORITY, VALUE]).map(e => e.value);
		if (!!sanitize) {
			return vals.map(e => IrUtils.sanitizeHtmlEntities(e));
		}
		return vals;
	}

	/**
	 * Sort the objects in an array by the attributes specified
	 * @param {object[]} list
	 * @param {string[]} attributes
	 * @returns {object[]} A sorted clone of the list (this is not an in-place sort)
	 */
	_sortedByMultiple(list, attributes) {
		return [...list].sort((a, b) => {
			for (const attribute in attributes) {
				if (a[attribute] < b[attribute]) return -1;
				if (a[attribute] > b[attribute]) return 1;
			}
			return 0;
		})
	}
}

export { DynamicDataElement, DynamicDataSequence }
