import React from 'react';
import { Message } from './Message.jsx';

export const MessageGroup = ({ messages, expanded, expandedCallback, moreCallback, nextCallback, typingMessageIndex, typingParagraphIndex, typingDoneCallback }) => {
	const isGroup = (messages.length > 1) || (messages.length && messages[0].isQuestion);
	const onClick = () => {
		expandedCallback(!expanded);
	};

	return <div className={`p-1 pb-0 mb-2 overflow-auto flex-shrink-0 rounded-lg text-sm ${isGroup ? 'conversation-group' : 'transparent'}`} onClick={onClick}>
		{messages.filter((_, i) => expanded || !i).map((m, i) =>
			<Message
				key={i}
				message={m}
				lineClamp={!expanded}
				moreCallback={() => { moreCallback && moreCallback(i); }}
				nextCallback={() => { nextCallback && nextCallback(i); }}
				typingParagraphIndex={(expanded && (typingMessageIndex === i)) ? typingParagraphIndex : -1}
				typingDoneCallback={() => typingDoneCallback()}
			/>)}
	</div>
}
