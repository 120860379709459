import { ChatMessage } from "./chat-message.es6";

const DIV = '<div>';
const SPAN = '<span>';
const BUTTON = '<button>';

/**
 *  View for a single chat message
 */
class ChatMessageView {
	/**
	 * A chat message model.
	 * @param {ChatMessage} model
	 */
	constructor(model) {
		this.model = model;
		this._$msg = null;
		this.onClick = function (model) { return false; };
		this.onResponseOptionClick = function (option) { return false; };
		this.startMsgGroup = false;
		this.endMsgGroup = false;
	}

	/**
	 * Create the structure
	 * @param {ChatView} parent - The parent view
	 * @param {boolean} startMsgGroup
	 * @param {boolean} endMsgGroup
	 */
	render(parent, startMsgGroup, endMsgGroup) {

		this._$author = $(DIV).addClass('chat-message-author');
		this._$date = $(DIV).addClass('chat-message-date');
		this._$info = $(DIV).addClass('chat-message-info').append(this._$author).append(this._$date);

		// ResponseOptions
		this._$question = $(DIV).addClass('chat-message-question').text(this.model.question);
		this._$options = $(DIV).addClass('chat-message-options').append(this._$question);
		if (this.model.responseOptions?.length) {
			for (const option of this.model.responseOptions) {
				const $opt = $(BUTTON).addClass('chat-message-option');
				$opt.unbind('click').bind('click', (evt) => this._onResponseOptionClick(evt, option));
				if (option.Id === this.model.defaultOptionId) {
					$opt.addClass('default');
				}
				$opt.text(option.Label);
				this._$options.append($opt);
			}
		}

		this._$asked = $(SPAN).addClass('material-icons');

		this._$messageText = $(SPAN).addClass('chat-message-text');

		this._$bubble = $(DIV).addClass('chat-message-bubble').append(this._$messageText).append(this._$options).append(this._$asked);
		this._$thumb = $(DIV).addClass('chat-message-thumb');
		this._$body = $(DIV).addClass('chat-message-body').append(this._$bubble).append(this._$thumb);

		this._$msg = $(DIV).addClass('chat-message').attr('id', 'chat-message-' + this.model.id).data('id', this.model.id).append(this._$info).append(this._$body);
		this._$msg.unbind('click').bind('click', () => this._onClick());

		this.startMsgGroup = startMsgGroup;
		this.endMsgGroup = endMsgGroup;

		this.update();
		parent.append(this._$msg);
		return this;
	}

	/**
	 * Apply the model to the structure
	 * */
	update() {
		const msgDate = new Date(this.model.createdDateTime);
		const formattedDateTime = msgDate.toLocaleString();
		const formattedDate = msgDate.toLocaleDateString();

		this._$info.prop('title', formattedDateTime);
		this._$author.text(this.model.name);
		this._$date.text(formattedDate);
		this._$messageText.text(this.model.chatText);

		if (this.startMsgGroup) {
			this._$bubble.addClass('first-message');
			this._$info.removeClass('hidden');
			this._$thumb.css("background-image", `url('${this.model.thumbUrl}')`);
			this._$thumb.removeClass('hidden');
		} else {
			this._$bubble.removeClass('first-message');
			this._$info.addClass('hidden');
			this._$thumb.addClass('hidden');
		}

		// If there's no valid thumbnail image url, override the above to hide it
		if (!this.model.thumbUrl) {
			this._$thumb.addClass('hidden');
		}

		if (this.endMsgGroup) {
			this._$bubble.addClass('last-message');
		} else {
			this._$bubble.removeClass('last-message');
		}

		if (this.model.hasAnsweredQuestion) {
			this._$options.hide();
		}

		if (this.model.askedQuestion) {
			this._$asked.text('check_circle_outline');
		}
	}

	_onClick() {
		if (this.onClick) { return this.onClick(this.model); }
		return true;
	}

	_onResponseOptionClick(evt, option) {
		if (this.onResponseOptionClick) {
			evt.stopPropagation();
			return this.onResponseOptionClick(option);
		}
		return true;
	}
}

export { ChatMessageView }
