import { MulticastEventHandler } from '../utils/multicast-event-handler.es13.js'

const eventType = 'show-content-item';

/**
 * @classdesc Algorithm for deciding when the displayed content item in the content area should be updated
 * based on changes to the scenePath and explicitly selected content items (selected either at the initial
 * page load or clicking on listing/building/features/next/pref). Events are passed in via handleXyz methods
 * and event handlers (registered via onXyz methods) receive notification when a new content item should
 * be displayed.
 */
class ShowContentItemAlgorithm {
	/**
	 * Receives incoming events from the system and triggers 'show-content-item' event
	 * @param {ShowContentItemModel} model
	 */
	constructor(model) {
		this._model = model;
		this._eventHandler = new MulticastEventHandler();
		this._currentScenePath = null;
		this._lastContentItem = null;
		this._currentContentItem = null;
	}

	/**
	 * Must be called every time a content item is explicitly selected, either at initial page load or
	 * by clicking on listing/building/feature/next/prev. Always triggers onShowContentItem callbacks.
	 * @param {string} contentItemId - Of the form 'ListingContentItem:###' or 'ListingUrl:###'
	 * @param {string} contentItemScenePath - ScenePath for the content item, or none for listing urls.
	 * @returns {ShowContentItemAlgorithm} - this
	 */
	handleContentItemSelected(contentItemId, contentItemScenePath, options) {
		if (contentItemId) {
			this._lastContentItem = this._currentContentItem;
			this._currentContentItem = {
				id: contentItemId,
				scenePath: contentItemScenePath,
				arrived: !contentItemScenePath || (this._currentScenePath === contentItemScenePath)
			};
			let updateScene;
			if (false === options?.updateScene) {
				updateScene = false;
			} else if (contentItemScenePath) {
				updateScene = true;
			}
			this._eventHandler.trigger(eventType, contentItemScenePath, contentItemId, updateScene);
		}
		return this;
	}

	/**
	 * Must be called every time the scenePath of the scene changes. May be called when no change occurs.
	 * @param {string=} scenePath
	 * @returns {ShowContentItemAlgorithm} - this
	 */
	handleScenePathChanged(scenePath) {
		if (!scenePath) {
			return this;
		}
		if (scenePath === this._currentScenePath) { // No change
			return this;
		}

		// Scene path changed.

		const lastScenePath = this._currentScenePath;
		this._currentScenePath = scenePath;

		const mostRecentContentItem = this._currentContentItem || this._lastContentItem;
		if (scenePath === mostRecentContentItem?.scenePath) {
			// Same scenePath as most recent item. No change to content area.
			mostRecentContentItem.arrived = true;
			if (!this._currentContentItem) {
				// Weren't currently displaying a content item - so fire the event.
				this._currentContentItem = mostRecentContentItem;
				this._eventHandler.trigger(eventType, scenePath, mostRecentContentItem.id);
			}
			return this;
		}

		// Was a content item selected in a matterport model and this is the first sweep
		// in that model? We receive events from matterport about landing in that sweep
		// on the way to our destination sweep. Don't update content area if this is
		// just a transitional sweep.
		if (!mostRecentContentItem.arrived && this._isFirstSweepInMatterportModel(scenePath, lastScenePath)) {
			return this;
		}

		const contentItemId = this._model.getNearestContentItemIdFromContentItemList(scenePath,
			mostRecentContentItem.id);
		if (contentItemId) {
			// Need to update the view for a new content item.
			this._lastContentItem = mostRecentContentItem;
			this._currentContentItem = {
				id: contentItemId,
				scenePath: scenePath,
				arrived: true
			};
			this._eventHandler.trigger(eventType, scenePath, contentItemId);
		} else {
			// We're in a scenePath not associated with any content items.
			if (this._currentContentItem) {
				// Transitioning from a visible contentitem to none.
				this._lastContentItem = this._currentContentItem;
				this._currentContentItem = null;
			}
			this._eventHandler.trigger(eventType, scenePath, null);
		}

		return this;
	}

	/**
	 * Register an event handler for the output of this algorithm, when the content item in the content area
	 * should be changed.
	 * @param {function} handler
	 * @param {object=} options
	 * @param {boolean=} options.synchronous
	 * @returns {ShowContentItemAlgorithm}
	 */
	onShowContentItem(handler, options) {
		this._eventHandler.on(eventType, handler, options);
		return this;
	}

	/**
	 * Remove the specified handler (or all event handlers for this event).
	 * @param {function=} handler
	 * @returns {ShowContentItemAlgorithm}
	 */
	offShowContentItem(handler) {
		this._eventHandler.off(eventType, handler);
		return this;
	}

	_isFirstSweepInMatterportModel(scenePath, lastScenePath) {
		if (!scenePath.startsWith('matterport:')) {
			return false;
		}
		if (!lastScenePath?.startsWith('matterport:')) {
			return true;
		}
		return (lastScenePath.split('/')[4] !== scenePath.split('/')[4]);
	}
}

export { ShowContentItemAlgorithm }
