import { saveChatMessage } from "../api/api.es6"
import { getTabClientInstanceId } from "../utils/identity.es6"
import { getShareLink } from "../utils/redirect.es6.js"
import { getThumbnail, getViewerState, setViewWithConversion, translateView } from "../telemetry/room.js"
import { doToast, TOAST_TYPES } from "../utils/toastr-wrapper.es6"

/**
 * Controller for text-chat-related function
 * TODO - there will be some rendering/handling logic for replying to questions/etc.
 * @class
 */
class ChatController {
	/**
	 * Initialize the controller.
	 * @param {number} roomId
	 * @param {string} callosumRoomId
	 * @param {ChatModel} chatModel
	 * @param {ChatView} chatView
	 * @param {any=} options
	 * @param {LeadCaptureView=} options.leadCaptureView
	 */
	constructor(roomId, callosumRoomId, chatModel, chatView, options) {
		/** @member {string} */
		this.callosumRoomId = callosumRoomId;
		/** @member {ChatModel} */
		this.chatModel = chatModel;
		/** @member {ChatView} */
		this.chatView = chatView;
		/** @initialized {bool} */
		this.initialized = false;
		this.leadCaptureView = options?.leadCaptureView;
		/** @member {number} */
		this.roomId = roomId;

		// Parent controller hooks. Do not call directly here (use underscore methods below).
		this.onRecordSendChatMessageActivity = function (_message) { return false; }

		// Hook the view's callbacks.
		this.chatView.onClose = this._onClose;
		this.chatView.onMessageClicked = (message) => this._onMessageClicked(message);
		this.chatView.onResponseOptionClicked = (message, option, messageView) => this._onResponseOptionClicked(message, option, messageView);
		this.chatView.onSendMessage = (message) => this._onSendMessage(message);
		this.chatView.onSetView = this._onSetView;

		// Hooked by MobileWebController
		this.onSetView = function (_view) { };
	}

	_handleCallosumApplicationEvent(fromCallosumClientInstanceId, eventTopic, data) {
		const me = this;
		const myParticipantId = window.mobileWebController.userModel.participantId;
		if (eventTopic === "RespondableMessage") {
			// Did a question just get answered? Remove the options for everyone then.
			if (data.answering) {
				me.chatModel.messages
					.filter(m => m.id === data.answering)
					.forEach(m => {
						m.hasAnsweredQuestion = true;
						me.chatView.update();
					});
			}

			// If it's not meant for us, remove the options
			if ((data.toParticipantId && data.toParticipantId !== myParticipantId)
				|| data.fromParticipantId === myParticipantId) {
				data.messageObj.chatQuestion = null;
			}

			if (data.fromParticipantId === myParticipantId) {
				data.messageObj.askedQuestion = true;
			}

			// Send the message object along for rendering
			this._doAddMessage(data.messageObj);
		}
	}

	_handleCallosumChatMessage(fromCallosumClientInstanceId, messageDto) {
		this._doAddMessage(messageDto);
	}

	_handleCallosumSystemMessage(fromCallosumClientInstanceId, messageType, messageDto) {
		this.chatModel.addMessages([messageDto]);
	}

	_doAddMessage(messageDto) {
		this.chatModel.addMessages([messageDto]);
		this.chatView.update();
		if (!window.mobileWebController.headerMenuModel.chatModalOpen) {
			const title = 'New Message (click to open)';
			const thumbUrl = messageDto.ThumbUrl ?? messageDto.thumbUrl;
			const thumbElement = thumbUrl ? `<div class="chat-message-thumb" style="background-image: url('${thumbUrl}');"></div>` : '';
			const message = `
<div class="chat-message">
	<div class="chat-message-info">
		<div class="chat-message-author">${messageDto.Name ?? messageDto.name}</div>
	</div>
	<div class="chat-message-body">
		<div class="toast-chat-message-bubble">${messageDto.ChatText ?? messageDto.chatText}</div>
		${thumbElement}
	</div>
</div>`;
			doToast(TOAST_TYPES.chat, message, title, 30000);
		}
	}

	initialize() {
		if (this.initialized) { return this; }
		const me = this;

		// Callosum Setup
		let customCallosumConfig = { clientInstanceId: getTabClientInstanceId() };
		this.callosumClient = window.callosum?.Client(window.siteSettings.Callosum.HubUrl, customCallosumConfig);

		this.callosumClient.on("ReceivedSystemMessage", (fromCallosumClientInstanceId, messageType, messageDto) => this._handleCallosumSystemMessage(fromCallosumClientInstanceId, messageType, messageDto));
		this.callosumClient.on("ReceivedMessage", (fromCallosumClientInstanceId, messageDto) => this._handleCallosumChatMessage(fromCallosumClientInstanceId, messageDto));
		this.callosumClient.on("ReceivedApplicationEvent", (fromCallosumClientInstanceId, eventTopic, data) => this._handleCallosumApplicationEvent(fromCallosumClientInstanceId, eventTopic, data));

		this.callosumClient.Connect(this.callosumRoomId);

		// Ensure the current user is added to the chat
		// TODO

		// Populate Message History
		this.chatModel.loadAllMessages().then(function () {
			me.chatView.initializeView();
		});


		this.initialized = true;
		return this;
	}

	/**
	 * Send a chat message as the current user with the current user's view recorded
	 * @param {string} message
	 */
	sendChatMessage(message) {
		return this._onSendMessage(message);
	}

	/**
	 * All outbound messages have mostly the same needs. This consolidates the common fields.
	 * @param {string} chatText
	 * @returns {object} containing properties for apiDTO and callosumDTO
	 */
	_assembleOutboundMessageDtos(chatText) {
		const view = translateView(getViewerState().currentView);
		const pos = this._translatePositionToChatMessageFields(view.viewerPosition, view.content.contentPath);
		const userModel = window.mobileWebController.userModel;

		// fields used below
		const contentPath = view.content.contentPath;
		const fov = pos.fov;
		const hlook = pos.hlook;
		const name = userModel.displayName;
		const participantId = userModel.participantId;
		const vlook = pos.vlook;
		const thumbUrl = getThumbnail();
		const dateTime = new Date();

		// I know. Having two different DTOs for the same thing sucks, and it makes me crazy too.
		const callosumDTO = {
			tourId: 0,
			chatText: chatText,
			contentId: 0,
			contentType: '',
			vlookat: vlook,
			hlookat: hlook,
			contentPath: contentPath,
			createdDateTime: dateTime,
			name: name,
			participantId: participantId,
			thumbUrl: thumbUrl,
			isPublicRoom: true,
			chatRoomId: this.roomId,
			scapeCastId: "chat".concat(this.roomId),
			fov: fov,
			sceneName: 'irrelevant'
		}

		const apiDTO = {
			TourId: callosumDTO.tourId,
			ContentId: callosumDTO.contentId,
			ContentType: callosumDTO.contentType,
			Vlook: callosumDTO.vlookat,
			Hlook: callosumDTO.hlookat,
			ChatText: callosumDTO.chatText,
			ContentPath: callosumDTO.contentPath,
			CreatedDateTime: callosumDTO.createdDateTime,
			Name: callosumDTO.name,
			ParticipantId: callosumDTO.participantId,
			ThumbUrl: callosumDTO.thumbUrl,
			IsPublicRoom: callosumDTO.isPublicRoom,
			ChatRoomId: callosumDTO.chatRoomId,
			ScapeCastId: callosumDTO.scapeCastId,
			Fov: callosumDTO.fov,
			SceneName: callosumDTO.sceneName,
			PanoId: 0,
			IsAPinnedComment: false,
			PinVlook: 0,
			PinHlook: 0
		}

		return {
			apiDTO: apiDTO,
			callosumDTO: callosumDTO
		};
	}

	/**
	 * On clicking close button - close the chat window.
	 */
	_onClose() {
		this.chatView.toggleChatWindow(false);
		return true;
	}

	/**
	 * Load the view associated with the selected message
	 * @param {ChatMessage} message
	 */
	_onMessageClicked(message) {
		const position = this._translateChatMessageFieldsToPosition(message);
		// close the chat modal
		window.mobileWebController.headerMenuModel.modalOpen = '';
		window.mobileWebController.headerMenuView.update();

		// load the content and position in the viewer;
		const view = {
			content: {
				contentPath: message.contentPath
			},
			viewerPosition: position
		}
		// TODO: We should try to infer which listing we're looking at now, since the chat message doesn't store the listingId.

		setViewWithConversion(view);
	}

	/**
	 * Send a response to a respondable message, based on the button the user clicked
	 * @param {any} message
	 * @param {any} option
	 * @param {any} messageView
	 */
	_onResponseOptionClicked(message, option, messageView) {
		const me = this;
		const dtos = this._assembleOutboundMessageDtos('');

		switch (option?.ActionType) {
			case "NegotiateJoinRespondAffirmative":
				dtos.callosumDTO.chatText = "Yes, please!";
				dtos.callosumDTO.chatQuestion = {
					DefaultOptionId: 0,
					Message: "",
					Options: [
						{
							Id: 0,
							Label: "Join Session",
							ActionType: "NegotiateJoinFinishLink",
							ActionData: {
								Url: getShareLink()
							}
						}
					]
				}
				break;
			case "NegotiateJoinRespondNegative":
				dtos.callosumDTO.chatText = "Not right now, thanks!";
				break;
			case "NegotiateJoinFinishLink":
				if (option.ActionData?.Url) {
					window.location = option.ActionData.Url;
				}

		}

		dtos.apiDTO.ChatText = dtos.callosumDTO.chatText;

		if (dtos.apiDTO.ChatText) {
			const eventData = {
				answering: message.id,
				fromClientInstanceId: me.callosumClient.clientInstanceId,
				fromParticipantId: dtos.callosumDTO.participantId,
				toParticipantId: option.ActionData?.replyToParticipantId,
				messageObj: dtos.callosumDTO
			};

			// Save the message to the database, and get it back in its full form (i.e. with an id)
			saveChatMessage(dtos.apiDTO) // from api/api.es6
				.then(function (savedMessages) {
					// report the message to current participants (self included) via callosum
					if (savedMessages.length > 0 && savedMessages[0].Id) {
						dtos.callosumDTO.uniqueId = savedMessages[0].Id;

						me.callosumClient.SendApplicationEventAll("RespondableMessage", eventData).catch(console.error);
						message.hasAnsweredQuestion = true;
						messageView.update();
						me._recordSendChatMessageActivity(dtos.callosumDTO.chatText);
					}
				});

		}
	}

	_onSendMessage(message) {
		const me = this;
		const task = () => {
			try {
				const dtos = this._assembleOutboundMessageDtos(message);

				// Save the message to the database, and get it back in its full form (i.e. with an id)
				saveChatMessage(dtos.apiDTO) // from api/api.es6
					.then(function (savedMessages) {
						// report the message to current participants (self included) via callosum
						if (savedMessages.length > 0 && savedMessages[0].Id) {
							dtos.callosumDTO.uniqueId = savedMessages[0].Id;
							me.callosumClient.SendMessage(dtos.callosumDTO).catch(console.error);
							me.chatModel.addMessages([dtos.callosumDTO]);
							me.chatView.update();
							me._recordSendChatMessageActivity(message);
						}
					});
			}
			catch (err) {
				console.error('[chat]', 'Failed to send chat message to server', err);
			}
		};
		this.leadCaptureView.getLeadBeforeSendingChat(task);
		return true;
	}

	_recordSendChatMessageActivity(message) {
		if (this.onRecordSendChatMessageActivity) { return this.onRecordSendChatMessageActivity(message); }
		return true;
	}

	_translateChatMessageFieldsToPosition(message) {
		let position = [];
		let viewerType = message.contentPath.split(':')[0];

		switch (viewerType) {
			case 'everyscape':
			case 'olio':
			case 'pdf':
			case 'streetview':
			case 'video':
			case 'iguide':
				position[0] = message.x;
				position[1] = message.y;
				position[2] = message.fov;
				break;
			case 'matterport':
				position[0] = 0;
				position[1] = 0;
				position[2] = 0;
				position[3] = message.y;
				position[4] = message.x;
				position[5] = message.fov;
				break;
			default:
				console.warn('[chat]', 'Failed to get position for view for creating chat thumbnail.');
				break;
		}

		return position;
	}

	_translatePositionToChatMessageFields(position, contentPath) {
		let p = {
			hlook: null,
			vlook: null,
			fov: null
		}
		let viewerType = contentPath.split(':')[0];

		switch (viewerType) {
			case 'everyscape':
			case 'olio':
			case 'pdf':
			case 'streetview':
			case 'iguide':
				p.hlook = position[0];
				p.vlook = position[1];
				p.fov = position[2];
				break;
			case 'video':
				p.hlook = position[0]; // time, in videos
				p.vlook = 1; // playing, in videos, which we'll always set to 1 so chat message links play back
				p.fov = 0; // no relevant data here on videos
				break;
			case 'matterport':
				p.hlook = position[4];
				p.vlook = position[3];
				p.fov = position[5];
				break;
			default:
				console.warn('[chat]', 'Failed to get position for view for creating chat thumbnail.');
				break;
		}

		return p;
	}


	/**
	 * Delegate "onSetView" call to parent controller (MobileWebController).
	 * @param {{contentPath: string, position: number[]=}} view
	 * @return {bool} wasHandled
	 */
	_onSetView(view) {
		if (this.onSetView) { return this.onSetView(view); }
		return this;
	}
}

export { ChatController }
