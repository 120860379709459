const DIV = '<div>';
const SPAN = '<span>'
/**
 * View for a single PIP.
 */
class PipView {
	/**
	 * Create the pip view with backing data model.
	 * @param {PipModel} model
	 * @param {truthy} isYouPip
	 */
	constructor(model, isYouPip) {
		this.model = model;
		this.isYouPip = !!isYouPip;
		this._$pip = null;
	}

	/**
	 * Create HTML elements representing the view of the pip.
	 * @param {jQuerySelector} parent Container element to render the pip into.
	 */
	render(parent) {
		this._$pip = $(DIV).addClass('pip').data('id', this.model.clientInstanceId);
		this._$callIcon = $(SPAN).addClass('material-icons').prop('title', 'on call').text('call');
		this._$displayName = $(SPAN).addClass('display-name');
		const header = $(DIV).addClass('espip-text-label oneLineText').append(this._$callIcon).append(this._$displayName);

		this._$videoTrack = $(DIV).addClass('espip-video-track');
		this._$audioTrack = $(DIV).addClass('espip-audio-track');
		this._$avatar = $(DIV).addClass('espip-avatar-image fullSize').append(this._$videoTrack).append(this._$audioTrack);
		this._$micIcon = $(DIV).addClass('material-icons off').prop('title', 'microphone off').text('mic_off');
		this._$cameraIcon = $(DIV).addClass('material-icons off').prop('title', 'camera off').text('videocam_off');
		const overlay = $(DIV).addClass('espip-html-overlay fullSize')
			.append($(DIV).addClass('espip-track-icons').append(this._$micIcon).append(this._$cameraIcon));
		if (this.isYouPip) {
			this._$gearIcon = $(DIV).prop('id', 'MobileCallSettingsIcon').addClass('material-icons').text('settings').hide();
			overlay.append(this._$gearIcon);
		}
		this._$pip.append(header).append(this._$avatar).append(overlay);
		if (!this.isYouPip) {
			this._$pip.unbind('click').click(() => {
				window.mobileWebController.showFollowModal(this.model.clientInstanceId, this.model.displayName, this.model.avatarPhotoUrl);
			});
		}
		this.update();
		parent.append(this._$pip);
		return this;
	}

	remove() {
		this._$pip.remove();
		return this;
	}

	update() {
		const displayName = this.isYouPip ? [this.model.displayName, '(You)'].join(' ') : this.model.displayName;
		const ps = this.model.participantState;
		this._$displayName.text(displayName);
		this._$callIcon.toggle(ps.callConnected);
		this._$avatar.css('backgroundImage', this.model.avatarPhotoUrl ? `url(${this.model.avatarPhotoUrl})` : '');
		this._$micIcon.toggle(!this.isYouPip && (ps.callConnected && ps.audioMuted));
		this._$cameraIcon.toggle(!this.isYouPip && (ps.callConnected && ps.videoMuted));
		this._$videoTrack.toggle(ps.callConnected && !ps.videoMuted);
		if (this.isYouPip) {
			this._$gearIcon.toggle(ps.callConnected);
		}
		return this;
	}

	attachVideoTrack() {
		const $el = this._$videoTrack;
		$el.find('video').remove();
		if (this.model.videoTrack) { $el.append(this.model.videoTrack.attach()); }
		return this;
	}

	attachAudioTrack() {
		const $el = this._$audioTrack;
		$el.find('audio').remove();
		if (this.model.audioTrack) { $el.append(this.model.audioTrack.attach()); }
		return this;
	}
}

export { PipView }
