/**
 * Model for all pips.
 */
class PipsModel {
	constructor() {
		this.pips = new Map();
		this.fullsize = true;
	}

	addPip(pip) {
		this.pips.set(pip.clientInstanceId, pip);
		return this;
	}

	getPip(clientInstanceId) {
		return this.pips.get(clientInstanceId);
	}

	removePip(pip) {
		if ('string' === typeof (pip)) {
			this.pips.delete(pip);
		} else {
			this.pips.delete(pip.clientInstanceId);
		}
		return this;
	}

	set(newModel) {
		const diff = {
			added: [],
			updated: [],
			isDifferent: false
		};
		const removeClientInstanceIds = new Set(this.pips.keys());
		for (const [clientInstanceId, pip] of newModel.pips) {
			removeClientInstanceIds.delete(clientInstanceId);
			const currentPip = this.pips.get(clientInstanceId);
			if (!currentPip) {
				this.pips.set(clientInstanceId, pip);
				diff.added.push(pip.clientInstanceId);
			} else if (!currentPip.equals(pip)) {
				currentPip.set(pip);
				diff.updated.push(pip.clientInstanceId);
			}
		}
		Array.from(removeClientInstanceIds).forEach((clientInstanceId) => {
			const removeMe = this.pips.get(clientInstanceId);
			if (removeMe.audioTrack || removeMe.videoTrack) { // We can't remove it. Still connected to call.
				removeClientInstanceIds.delete(clientInstanceId);
				removeMe.participantState.telemetryConnected = false;
				diff.updated.push(clientInstanceId);
			}
		});
		diff.removed = Array.from(removeClientInstanceIds);

		for (const deleteClientInstanceId of diff.removed) { this.pips.delete(deleteClientInstanceId); }
		diff.isDifferent = (diff.added.length + diff.updated.length + diff.removed.length) > 0;
		return diff;
	}
}

export { PipsModel }
