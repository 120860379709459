import { ChatMessage } from "./chat-message.es6.js"

/**
 * Model (data and some data access) relating to text chat functionality.
 * @class
 */
class ChatModel {
	/**
	 * Initialize the model.
	 * @param {number} roomId
	 */
	constructor(roomId) {
		/** @member {ChatMessage[]} messages Messages sorted by time sent ascending. */
		this.messages = [];
		/** @member {number} */
		this.roomId = roomId;
		/** @member {bool} websocketConnected Are messages currently being streamed or not. */
		this.websocketConnected = false;
	}

	/**
	 * Load all messages for the chat.
	 * @return {Promise} on successful completion.
	 */
	loadAllMessages() {
		// TODO - implement API calls (put in ../api.es6.js) to infinityy api to load the initial data into this model.
		const me = this;
		return new Promise(function (resolve, reject) {
			$.ajax(window.siteSettings.InfinityyApiUrl + "api/ChatMessage/", {
				type: "GET",
				cache: false,
				data: { scapeCastId: "chat" + window.roomId, chatRoomId: window.roomId }
			}).done(function (messages) {
				resolve(me.addMessages(messages));
			});

		})
	}

	/**
	 * Add new messages.
	 * @param {Array.<Object>} messages - incoming message objects (from JSON/callosum).
	 * @return {ChatModel}
	 */
	addMessages(messages) {
		const me = this;
		messages.forEach((msg) => {
			me.messages.push(new ChatMessage(msg));
		});
		
		return this;
	}

}

export { ChatModel }
