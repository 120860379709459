import { IrUtils, isNully } from "../utils/utils.es13.js"
import { Spherical } from "../utils/spherical.es13.js"

const INVALID_CONTENT_PATH = 'everyscape://';
const ES = 'everyscape';
const MIN_H = -180;
const MAX_H = 180;
const MIN_V = -90;
const MAX_V = 90;
const MIN_FOV = 0;
const MAX_FOV = 179;
const CALLBACK_KRPS = "__CALLBACK__";
const DEFAULT_OPTIONS = {
	aimTolerance: 0.1, // t*fov >= degreeDistance(a, b) all in degrees
	zoomTolerance: 1.1 // maximum value of larger fov/smaller fov
}
const ES_TYPES = {
	PANORAMA: 'panorama',
	POI: 'poi',
	SCRIPT: 'script',
	TOUR: 'tour'
}

/**
 * Immutable camera view details.
 * @property {number} h - horizontal look direction in degrees (-180..180, wraparound possible)
 * @property {number} v - vertical look direction in degrees (-90..90)
 * @property {number} fov - field of view in degrees (0..179)
 * @property {string} actionLookArgs - krpanoScript look direction string
 */
class EveryScapeCamera {
	/**
	 * Create a camera from 3 arguments angle, elevation, zoom.
	 * Or another EveryScapeCamera or object (angle,elevation,zoom).
	 * @param {object|EveryScapeCamera|number=} arg1 camera object or horizontal look direction
	 * @param {number=} [v=0] vertical look direction
	 * @param {number=} [fov=0] field of view
	 */
	constructor(arg1 = 0, v = 0, fov = 0) {
		if (('object' === typeof (arg1)) && (null !== arg1)) {
			this._h = arg1.h || 0;
			this._v = arg1.v || 0;
			this._fov = arg1.fov || 0;
		} else {
			this._h = arg1 || 0;
			this._v = v || 0;
			this._fov = fov || 0;
		}
	}

	get h() { return this._h; }
	get v() { return this._v; }
	get fov() { return this._fov; }

	get actionLookArgs() {
		return !this.isValid() ? "" : `, view.hlookat=${this.h}&view.vlookat=${this.v}&view.fov=${this.fov}`;
	}

	/**
	 * Returns a copy of this.
	 * @returns {EveryScapeCamera}
	 */
	clone() {
		return new EveryScapeCamera(this._h, this._v, this._fov);
	}

	/**
	 * Returns a normalized copy of this.
	 * @returns {EveryScapeCamera}
	 */
	cloneNormalized() {
		return new EveryScapeCamera(this._normalizedH, this._normalizedV, this._normalizedFov);
	}

	extend(camera) {
		return new EveryScapeCamera(IrUtils.firstDefined(camera?.h, this?._h),
			IrUtils.firstDefined(camera?.v, this?._v),
			IrUtils.firstDefined(camera?.fov, this?._fov));
	}

	/**
	 * @returns {boolean}
	 */
	isValid() {
		return isFinite(this._h)
			&& (this._v <= MAX_V) && (this._v >= MIN_V)
			&& (this._fov >= MIN_FOV) && (this._fov <= MAX_FOV);
	}

	toJSON() {
		return {
			angle: this._h,
			elevation: this._v,
			zoom: this._fov
		};
	}

	get _normalizedH() {
		return +(this._normalizeValue(this._h, MIN_H, MAX_H).toFixed(1));
	}
	get _normalizedV() {
		return +(this._normalizeValue(this._v, MIN_V, MAX_V).toFixed(1));
	}
	get _normalizedFov() {
		return +(this._normalizeValue(this._fov, MIN_FOV, MAX_FOV).toFixed(1));
	}

	_normalizeValue(value, min, max) {
		field = max - min;
		return ((value - min) % field) + min;
	}

}

/**
 * Immutable description of an EveryScape visible scene (model, location/position/panorama, and camera look
 * direction/zoom).
 * @property {string} viewerType
 * @property {string=} contentType
 * @property {number=} contentId
 * @property {EveryScapeCamera=} camera
 */
class EveryScapeScene {
	constructor(arg1 = null, contentId = null, camera = null) {
		if (('object' === typeof (arg1)) && (null !== arg1)) {
			this._contentType = arg1.contentType;
			this._contentId = +(arg1.contentId);
			this._camera = arg1.camera;
		} else {
			this._contentType = arg1;
			this._contentId = +(contentId);
			this._camera = camera;
		}
	}

	get contentType() { return this._contentType; }

	get contentId() { return this._contentId; }
	get camera() { return this._camera; }
	get viewerType() { return ES; }
	get sceneName() {
		let prefix = "";
		switch (this._contentType) {
			case 'panorama':
				prefix = 'pano';
				break;
			case 'poi':
				prefix = 'poi';
				break;
			case 'script':
			case 'videoscape':
				prefix = 'script';
				break;
			case 'tour':
				prefix = 'tour';
				break;
		}
		return `${prefix}M${this._contentId}`;
	}

	/**
	 * Returns a copy of this.
	 * @returns {EveryScapeScene}
	 */
	clone() {
		return new EveryScapeScene(this._contentType, this._contentId, this._camera);
	}

	extend(scene) {
		return new EveryScapeScene(IrUtils.firstDefined(scene?.contentType, this._contentType),
			IrUtils.firstDefined(scene?.contentId, this._contentId),
			IrUtils.firstDefined(scene?.camera, this._camera));
	}

	toJSON() {
		return IrUtils.shallowStripNullies({
			viewerType: this.viewerType,
			contentType: this._contentType,
			contentId: this._contentId,
			camera: this._camera
		});
	}
}

class EveryScapeViewer {
	/**
	 * @param {any} parentViewerState the state of the top-level _viewer_ which has many subviewers in it;
	 * matterport, iguide, etc.
	 * @param {jQuerySelector|HtmlElement} domElement
	 * @param {object=} options
	 * @param {boolean=} options.debug
	 */
	constructor(parentViewerState, domElement, options) {
		this._instanceId = IrUtils.guid();
		window.EveryScapeViewers = window.EveryScapeViewers ?? {};
		window.EveryScapeViewers[this._instanceId] = this; // We need a global reference to this instance so krPano callbacks can find it
		this._globalReferenceString = `window.EveryScapeViewers['${this._instanceId}']`;
		this._domElement = domElement;
		this._options = $.extend({}, DEFAULT_OPTIONS, options);
		this._parentViewerState = parentViewerState;
		this._krpano = null;
		this._esViewerObject = null;
		this._scene = new EveryScapeScene();
		this._sceneBeingProcessed = null;
		this._isReady = false;
		this._deferredScene = null;
		this._currentKrpanoCommand = null;
		this._nextKrpanoCommand = null;
	}

	_log() {
		console.debug('[esviewer]', ...arguments, Date.now() * .001 )
	}

	get viewerType() { return ES; }

	/**
	 * Given 2 scenes, are they close enough to be equivalent
	 * @param {EveryScapeScene} a 
	 * @param {EveryScapeScene} b
	 * @returns {boolean}
	 */
	areScenesClose(a, b) {
		if ((ES !== a?.viewerType) || (ES !== b?.viewerType)) {
			return false;
		}
		return (a.contentType === b.contentType)
			&& (a.contentId === b.contentId)
			&& this._areCamerasClose(a.camera, b.camera);
	}


	/**
	 * Given 2 cameras, are they close enough to be equivalent
	 * @param {EveryScapeCamera} a 
	 * @param {EveryScapeCamera} b
	 * @returns {boolean}
	 */
	_areCamerasClose(a, b) {
		if (IrUtils.isNully(a) || IrUtils.isNully(b)) {
			return false;
		}

		if (this._options.zoomTolerance < Math.max(a.fov / b.fov, b.fov / a.fov)) {
			return false;
		}

		return (((a.fov + b.fov) / 2) * this._options.aimTolerance) >= Spherical.degreeDistance({ x: a.h, y: a.v }, { x: b.h, y: b.v });
	}

	/**
	 * Set the scene to `scene`.
	 * The parent viewer state will be updated when the scene changes and doneProcessingViewRequest will be
	 * called when the desired scene is in view.
	 * @param {EveryScapeScene} scene
	 * @returns
	 */
	processSceneChange(scene) {
		this._log('processSceneChange', scene, scene.camera);
		if ((scene !== this._sceneBeingProcessed) && (ES === scene.viewerType)) {
			this._sceneBeingProcessed = scene;
			return this._loadScene(scene);
		}
		return this;
	}

	_doneProcessingSceneChange() {
		this._sceneBeingProcessed = null;
		this._log('_doneProcessingSceneChange');
		this._parentViewerState.doneProcessingViewRequest();
		return this;
	}

	_loadScene(scene) {
		this._log('_loadScene', scene);
		if (!EveryScape?.installViewer) {
			console.error("EveryScape.installViewer not found.");
			return this;
		}
		if (null == this._esViewerObject) {
			this._log('_loadScene:installViewer');
			this._esViewerObject = EveryScape.installViewer(this._getContainerDescriptor(), this._getContentDescriptor(scene));
			this._deferredScene = scene;
		} else {
			const command = this._getKrpanoScriptLoadSceneAction(scene);
			if (IrUtils.isNully(command)) {
				this._log('_loadScene', 'No appropriate command found');
				return this;
			}
			this._runKrpanoScript(command);
		}

		return this;
	}

	_getKrpanoScriptLoadSceneAction(scene) {
		if (scene.contentType == ES_TYPES.PANORAMA) {
			const loaded = !IrUtils.isNully(this._krpano.get(`scene[${scene.sceneName}]`));
			const _currentScene = this._getCurrentScene();
			const alreadyOnScreen = !!(_currentScene.sceneName == scene.sceneName);
			const includesPosition = scene.camera.isValid();

			if (alreadyOnScreen) {
				const areCamerasClose = this._areCamerasClose(_currentScene.camera, scene.camera)
				if (!includesPosition || areCamerasClose) {
					this._log('_getKrpanoScriptLoadSceneAction', 'No appropriate command found',
						`alreadyOnScreen:true,includesPosition:${includesPosition},areCamerasClose:${areCamerasClose}`, scene.camera, _currentScene.camera);
					return null;
				} else {
					return `stoplookto(); lookto(${scene.camera.h}, ${scene.camera.v}, ${scene.camera.fov}, tween(linear, 0.7), true, true, ${CALLBACK_KRPS});`;
				}
			} else if (!loaded) {
				let loadaction = `loadpano(PanoramaHtml5?p=${scene.contentId}${scene.camera.actionLookArgs}, MERGE | KEEPSCENES, BLEND(0.5)); wait(BLEND); `;
				loadaction += `loadscene(${scene.sceneName}${scene.camera.actionLookArgs}, MERGE, BLEND(0.5)); wait(LOAD); ${CALLBACK_KRPS};`;
				return loadaction;
			} else {
				return `loadscene(${scene.sceneName}${scene.camera.actionLookArgs}, MERGE, BLEND(0.5)); ${CALLBACK_KRPS};`;
			}
		} else {
			const baseUrl = EveryScape.rpcUrl3 + ["PanoramaViewer.svc", "xml"].join("/");
			let callPath;
			switch (scene.contentType) {
				case ES_TYPES.POI:
					callPath = `/InitPoiHtml5?poi=${scene.contentId}`;
					break;
				case ES_TYPES.SCRIPT:
					callPath = `/InitVsHtml5?vsId=${scene.contentId}`;
					break;
				case ES_TYPES.TOUR:
					callPath = `/InitToHtml5?to=${scene.contentId}`;
					break;
			}
			if (IrUtils.isNully(callPath)) {
				this._log('_getKrpanoScriptLoadSceneAction', 'No appropriate command found', 'empty callPath', scene.contentType);
				return null;
			} else {
				return `stopall(); loadpano(${baseUrl}${callPath});`;
			}
		}
	}

	_getKrpanoCallback() {
		return `js(${this._globalReferenceString}.commandFinished()) `;
	}

	_getCurrentScene() {
		const currentSceneName = this._krpano.get("xml.scene");
		const currentVLookat = this._krpano.get("view.vlookat");
		const currentHLookat = this._krpano.get("view.hlookat");
		const currentFOV = this._krpano.get("view.fov");

		const camera = new EveryScapeCamera(currentHLookat, currentVLookat, currentFOV);

		if (!currentSceneName.startsWith('panoM')) {
			return null;
		}

		const panoId = +(currentSceneName.replace('panoM', ''));

		return new EveryScapeScene('panorama', panoId, camera);
	}

	_getContainerDescriptor() {
		const containerDescriptor = {
			container: $(this._domElement).get(0),
			type: EveryScape.Viewer.Type.HTML5,
			heightMargin: 0,
			hideMobileControls: true,
			nonIFrameVersion: true
		};
		containerDescriptor.subscriptions = [
			{
				topic: "/viewer/loaded",
				callback: () => { this._handleViewerLoaded(); }
			}
		];
		return containerDescriptor;
	}

	_getContentDescriptor(scene) {
		scene = scene ?? this._sceneBeingProcessed;
		const contentDescriptor = {
			playOnLoad: false,
			hideNavButtons: true,
			hideSlinks: this._options?.hideSlinks ? true : false,
			hideHotspots: this._options?.hideHotspots ? true : false,
		};

		switch (scene.contentType) {
			case "panorama":
				contentDescriptor["panorama"] = { panoramaId: scene.contentId };
				break;
			case "poi":
				contentDescriptor["poi"] = { id: scene.contentId };
				break;
			case "script":
			case "videoscape":
				contentDescriptor["script"] = { scriptId: scene.contentId }
				break;
			case "tour":
				contentDescriptor["panorama"] = { tourId: scene.contentId }
				break;
		};
		return contentDescriptor;
	}

	_handleViewerLoaded() {
		const e = this._esViewerObject;
		const k = e.getKrpanoObject();
		this._isReady = true;
		this._krpano = k;

		k.call("removeplugin('logo')");
		k.call("hideVideoScapeControls();");
		k.set("plugin[openfs].visible", false);
		k.set("plugin[playAutoDrive].visible", false);
		e.ui.clearSlate(false);
		let hideModeControls = () => { return false; };
		if (e.isPortrait) {
			hideModeControls = e?.ui?.hideInteractiveModeControls ?? hideModeControls;
		} else {
			hideModeControls = e?.ui?.hideVideoScapeModeControls ?? hideModeControls;
		}
		hideModeControls();

		k.set("debugmode", "true");
		k.set("logkey", "true");

		if (!IrUtils.isNully(this._deferredScene)) {
			const scene = this._deferredScene;
			this._deferredScene = null;
			this._loadScene(scene);
		}
	}

	/**
	 * Marks this viewer as the active viewer (or not).
	 * @param {truthy} isActive
	 * @returns
	 */
	toggleActive(isActive) {
		// TODO?
		return this;
	}
	translateSceneToTelemetry(scene) {
		let contentPath;
		if (scene.contentType && scene.contentId) {
			contentPath = `${ES}://${scene.contentType}/${scene.contentId}`;
		} else {
			return { content: { contentPath: INVALID_CONTENT_PATH } };
		}

		const telemetry = {
			content: {
				contentPath: contentPath
			}
		};
		if (scene.camera) {
			telemetry.viewerPosition = this._telemetrizeViewerPosition(scene.camera);
		}
		return telemetry;
	}

	translateTelemetryToScene(telemetry) {
		let proto, path;
		[proto, path] = telemetry.content?.contentPath?.split('://');
		if (IrUtils.isNully(proto) || proto != ES || IrUtils.isNully(path)) {
			return new EveryScapeScene();
		}

		// In everyscape contentpaths, all path segments are pairs, so we can turn them into a map
		const pathMap = new Map(path.split('/').reduce((r, v, i, a) => { if (i % 2 == 0) { r.push([a[i].toLowerCase(), a[i + 1]]); }; return r; }, []));

		// special case: prefer panorama if present
		const PANOTYPE = 'panorama';
		const scene = {};
		if (pathMap.has(PANOTYPE)) {
			scene.contentType = PANOTYPE;
			scene.contentId = pathMap.get(PANOTYPE);
		} else {
			const first = pathMap.entries().next().value;
			scene.contentType = first[0].toLowerCase();
			scene.contentId = first[1];
		}

		const vp = telemetry.viewerPosition;
		if (vp) {
			if (('number' === typeof (vp.length)) && (vp.length >= 3)) {
				const camera = new EveryScapeCamera(+(vp[0]), +(vp[1]), +(vp[2]));
				if (camera.isValid()) {
					scene.camera = camera;
				}
			}
			if (!scene.camera) {
				this._logError('Invalid viewer position in telemetry.', vp);
			}
		}
		return new EveryScapeScene(scene);
	}

	_runKrpanoScript(command) {
		this._log('_runKrpanoScript', command);

		if (IrUtils.isNully(command)) {
			return Promise.resolve();
		}
		const doCommand = () => {
			return new Promise((resolve, reject) => {
				this._currentKrpanoCommand = { resolve: resolve, reject: reject, timeout: Date.now() + 3000 };
				const cmd = command.replace(CALLBACK_KRPS, this._getKrpanoCallback());
				this._log('_runKrpanoScript:executing', cmd);

				this._krpano.call(cmd);
			});

		}
		const lastCommand = this._currentKrpanoCommand;
		if (IrUtils.isNully(lastCommand)) {
			this._log('_runKrpanoScript', 'lastCommand ~ null');
			return doCommand();
		} else {
			if (lastCommand.timeout < Date.now()) {
				this._log('_runKrpanoScript', 'lastCommand ~ timeout');
				lastCommand.resolve();
				command = `stopall(); ${command}`;
				return doCommand();
			} else {
				this._log('_runKrpanoScript', 'command queued', command);
				this._nextKrpanoCommand = command; // Queue for _possible_ execution after current
				return Promise.reject('Krpano Busy');
			}
		}
	}

	commandFinished() {
		return new Promise(() => {
			this._log('commandFinished');

			if (this._currentKrpanoCommand?.resolve) {
				const lastCmd = this._currentKrpanoCommand;
				this._log('commandFinished:resolving', lastCmd);
				this._currentKrpanoCommand = null;
				if (this._nextKrpanoCommand) {
					const next = this._nextKrpanoCommand;
					this._nextKrpanoCommand = null;
					this._runKrpanoScript(next);
				}
				this._doneProcessingSceneChange();
				lastCmd.resolve();
			}
		});
	}

	_telemetrizeViewerPosition(camera) {
		camera = camera.cloneNormalized();
		return [camera.h, camera.v, camera.fov];
	}
}

export { EveryScapeCamera, EveryScapeScene, EveryScapeViewer }
