import { ChatMessageView } from "./chat-message-view.es6";

/**
 * Rendering and DOM interaction relating to text chat.
 * TODO - there will be some rendering/handling logic for replying to questions/etc.
 * @class
 */
class ChatView {
	/**
	 * Initialize the view.
	 * @param {ChatModel} chatModel
	 */
	constructor(chatModel) {
		/** @member {ChatModel} */
		this.chatModel = chatModel;
		/** @member {Date} */
		this.lastRenderedTimestamp = null;
		/** @member {boolean} */
		this.chatWindowVisible = false;

		this._$chatContainer = $("#ChatContainer");
		this._$messagesContainer = $("#ChatMessagesContainer");
		this._$addMessageInput = $("#ChatInputText");

		// Controller hooks. Do not call directly in view (use underscore methods below).
		this.onClosed = function () { return false; };
		this.onMessageClicked = function (_messageModel) { return false; };
		this.onResponseOptionClicked = function (_messageModel, _option, _messageView) { return false; };
		this.onSendMessage = function (_message) { return false; };
		this.onSetView = function (_view) { return false; };

		// Not sure if this is kosher, but..
		this._messageViewList = [];
	}

	/**
	 * Initialize DOM elements for chat. Hookup click handlers, etc.
	 * @return {ChatView}
	 */
	initializeView() {
		// Detect enter key in input box
		this._$addMessageInput.unbind('keydown').bind('keydown', (evt) => {

			if (evt?.keyCode === 13) {
				this.onSendMessage(this._$addMessageInput.val());
				this._$addMessageInput.val('');
			}
		});

		this.render();
		return this;
	}

	/**
	 * Render all messages in the chatModel to the UI.
	 * @return {ChatView}
	 */
	render() {
		this._messageViewList = this._messagesToViews(this.chatModel.messages);
		this.updatePlaceholder();
		this.scrollToBottom();
		return this;
	}

	/**
	 * Render a failed-to-send message error, failed to retrieve messages, etc.
	 * @param {??} error
	 * @return {ChatView}
	 */
	renderError(error) {
		// TODO
		return this;
	}

	/**
	 * Render new messages.
	 * @return {ChatView}
	 */
	update() {
		// update existing message views
		this._messageViewList.forEach(mv => mv.update());

		// render new messages
		const mLength = this.chatModel.messages.length;
		const newCount = mLength - this._messageViewList.length;

		if (newCount > 0) {
			const newMsgs = this.chatModel.messages.slice(-1 * newCount);
			const newMessageViews = this._messagesToViews(newMsgs);

			// handle bubble corners at the seam between the old and the new
			const lastExistingMessage = this.chatModel.messages[mLength - newCount - 1];
			if (lastExistingMessage && !this._shouldHaveSeam(lastExistingMessage, newMsgs[0])) {
				const mvix = this._messageViewList.length - 1
				this._messageViewList[mvix].endMsgGroup = false;
				this._messageViewList[mvix].update();
				newMessageViews[0].startMsgGroup = false;
				newMessageViews[0].update();
			} else {
				newMessageViews[0].startMsgGroup = true;
				newMessageViews[0].update();
			}

			this._messageViewList = this._messageViewList.concat(newMessageViews);
			this.updatePlaceholder();
			this.scrollToBottom();
		}
		return this;
	}

	updatePlaceholder() {
		$("#ChatMessagesEmptyPlaceholder").toggle(this.chatModel.messages?.length === 0);
		return this;
	}

	scrollToBottom() {
		const mc = this._$messagesContainer;
		mc.animate({ scrollTop: mc.get(0).scrollHeight }, 250);
	}

	/**
	 * Toggle the visibility of the chat window.
	 * @param {bool=} visible - Toggle the chat window visible (true), invisible (false), or toggle its state (undefined).
	 * @return {ChatView}
	 */
	toggleChatWindow(visible) {
		window.mobileWebController.headerMenuModel.modalOpen = visible ? "chat" : '';
		window.mobileWebController.headerMenuView.update();
		return this;
	}

	_messagesToViews(messages) {
		const me = this;
		return messages.map((msg, ix, array) => {
			const previousMessage = ix === 0 ? null : array[ix - 1];
			const nextMessage = ix === array.length ? null : array[ix + 1];
			const startMsgGroup = this._shouldHaveSeam(previousMessage, msg);
			const endMsgGroup = this._shouldHaveSeam(msg, nextMessage);
			const messageView = new ChatMessageView(msg);

			messageView.onClick = () => me._onMessageClicked(msg); // msg is taken from closure
			messageView.onResponseOptionClick = (option) => me._onResponseOptionClicked(msg, option); // msg is taken from closure

			messageView.render(me._$messagesContainer, startMsgGroup, endMsgGroup);
			return messageView;
		});
	}

	_shouldHaveSeam(message1, message2) {
		return (
			!message1 ||
			!message2 ||
			message1.participantId !== message2.participantId ||
			message1.hasQuestion ||
			message1.thumbUrl !== message2.thumbUrl
		);
	}

	/**
	 * View calls _this_ to delegate (to controller) closing chat window.
	 * @return {bool} wasHandled
	 */
	_onClose() {
		if (this.onClose) { return this.onClose(); }
		return true;
	}


	_onMessageClicked(messageModel) {
		if (this.onMessageClicked) { return this.onMessageClicked(messageModel); }
		return true;
	}

	_onResponseOptionClicked(messageModel, option) {
		if (this.onResponseOptionClicked) { return this.onResponseOptionClicked(messageModel, option, this); }
		return true;
	}

	/**
	 * View calls _this_ to delegate (to controller) sending a message.
	 * @param {string} message
	 * @return {bool} wasHandled
	 */
	_onSendMessage(message) {
		if (this.onSendMessage) { return this.onSendMessage(message); }
		return true;
	}

	/**
	 * View calls _this_ to delegate (to controller) setting the viewer view.
	 * @param {{contentPath: string, position: number[]=}} view
	 * @return {bool} wasHandled
	 */
	_onSetView(view) {
		if (this.onSetView) { return this.onSetView(view); }
		return true;
	}

}

export { ChatView }
