const TIMEOUT_STANDARD = 1000;
const TIMEOUT_LONG = 5000;
const TOAST_TYPES = {
	chat: "chat",
	error: "error",
	info: "info",
	success: "success",
	warning: "warning"
}

function doToast(toast_type, message, title, timeout) {
	timeout = timeout ? timeout : toast_type === TOAST_TYPES.success || toast_type === TOAST_TYPES.warning ? TIMEOUT_STANDARD : TIMEOUT_LONG;

	toastr.options = {
		"closeButton": true,
		"debug": false,
		"newestOnTop": false,
		"progressBar": true,
		"positionClass": "toast-top-right",
		"preventDuplicates": false,
		"showDuration": "300",
		"hideDuration": "1000",
		"timeOut": timeout,
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};

	if (toast_type === TOAST_TYPES.chat) {
		toastr.options.extendedTimeOut = 10000;
		toastr.options.onclick = function () {
			// Open Chat
			if (!window.mobileWebController.headerMenuModel.chatModalOpen) {
				window.mobileWebController.headerMenuView.onChatButtonClicked();
			}
		}
	} else {
		toastr.options.extendedTimeOut = 1000;
		toastr.options.onclick = null;
	}

	switch (toast_type) {
		case TOAST_TYPES.error:
			toastr.error(message, title);
			break;
		case TOAST_TYPES.success:
			toastr.success(message, title);
			break;
		case TOAST_TYPES.warning:
			toastr.warning(message, title);
			break;
		case TOAST_TYPES.chat:
		case TOAST_TYPES.info:
		default:
			toastr.info(message, title);
			break;
	}
}

export { doToast, TOAST_TYPES }
