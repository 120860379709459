import React, { useEffect, useRef, useState, useContext } from 'react';
import { ChatContext } from "../../contexts";
import RobotIcon from '../../components/icons/Robot';
import UserIcon from '../../components/icons/User';

export const Message = ({ message, lineClamp, nextCallback, moreCallback, typingParagraphIndex, typingDoneCallback }) => {
	const { debug, recordProblem, submitChatMessage } = useContext(ChatContext);
	const containerRef = useRef(null);
	const featureIds = message.nextableFeatureIds;
	const typingParagraph = message.paragraphs[typingParagraphIndex];
	const [typingParagraphLength, setTypingParagraphLength] = useState(0);

	// For now, always after the 1st paragraph, show suggestions (thus the check for != 0 below).
	const [exposeSuggestions, setExposeSuggestions] = useState((message.suggestions.length > 0) && (typingParagraphIndex != 0));

	const setupClickHandlers = (message) => {
		if (containerRef.current) {
			const links = containerRef.current.querySelectorAll('.lci');
			const handleClick = e => {
				e.stopPropagation();
				const lciId = e.target.getAttribute('lciid');
				window.mobileWebController?.setSelectedContentItem(
					`ListingContentItem:${lciId}`,
					{ updateContentArea: true, updateScene: true }
				);
			};

			const handleAiFunctionClick = evt => {
				evt.stopPropagation();
				const el = evt.target.closest('.ai-func');
				const functionName = el.getAttribute('data-functionName');
				let params = el.getAttribute('data-params');
				try { params = JSON.parse(params); } catch (_) { }
				switch (functionName) {
					case 'applyNow':
					case 'bookTour':
						window.open(params?.url, '_blank', 'noreferrer');
						break;
					case 'showMap':
						window.mobileWebController.openDrawerForMapSearch(params.places || params);
						break;
				}
			};

			const aiFunctionElements = containerRef.current.querySelectorAll('.ai-func');
			aiFunctionElements.forEach(el => {
				el.removeEventListener('click', handleAiFunctionClick);
				el.addEventListener('click', handleAiFunctionClick);
			});

			links.forEach(el => {
				el.removeEventListener('click', handleClick);
				el.addEventListener('click', handleClick);
			});
		}
	}

	useEffect(() => {
		if (typingParagraph) {
			setTypingParagraphLength(0);
		}
	}, [typingParagraphIndex]);

	useEffect(() => {
		setupClickHandlers(message);
	}, [message]);

	useEffect(() => {
		if (typingParagraph) {
			if (typingParagraphLength < typingParagraph.html.length) {
				const timer = setTimeout(() => {
					let chunkSize = Math.floor(Math.random() * 3) + 2;
					let endChunkIndex = Math.min(
						typingParagraphLength + chunkSize,
						typingParagraph.html.length,
					);
					setTypingParagraphLength(endChunkIndex);
				},
					30 + Math.random() * 30,
				);
				return () => clearTimeout(timer);
			} else {
				if (!exposeSuggestions && (message.suggestions.length > 0)) {
					const fadeIn = (0 === typingParagraphIndex); // After first one is typed out, then show the suggestions.
					setExposeSuggestions((0 === typingParagraphIndex) ? 'fadeIn' : true);
					if (fadeIn) {
						setTimeout(() => { setExposeSuggestions(true); }, 3000);
					}
				}
				typingDoneCallback();
				setupClickHandlers(message);
			}
		}
	}, [message, typingParagraphLength, typingParagraphIndex]);

	useEffect(() => {
		const resizeTarget = containerRef.current;
		const chatContainer = document.getElementById("AiChatHistory");

		if (!resizeTarget || !chatContainer) return;

		const resizeObserver = new ResizeObserver(() => {
			if (typingParagraph) {
				[100, 300, 500].map((delay) =>
					setTimeout(
						() =>
						window.requestAnimationFrame(() =>
							chatContainer.scrollTo({
							top: chatContainer.scrollHeight,
							behavior: "smooth",
						}),
					),
					delay,
				));
			}
		});

		resizeObserver.observe(resizeTarget);

		return () => resizeObserver.unobserve(resizeTarget);
	  }, []);

	const onSuggestionSelect = prompt => {
		submitChatMessage(prompt);
		window.activityMonitor.aiPromptSelect(prompt);
	}

	const moreButtonEnabled = !!(moreCallback && message.paragraphs.some(p => p.isHidden));
	const nextButtonEnabled = !!(
		nextCallback &&
		message.isAnswer &&
		(featureIds.length > 1) &&
		message.selectedFeatureIndex + 1 < featureIds.length - 1);
	return <div ref={containerRef} className={`flex p-2 ${message.isAnswer ? 'mb-1' : ''} rounded-lg ${message.isAnswer ? 'brandContentColors' : 'bg-transparent'}`}>
		<div className="flex-shrink-0 mt-1 mr-2">{message.isAnswer ? <RobotIcon/> : <UserIcon />}</div>
		<div className="flex flex-col">
			{message.paragraphs.filter(p => !p.isHidden).map((p, i) =>
				<p key={i} className={`${lineClamp ? 'line-clamp-1' : ''} p-0 m-0`} dangerouslySetInnerHTML={{ __html: (typingParagraphIndex == i) ? p.html.substring(0, typingParagraphLength) : p.html }}></p>
			)}
			{message.isAnswer && debug && <DebugForm message={message} recordProblem={recordProblem}/>}
			{(moreButtonEnabled || nextButtonEnabled) && <div className={"pb-2 pl-0 mt-2 bg-transparent " + (lineClamp ? 'hidden' : '')}>
				{nextButtonEnabled &&
					<button onClick={(evt) => {
						evt.preventDefault();
						evt.stopPropagation();
						nextCallback && nextCallback();
					}}
						className="inline-flex items-center justify-center px-4 py-1 mr-3 border-none rounded-full brandCTAButtonColors">
						Next
					</button>
				}
				{moreButtonEnabled &&
					<button onClick={(evt) => {
						evt.preventDefault();
						evt.stopPropagation();
						moreCallback && moreCallback();
					}}
						className="inline-flex items-center justify-center px-4 py-1 border-none rounded-full brandCTAButtonColors">
						More
					</button>
				}
			</div>}
			{exposeSuggestions && <div className={'ai-suggested-prompts ' + (lineClamp ? 'hidden' : '')}>
				{message.suggestions.map((s, i) =>
					<button key={i} className={"flex flex-col ai-prompt-suggestion " + (('fadeIn' === exposeSuggestions) ? 'fadeIn' : '')} style={('fadeIn' === exposeSuggestions) ? { opacity: 0 } : {}}
						onClick={(evt) => { evt.preventDefault(); evt.stopPropagation(); onSuggestionSelect(s); }}>{s}</button>
				)}
			</div>}
		</div>
	</div>
};

const DebugForm = ({ message, recordProblem }) => {
	const [expanded, setExpanded] = useState(message.problem !== undefined);
	const inputRef = useRef(null);

	return (
		<div className="flex flex-col">
			<i className={`${expanded || message.problem ? 'text-red-600' : 'text-white'} material-icons`} onClick={(e) => {
				e.stopPropagation();
				recordProblem(message, ' ');
				setExpanded(true);
			}}>flag</i>
			{expanded && <>
				<textarea className="w-full h-20 text-black" ref={inputRef} defaultValue={message.problem} onClick={(e) => e.stopPropagation()}></textarea>
				<button className="self-end mt-1 text-black" onClick={(e) => {
					recordProblem(message, inputRef.current.value.trim());
					e.stopPropagation();
				}}>Submit</button>
			</>}
		</div>
	);
};
