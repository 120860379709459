import React from "react";

export default function AiThinking() {
	return <div className="mx-auto hal9000-spinner working">
		<div className="layer">
			<div className="inner"></div>
		</div>
		<div className="layer">
			<div className="inner"></div>
		</div>
		<div className="layer">
			<div className="inner"></div>
		</div>
		<div className="layer">
			<div className="inner"></div>
		</div>
		<div className="layer">
			<div className="inner"></div>
		</div>
	</div>;
}
