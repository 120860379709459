/**
 * A model of a single telemetry set (room state) in the form that's applicable to floor plans/maps.
 * A list of clientInstances with {clientInstanceId, connected, }
 */
class MapTelemetryModel {
	constructor(data) {
		this.clientInstances = {};
		if (data && data.room && data.room.client_instances) {
			for (const id in data.room.client_instances) {
				const ci = data.room.client_instances[id];
				const loc = (ci.view && ci.view.content && ci.view.content.location && ci.view.content.location.calculated) ? ci.view.content.location.calculated : { lat: 0, lng: 0 };
				this.clientInstances[id] = {
					clientInstanceId: id,
					connected: true,
					content: (ci && ci.view && ci.view.content) ? ci.view.content.contentPath : '',
					heading: (ci && ci.view && ci.view.content) ? ci.view.content.heading : null,
					location: {
						lat: loc.lat,
						lng: loc.lng
					},
					username: id
				}
			}
		}
	}

	get clientInstanceCount() {
		return Object.keys(this.clientInstances).length;
	}

	/**
	 * Return a list of clientInstanceIds that have changed since 'otherModel'.
	 * @param {MapTelemetryModel} otherModel MapTelemetryModel to compare this one to.
	 */
	getChangedClientInstanceIds(otherModel) {
		if (!otherModel) { otherModel = { clientInstances: {} }; }

		const result = {
			added: [],
			removed: [],
			floorPlanUpdated: [],
			mapsUpdated: []
		};

		// Changed or new.
		for (const id in this.clientInstances) {
			const a = this.clientInstances[id];
			const b = otherModel.clientInstances[id];
			if (!b) {
				result.added.push(a);
			} else {
				if ((a.content !== b.content) || (a.heading !== b.heading)) {
					result.floorPlanUpdated.push(a);
				}
				if ((a.lat !== b.lat) || (a.lng !== b.lng) || (a.heading !== b.heading)) {
					result.mapsUpdated.push(a);
				}
			}
		}

		// Removed.
		for (const id in otherModel.clientInstances) {
			if (!this.clientInstances[id]) {
				result.removed.push(otherModel.clientInstances[id]);
			}
		}

		return result;
	}

	getClientInstance(clientInstanceId) {
		const ci = this.clientInstances[clientInstanceId];
		return ci ? ci : { clientInstanceId: clientInstanceId, connected: false, username: clientInstanceId };
	}
}

export { MapTelemetryModel }
