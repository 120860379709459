import React, { useEffect, useState } from "react";
import { AUTOTOUR_STATE } from "../../viewer/auto-tour.mjs";

export const PlayButton = () => {
	const [visible, setVisible] = useState(false);
	const [iconName, setIconName] = useState('play');

	const updateFromAutoTour = (newState) => {
		setVisible(window.mobileWebController.hasAutoTour());
		switch (newState) {
			case AUTOTOUR_STATE.PLAYING:
				setIconName('pause');
				break;
			case AUTOTOUR_STATE.FINISHED:
				setIconName('replay');
				break;
			default:
				setIconName('play_arrow');
		}
	}

	const onButtonClick = () => {
		window.mobileWebController.toggleAutoTourPlayState();
	}

	useEffect(() => {
		window.mobileWebController.onAutoTourChange = updateFromAutoTour;
		updateFromAutoTour();
	}, []);

  	return visible &&
		<button id="AutoTourControl" className="absolute flex items-center p-2 text-white border-none rounded-full bottom-6 right-2 z-1000"
			onClick={onButtonClick}>
				<i className="material-icons">{ iconName }</i>
		</button>;
};
