/**
* Viewer-side (iframe-side) API client, allowing interaction between parent window and viewer iframe.
*/
class ViewerIframeApi {
	constructor(options) {
		this._viewerModel = options?.viewerModel;
		this._sendRoomId();
	}

	_sendRoomId() {
		console.debug('[viewer-iframe-api]', 'Posting RoomID message.', this?._viewerModel?.chatId);
		window.parent.postMessage({ command: 'roomId', data: this?._viewerModel?.chatId }, '*');
		return this;
	}
}

export { ViewerIframeApi }
