import React from "react";

export default function User(props) {
  return (
    <svg {...props} width="19px" height="16px">
      <g transform="matrix(1 0 0 1 -10 -24 )">
        <path d="M 12.767857142857146 13.254838709677419  C 12.814285714285715 10.63548387096774  11.142857142857142 8.467741935483872  8.821428571428571 7.564516129032258  C 9.982142857142858 6.841935483870967  10.725000000000001 5.57741935483871  10.725000000000001 4.132258064516129  C 10.725000000000001 1.8741935483870962  8.821428571428571 0.02258064516128984  6.5 0.02258064516128984  C 4.178571428571429 0.02258064516128984  2.2750000000000004 1.8741935483870962  2.2750000000000004 4.132258064516129  C 2.2750000000000004 5.5774193548387085  3.017857142857143 6.841935483870967  4.178571428571429 7.564516129032258  C 1.857142857142857 8.467741935483872  0.23214285714285712 10.680645161290322  0.23214285714285712 13.254838709677419  C 0.23214285714285712 13.570967741935485  0.5107142857142856 13.796774193548389  0.7892857142857139 13.796774193548389  C 1.1142857142857137 13.796774193548389  1.3464285714285709 13.525806451612905  1.3464285714285709 13.254838709677419  C 1.3464285714285709 10.5  3.6678571428571423 8.241935483870968  6.5 8.241935483870968  C 9.332142857142857 8.241935483870968  11.653571428571428 10.5  11.653571428571428 13.254838709677419  C 11.653571428571428 13.570967741935485  11.932142857142859 13.796774193548389  12.210714285714287 13.796774193548389  C 12.535714285714288 13.796774193548389  12.767857142857146 13.525806451612905  12.767857142857146 13.254838709677419  Z M 9.564285714285715 4.132258064516129  C 9.564285714285715 5.758064516129033  8.217857142857142 7.112903225806452  6.5 7.112903225806452  C 4.7821428571428575 7.112903225806452  3.435714285714285 5.758064516129033  3.435714285714285 4.132258064516129  C 3.435714285714285 2.461290322580645  4.828571428571428 1.1516129032258058  6.5 1.1516129032258058  C 8.217857142857142 1.1516129032258058  9.564285714285715 2.5064516129032253  9.564285714285715 4.132258064516129  Z " fillRule="nonzero" fill="#ffffff" stroke="none" transform="matrix(1 0 0 1 10 24 )" />
      </g>
    </svg>
  );
}
