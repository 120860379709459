/**
 * @file A user (logged in or not) to infinityy.
 */

/**
 * Model of the user in this session. Logged in or not.
 * @class
 */
class UserModel {
	constructor() {
		/** @member {string} */
		this.authToken = null;
		this.avatarPhotoUrl = null;
		/** @member {string} displayName The name displayed to the user. */
		this.displayName = null;
		/** @member {number=} */
		this.identityId = null;
		/** @member {string=} username The ID (email address) used to login to infinityy. */
		this.username = null;
		/** @member {string=} */
		this.participantId = null;
		this.preferredEmailClient = 0;
	}

	get isIdentified() {
		return this.isLoggedIn || !!this.displayName;
	}

	get isLoggedIn() {
		return !!this.authToken;
	}

	/**
	 * @return {string}
	 */
	get login() {
		return this.username;
	}

	getDisplayNameOrDefault() {
		const currName = window.mobileWebController.userModel.displayName || "";
		return currName.trim() ? currName.trim() : `Guest_${window.mobileWebController.clientInstanceId.substring(0, 4)}`;
	}
}

export { UserModel }
