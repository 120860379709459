// TODO: If google maps and places never loads we're just going to keep queueing up tasks on content loads.
// Set a timeout for the case where it fails to load and just clear the queue and stop queueing at that point.

let queue = [];

function geocode(location, address, parentLocation, parentAddress, callback) {
	const task = {
		location: location,
		address: address,
		parentLocation: parentLocation,
		parentAddress: parentAddress,
		callback: callback
	};
	return processOrQueueGeocodeTask(task);
}

function isGeocoderLoaded() {
	return (('undefined' !== typeof (google)) && google.maps && google.maps.Geocoder && google.maps.places && google.maps.places.PlacesService);
}

function getActionToTake(task) {
	let result = {
		callback: task.callback,
		done: null,
		geocode: null
	};

	for (const [loc, addr] of [[task.location, task.address], [task.parentLocation, task.parentAddress]]) {
		if (!result.done && !result.geocode && loc) {
			if (loc.calculated) {
				return { done: loc };
			} else if (loc.latLng) {
				loc.calculated = loc.latLng;
				return { done: loc };
			} else if (loc.placeId) {
				result.geocode = { placeId: loc.placeId };
			}
		}

		if (!result.done && !result.geocode && addr) {
			result.geocode = { address: addr };
		}
	}

	return result;
}

function processOrQueueGeocodeTask(task) {
	const result = getActionToTake(task);
	if (!result.geocode) {
		result.callback(result.done);
		return true; // Processed
	}
	queueOrProcessGeocodeTask({
		geocode: result.geocode,
		callback: result.callback
	});
	return false; // Queued or asynchronously executing.
}

function queueOrProcessGeocodeTask(task) {
	if (!isGeocoderLoaded()) {
		queue.push(task);
	} else {
		processGeocodeTask(task);
	}
}

let placesService = null;
let geocoderService = null;
function getPlacesService() {
	if (null === placesService) {
		placesService = new google.maps.places.PlacesService();
	}
	return placesService;
}

function getGeocoderService() {
	if (null === geocoderService) {
		geocoderService = new google.maps.Geocoder();
	}
	return geocoderService;
}

function processGeocodeTask(task) {
	if (task.geocode.placeId) {
		getPlacesService().getDetails({ placeId: task.geocode.placeId, fields: ['geometry'] }, function (place, status) {
			if ((google.maps.places.PlacesServiceStatus.OK === status) && place && place.geometry && place.geometry.location) {
				task.callback({
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng()
				});
			}
		});
	} else {
		getGeocoderService().geocode({ address: task.geocode.address }, function (results, status) {
			if ('OK' === status && results && results.length > 0) {
				let r = results[0];
				task.callback({
					calculated: { lat: r.geometry.location.lat(), lng: r.geometry.location.lng() },
					placeId: r.place_id
				});
			}
		});
	}
}

function processEntireQueue() {
	const q = queue;
	queue = [];
	for (const task of q) {
		processGeocodeTask(task);
	}
}

if (!isGeocoderLoaded()) {
	$('body').one('LOADED:GOOGLE-MAPS', processEntireQueue);
}

export { geocode }
