import consumer from "./actioncable-consumer.js"

export default function initializeRoomsChannel(roomId) {
	if (!roomId) {
		setTimeout(initializeRoomsChannel, 1000);
		return;
	}
	console.log(`[telemetry] connecting on ${roomId}`, Date.now() * .001);
	window.roomsChannel = consumer.subscriptions.create({ channel: 'RoomsChannel', id: roomId }, {
		connected() {
			console.log('[telemetry] connected', Date.now() * .001);
		},

		disconnected() {
			console.log('[telemetry] disconnected', Date.now() * .001);
		},

		received(data) {
			if (!data) { return; }
			if (!data.method) {
				data.method = 'broadcast-room';
			}
			if (data.method !== 'broadcast-room') {
				return;
			}
			data = data.params;
			_receiveTelemetry(data);
		}
	});
};

let _receiveTelemetry = function () { };
function receiveTelemetry(f) {
	_receiveTelemetry = f;
}

export { receiveTelemetry }
