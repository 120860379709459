import { isMobileDevice } from "./browser-utils.es6.js"
import { IrUtils } from "./utils.es13.js"

const initialUrl = window.pageInitialization.initialUrl;

/**
 * Get the base URL for the desktop website, potentially using a branded domain.
 * @param {string=} path additional path on the URL.
 * @return {string} url
 */
function getInfinityyDesktopUrl(path) {
	if (IrUtils.isNullyOrEmptyString(path)) { path = ''; }
	return window.siteSettings.InfinityyApiUrl + path;
}

function getInfinityyViewerUrl(options) {
	let url = initialUrl.setRootUrl(window.siteSettings.InfinityyApiUrl).cloneWithoutTemporaryParams();
	if (options?.emailAddress) {
		url = url.setLeadEmail(options.emailAddress);
	}
	if (options?.noRedirect) {
		url = url.setNoRedirect(true);
	}
	if (options?.contentOnlyLink) {
		url = url.cloneAsContentAndChatLink().cloneWithoutTemporaryParams();
	}
	return url.toString();
};

function getShareLink() {
	return getInfinityyViewerUrl({contentOnlyLink: true});
}

function redirectToDesktopViewerIfNecessary() {
	if (!window.siteSettings.RedirectDesktopViewer) {
		return false;
	}
	if (!initialUrl.contentGuid && !initialUrl.brandSlug) {
		window.location = window.siteSettings.RedirectDefaultWebsiteUrl;
		return true;
	}
	if (initialUrl.noRedirect) {
		return false;
	}
	if (!isMobileDevice()) {
		window.location = initialUrl.setRootUrl(window.siteSettings.InfinityyApiUrl).setNoRedirect(true).toString();
		return true;
	}
	return false;
};

export { getInfinityyDesktopUrl, getInfinityyViewerUrl, getShareLink, redirectToDesktopViewerIfNecessary }
