/**
 * UI helper functions and legacy helpers.
 */
const SELECTED = 'selected';

function exists(obj) {
	return (typeof obj !== "undefined" && obj !== null && obj !== "");
};

function addCommasToNumber(number, addSF = true, showDecimals = true) {
	let textNumber = number + "";
	let remaining;
	let formattedNumber = "";
	if (!exists(number)) {
		return "Unknown SF";
	}

	// Remove SF if needed
	if (number.includes("SF")) {
		textNumber = number.split("SF")[0].trim();
	}
	else if (number.includes("sf")) {
		textNumber = number.split("sf")[0].trim();
	}

	// Remove commas just to be consistent
	textNumber = textNumber.replace(/,/g, "");

	// decimalValue[0] is whether the number contains a decimal point, and decimalValue[1] is the value after the point.

	let decimalValue = [false, 0];
	if (number.includes(".")) {
		decimalValue = [true, textNumber.split(".")[1].trim()];
		textNumber = textNumber.split(".")[0].trim()
	}

	if (textNumber.length % 3 > 0) {
		let modResult = textNumber.length % 3;
		formattedNumber = textNumber.substring(0, modResult) + ",";
		remaining = textNumber.substring(modResult);
	} else {
		remaining = textNumber;
	}
	while (remaining.length > 0) {
		formattedNumber += remaining.substring(0, 3) + ",";
		remaining = remaining.substring(3);
	}
	//add commas
	formattedNumber = formattedNumber.substring(0, formattedNumber.length - 1);

	//add decimal back if it had one
	if (decimalValue[0] && showDecimals) {
		formattedNumber += "." + decimalValue[1];
	}

	//add SF
	if (addSF) {
		formattedNumber = formattedNumber + " SF";
	}

	return formattedNumber;
}

function formatFloor(floor) {
	let formattedFloor = "";
	switch (floor) {
		case "":
			formattedFloor = "—";
			break;
		case "G":
			formattedFloor = "Ground Floor";
			break;
		case "LL":
			formattedFloor = "Lower Level";
			break;
		case "B":
			formattedFloor = "Basement";
			break;
		default:
			var floorRemainder = floor % 10;
			var suffix = "th";

			switch (floorRemainder) {
				case 1:
					if (floor != 11) {
						suffix = "st";
					}
					break;
				case 2:
					if (floor != 12) {
						suffix = "nd";
					}
					break;
				case 3:
					if (floor != 13) {
						suffix = "rd";
					}
					break;
				default:
					suffix = "th"
			}

			formattedFloor = floor + suffix + " Floor";
	}
	return formattedFloor;
};

function formatDate(fromDate) {
	var now = new Date();
	// iPhone Safari doesn't support the Y-m-d H:i:s (ISO 8601) date format (as of 3/24/22)
	let convertDateForIosSupport = function (date) {
		var arr = date.split(/[- :]/);
		let year = arr[0];
		let month = arr[1] - 1;
		let day = arr[2];
		let hour = arr[3];
		let min = arr[4];
		let seconds = arr[5];
		if (exists(min) && exists(seconds)) {
			date = new Date(year, month, day, hour, min, seconds);
		} else if (exists(min)) {
			date = new Date(year, month, day, hour, min);
		} else {
			date = new Date(year, month, day, hour);
		}

		return date;
	};
	var past = new Date(convertDateForIosSupport(fromDate.replace("T", " ")));
	var output;

	var hour = past.getHours();
	hour = (hour < 10 ? "0" : "") + hour;

	var min = past.getMinutes();
	min = (min < 10 ? "0" : "") + min;

	var sec = past.getSeconds();
	sec = (sec < 10 ? "0" : "") + sec;

	output = (past.getMonth() + 1) + "-" + past.getDate() + "-" + past.getFullYear(); // + " " + hour + ":" + min + ":" + sec;
	return output;
}

/**
 * Toggle a jQueryDialog's open state and potentially focus another element if $dialog is going from closed to open.
 * @param {jQueryDialog} $dialog The dialog to open or close.
 * @param {boolean} doOpen Or not.
 * @param {{onOpen: function=, onClose: function=}} onOpen/onClose If $dialog goes from closed to open or vice versa, call these optional functions.
 * @return {jQueryDialog} The dialog being toggled.
 */
function toggleDialog($dialog, doOpen, options) {
	options = options || {}
	const isOpen = $dialog.dialog('isOpen');
	const changed = ((doOpen && !isOpen) || (!doOpen && isOpen));
	$dialog.dialog(doOpen ? 'open' : 'close');
	if (changed) {
		if (doOpen && !!options.onOpen) {
			options.onOpen();
		} else if (!!options.onClose) {
			options.onClose();
		}
	}
	return $dialog;
}

function switchTabsByIndex(eventOrIndex, tabGroupId, contentGroupId) {
	//for non-click events, an index must be provided.
	var desiredIndex = eventOrIndex;
	if (eventOrIndex.currentTarget) {
		//if it has a currentTarget, it's an event, so get the index of it.
		//jquery index starts at 0, but nth child starts at 1, so we add 1.
		desiredIndex = $(eventOrIndex.currentTarget).index() + 1;
	}
	var nthChildFormat = ":nth-child(" + desiredIndex + ")";
	$(tabGroupId + " > *").removeClass("selected");
	$(contentGroupId + " > *").removeClass("selected");
	$(tabGroupId + " > *" + nthChildFormat).addClass("selected");
	$(contentGroupId + " > *" + nthChildFormat).addClass("selected");
};

export { addCommasToNumber, toggleDialog, switchTabsByIndex, formatFloor, formatDate }
