/**
 * @file The state of the header menu and page modals (for now).
 */

const ModalId = {
	CHAT: 'chat',
	CONTACT_US: 'contact-us',
	IDENTIFY: 'identify',
	INVITE: 'invite',
	LOGIN: 'login',
	SAVEROOM: 'save-room',
	NONE: ''
};

/**
 * State of the header menu and page modals (for now).
 * @class
 */
class HeaderMenuModel {
	constructor(args) {
		this.audioMuted = false;
		this.buildingApplyNowUrl = null;
		this.buildingBookingUrl = null;
		this.callConnectButtonEnabled = true;
		this.callConnected = false;
		this.chatButtonHighlighted = false;
		this.contentId = args?.contentId;
		this.hamburgerMenuOpen = false;
		this.identifyDialogDisplayName = '';
		this.modalOpen = ModalId.NONE;
		this.showInviteExplanation = false;
		this.showLoadingAnimation = true;
		this.videoMuted = false;
	}

	get audioMuteButtonEnabled() {
		return this.callConnected;
	}

	get chatModalOpen() {
		return (ModalId.CHAT === this.modalOpen);
	}

	get contactUsModalOpen() {
		return (ModalId.CONTACT_US === this.modalOpen);
	}

	get identifyModalOpen() {
		return (ModalId.IDENTIFY === this.modalOpen);
	}

	get inviteModalOpen() {
		return (ModalId.INVITE === this.modalOpen);
	}

	get saveRoomModalOpen() {
		return (ModalId.SAVEROOM === this.modalOpen);
	}

	get loginModalOpen() {
		return (ModalId.LOGIN === this.modalOpen);
	}

	get videoMuteButtonEnabled() {
		return this.callConnected;
	}

	setBuilding(building) {
		this.buildingApplyNowUrl = building?.LinkUrl;
		this.buildingBookingUrl = building?.BookTourUrl;
		return this;
	}
}

export { HeaderMenuModel, ModalId }
