import { normalizeLatLng } from "../utils/map-utils.es6.js";

class PoiSearch {
	constructor(options) {
		const defaultOptions = {
			map: new google.maps.Map(document.createElement('DIV')),
			placesService: null,
			pageSize: 5 // valid range: 1...20
		}
		this._config = $.extend({}, defaultOptions, options);
		this._searchCenter = normalizeLatLng(null);
		this._currentSearch = this._getDefaultSearchState();
		this._service = this._config.placesService || new google.maps.places.PlacesService(this._config.map);
	}


	get config() {
		const calculated = {
			center: this._searchCenter,
			maxPageCount: Math.ceil(20 / this._config.pageSize) // Google's max result count is 60, but we're sticking with 20 for now to avoid a pagination bug.
		}
		return $.extend({}, this._config, calculated);
	}

	get pageSize() {
		return this._config.pageSize;
	}

	set pageSize(val) {
		if (!isNaN(val)) {
			this._config.pageSize = Math.ceil(Math.abs(val));
		}
		return this._config.pageSize;
	}

	get searchCenter() {
		return this._searchCenter;
	}

	set searchCenter(val) {
		const provided = normalizeLatLng(val);
		if (provided.isValid || val === null) {
			this._searchCenter = provided;
		} else {
			console.debug("[PoiSearch]", "searchCenter: invalid value provided", val);
		}

		return this._searchCenter;
	}

	async search(queryText) {
		if (!queryText) {
			return null;
		}

		if (queryText != this._currentSearch.searchString) {
			this.reset();
			this._currentSearch.searchString = queryText;
		}

		return await this._getPage(0);
	}

	async getNextPage() {
		return this._getPage(this._normalizePageNumber(this._currentSearch.page + 1));
	}

	async getPrevPage() {
		return this._getPage(this._normalizePageNumber(this._currentSearch.page - 1));
	}

	reset() {
		this._currentSearch = this._getDefaultSearchState();
	}

	async _getPage(page) {
		if (isNaN(page)) {
			return null;
		}

		if (this._currentSearch.page == page) {
			return this._currentSearch.results;
		}

		this._currentSearch.page = page;

		const numToSkip = page * this.config.pageSize;
		const displayUpTo = numToSkip + this.config.pageSize;

		const startingCacheLength = this._currentSearch.results.length;
		const needToQuery = !this._currentSearch.queryFailed && (
			startingCacheLength <= numToSkip || (
				startingCacheLength < displayUpTo && this._currentSearch.gm_paginator?.hasNextPage
			)
		);

		if (needToQuery) {
			await this._backfillGoogleData(displayUpTo);
		}

		return this._currentSearch.results.slice(numToSkip, displayUpTo);
	}

	_backfillGoogleData(resultCountTarget) {
		resultCountTarget = Math.ceil(Math.max(0, Math.min(resultCountTarget, 20))); // Enforce result count between 0 and 60 (Google's max)
		const me = this;
		return new Promise(function(resolve) {
			const _handleResults = (results, status, pagination) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					if (pagination) {
						me._currentSearch.gm_paginator = pagination;
					}
					me._currentSearch.results = [...me._currentSearch.results, ...results];

					if (me._currentSearch.results.length >= resultCountTarget) {
						resolve();
					} else {
						me._backfillGoogleData(resultCountTarget);
					}
				} else  {
					me._currentSearch.queryFailed = true;
					if (status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
						me._currentSearch.error = JSON.stringify(status);
					}
				}
			}

			if (me._currentSearch.results.length === 0 && !me._currentSearch.queryFailed && me._searchCenter.isValid) {
				const query = {
					keyword: me._currentSearch.searchString,
					location: me._searchCenter,
					rankBy: google.maps.places.RankBy.DISTANCE
				};

				me._service.nearbySearch(query, _handleResults);
			} else if (me._currentSearch.gm_paginator?.hasNextPage) {
				me._currentSearch.gm_paginator.nextPage();
			} else {
				console.debug("[PoiSearch]", "search: Unable to proceed.", `query failed?: ${me._currentSearch.queryFailed}`, me._searchCenter)
				resolve();
			}
		});
	}

	_normalizePageNumber(page) {
		page = page ? page : 0; // make sure it exists
		page = isNaN(page) ? 0 : Math.floor(page); // make sure it's an integer
		if (isNaN(page) || page < 0) {
			return 0;
		} else if (page >= this.config.maxPageCount) {
			return this.config.maxPageCount - 1; // page is zero-based
		} else {
			return page;
		}
	}

	_getDefaultSearchState() {
		return {
			page: -1,
			queryFailed: false,
			results: [],
			searchString: '',
			gm_paginator: null
		}
	}
}

export { PoiSearch }
