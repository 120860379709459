import { IrUtils } from "../utils/utils.es13.js"
import { ParticipantState } from "./participant-state.es6.js"

/**
 * Model for a PIP.
 */
class PipModel {
	constructor(clientInstanceId, options) {
		options = options || {};
		/** @member {string} */
		this.clientInstanceId = clientInstanceId;
		/** @member {string} */
		this.displayName = IrUtils.toEmptyStringIfNully(options.displayName);
		/** @member {string} */
		this.avatarPhotoUrl = IrUtils.toEmptyStringIfNully(options.avatarPhotoUrl);
		/** @member {ParticipantState} */
		this.participantState = options.participantState || new ParticipantState();
		this.audioTrack = null;
		this.videoTrack = null;
	}

	// In regards to telemetry. Ignore audio/video track components.
	equals(otherPip) {
		return otherPip
			&& (this.clientInstanceId === otherPip.clientInstanceId)
			&& (this.displayName === otherPip.displayName)
			&& (this.avatarPhotoUrl === otherPip.avatarPhotoUrl)
			&& (this.participantState.equals(otherPip.participantState));
	}

	// Set via telemetry. Ignore audio/video track components.
	set(value) {
		this.clientInstanceId = value.clientInstanceId;
		this.displayName = value.displayName;
		this.avatarPhotoUrl = value.avatarPhotoUrl;
		this.participantState = value.participantState;
		return this;
	}
}

export { PipModel }
