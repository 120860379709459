const AUTOTOUR_STATE = {
	INITIALIZED: 'initialized',
	PLAYING: 'playing',
	PAUSED: 'paused',
	FINISHED: 'finished'
}

class AutoTour {
	constructor(lciList, options) {
		const defaultOptions = {
			timeoutBetween: 2000,
			timeoutDefault: 6000
		}
		this._config = $.extend({}, defaultOptions, options);
		lciList = lciList ?? [];
		this._lciList = lciList.filter((v, i, a) => { return (v != a[i + 1]); });

		this._currentIndex = 0;
		this._timeoutHandle = null;
		this._state = AUTOTOUR_STATE.INITIALIZED;
		this.onRequestNavigate = (lciId) => { return false; }
		this.onStateChanged = (newState) => { return false; }
		this.onFinished = () => { return false; }
	}

	get state() {
		return this._state;
	}

	end() {
		this._log('end()');
		this._updateState(AUTOTOUR_STATE.FINISHED);
		clearTimeout(this._timeoutHandle);
		this.onFinished();
		return this;
	}

	next() {
		this._log('next()');
		clearTimeout(this._timeoutHandle);
		this.onRequestNavigate(this._lciList[this._currentIndex]);
		if (this._state == AUTOTOUR_STATE.PLAYING) {
			if (this._lciList.length > this._currentIndex) {
				this._currentIndex++;
				const me = this;
				this._timeoutHandle = setTimeout(() => { me.next() }, this._config.timeoutDefault);
			} else {
				this.end();
			}
		}
		return this;
	}

	pause() {
		this._log('pause()');
		this._updateState(AUTOTOUR_STATE.PAUSED);
		clearTimeout(this._timeoutHandle);
		return this;
	}

	resume() {
		this._log('resume()');
		this._updateState(AUTOTOUR_STATE.PLAYING);
		this.next();
		return this;
	}

	restart() {
		this._currentIndex = 0;
		this.resume();
	}

	togglePlayState() {
		this._log('togglePlayState()');
		if (this._state == AUTOTOUR_STATE.PLAYING) {
			this.pause();
		} else if (this._state == AUTOTOUR_STATE.FINISHED) {
			this.restart();
		} else { // INITIALIZED or PAUSED
			this.resume();
		}
	}

	waitForTts() {
		this._log('waitForTts()');
		clearTimeout(this._timeoutHandle);
		return this;
	}

	resumeFromTts(success) {
		this._log('resumeFromTts()');
		if (this._state == AUTOTOUR_STATE.PLAYING) {
			let timeout = success ? this._config.timeoutBetween : this._config.timeoutDefault;
			const me = this;
			this._timeoutHandle = setTimeout(() => { me.next() }, timeout);
		}
	}

	_updateState(state) {
		this._state = state;
		this.onStateChanged(state);
	}

	_log(message) {
		console.debug('[AutoTour]', message, Date.now() * 0.001);
	}
}

export { AutoTour, AUTOTOUR_STATE };
