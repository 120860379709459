import React, { useEffect, useRef } from "react";
import { Panel, PanelResizeHandle, PanelGroup } from "react-resizable-panels";
import { AiChat } from "../chat/ai/AiChat.jsx";
import { ChatProvider } from "../contexts";
import { TopInfoSection } from "./TopInfoSection.jsx";
import { PlayButton as AutotourPlayButton } from "./autotour/PlayButton.jsx";

const Viewer = () => {
	return <div className="flex flex-col h-full">
		<div id="viewer-wrapper" className="relative flex flex-grow-1">
			<iframe id="iguide-viewer" style={{ display: "none" }}></iframe>
			<iframe id="matterport-viewer" style={{ display: "none" }}></iframe>
			<div id="streetview-viewer" style={{ display: "none" }}></div>
			<div id="everyscape-viewer" style={{ display: "none" }}></div>
			<div id="olio-viewer" style={{ display: "none" }}></div>
			<div id="video-viewer" style={{ display: "none" }}>
				<div className="video-viewer container-layer">
					<video id="video-viewer-video" className="video-viewer container-layer video-js" controls muted="muted" playsInline="playsinline" preload="auto" autoPlay>
					</video>
				</div>
			</div>
			<AutotourPlayButton />
		</div>
		{window.contentModel?.useAi ?
			<PanelResizeHandle className="flex justify-center portrait-only resize-handle-space bg-brandContent">
				<div className="relative flex items-center justify-center w-full pt-1">
					<div className="absolute inset-0 w-full h-8"></div>
					<div className="w-24 h-1.5 bg-gray-400 rounded-full hover:bg-white"></div>
				</div>
			</PanelResizeHandle> :
			<div id="ContentDescription" className="px-3 py-1 text-sm bg-[var(--brandMenuColor)] h-16 overflow-y-scroll"/>
		}
		<TopInfoSection />
	</div>
};

const PanelWithDivider = ({ TopComponent, BottomComponent }) => {
	const panelRef = useRef(null);

	useEffect(() => {
		window.mobileWebController.mainContainerFirstPanel = panelRef.current;
	}, [panelRef]);

	return <PanelGroup direction="vertical" id="main-container-panel-group">
		<Panel
			ref={panelRef}
			className="flex flex-col bg-black"
			minSize={35}
			maxSize={80}
			defaultSize={55}
		>
			<TopComponent/>
		</Panel>
		<Panel className="portrait-only">
			<BottomComponent />
		</Panel>
	</PanelGroup>
};

export const MainContainer = () => {
	useEffect(() => {
		// Update the existing controller-driven views only
		// after dom elements coming from React context are in.
		window.mobileWebController.onMainContainerRendered();
	}, []);

	useEffect(() => {
		const handleClick = (event) => {
			if (!$("#BottomDrawer").has(event.target).length > 0) {
				window.mobileWebController.bottomDrawerView.minimizeBottomDrawer();
			}
		}

		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	if (window.contentModel?.useAi)
	{
		return <ChatProvider>
			<PanelWithDivider TopComponent={Viewer} BottomComponent={AiChat} />
		</ChatProvider>
	} else {
		return <Viewer />
	}
};
