const ChatroomControllerStates = {
	DISCONNECTED: 'disconnected',
	CONNECTING_CHECKING_PERMISSIONS: 'connecting:checking-permissions',
	CONNECTING_PERMISSIONS_MODAL: 'connecting:permissions-modal',
	CONNECTING_BROWSER_PERMISSIONS_CHECK: 'connecting:browser-permissions-check',
	CONNECTING_DEVICES_MODAL: 'connecting:devices-modal',
	CONNECTING_TO_ROOM: 'connecting:connect-to-room',
	CONNECTED: 'connected',
	UPDATING_MEDIA_DEVICES: 'connected:updating-media-devices'
};

const platformUtils = {
	isIos: function () {
		return navigator && (navigator.platform && /(ipad|ipod|iphone)/i.test(navigator.platform));
	},
	isIosOrAndroid: function () {
		return navigator && (
			(navigator.platform && /(ipad|ipod|iphone|android)/i.test(navigator.platform)) ||
			(navigator.userAgent && /android/i.test(navigator.userAgent))
		);
	}
};

/**
 * Controller state machine for managing twilio chat room.
 * The connect() workflow requires several user interactions.
 * Disconnect and switching rooms can happen while other outstanding asynchronous events are occurring.
 *
 * @param {ChatroomUtils} chatroomUtils Dependency injection.
 * @param {function} getAccessToken Dependency injection. ({string} clientInstanceId, {string} chatId) => {Promise} resolving with {string} token.
 * @param {function} twilioVideo Dependency injection.
 * @param {function} getTelemetryInstanceId Dependency injection. () => string
 * @param {number} chatId
 */
function ChatroomController(chatroomUtils, getAccessToken, twilioVideo, getTelemetryInstanceId, chatId) {
	this._utils = chatroomUtils;
	this._getAccessToken = getAccessToken;
	this._twilioVideo = twilioVideo;
	this._getTelemetryInstanceId = getTelemetryInstanceId;
	this._chatId = chatId;
	this._chatroomId = window.siteSettings.TwilioRoomIdFormat.replace('{0}', chatId);
	this._state = ChatroomControllerStates.DISCONNECTED;
	this._chatroom = null;

	this.selfMicrophoneEnabled = true;
	this.selfCameraEnabled = true;

	/**
	 * Track options (deviceIds) used on the last attempted connection.
	 * Saved during connect so if devices are reconfigured, the UI will
	 * default to the already selected deviceId
	 */
	this._defaultTrackOptions = {};
	this._mediaDeviceChangeTimer = null;

	// This should be set when callosum connects. It should be set to ONLY one value ever. It's the unique ID for
	// user's time on the page in this browser tab/session.
	this.telemetryClientInstanceId = null;

	// View should connect to these methods.
	this.onError = function (_id) { };
	this.onShowPermissionsModal = function () { };
	/**
	 * This should be hooked by the view for when the connect workflow needs to popup the "devices" modal.
	 * @param {any} devices {microphones: [MediaDeviceInfo], cameras: [MediaDeviceInfo], permissions: []}
	 * @param {any} defaultTrackOptions optional {audio: {deviceId: string}, video: {deviceId: string}}
	 */
	this.onShowDevicesModal = function (_devices, _defaultTrackOptions) { };
	this.onDevicesChange = function (_devices, _defaultTrackOptions) { };
	this.onConnected = function (_localTracks) { };
	this.onShowParticipant = function (_clientInstanceId, _videoTrack) { };
	this.onHearParticipant = function (_clientInstanceId, _audioTrack) { };
	this.onMuted = function (_clientInstanceId, _isMuted) { };
	this.onSelfMicrophoneEnabled = function (_isEnabled) { };
	this.onVideoDisabled = function (_clientInstanceId, _isDisabled) { };
	this.onSelfCameraEnabled = function (_isEnabled) { };
	this.onDominantSpeakerChanged = function (_clientInstanceId) { };
	this.onDisconnected = function () { };

	var me = this;
	if (navigator && navigator.mediaDevices) {
		navigator.mediaDevices.addEventListener('devicechange', function (evt) { return me._onMediaDevicesChange(evt); });
	}
};

ChatroomController.prototype._log = function (_name, _message, _data, _level) {
	return this;
};

ChatroomController.prototype._error = function (name, message, data) {
	return this._log(name, message, data, 'error');
};

ChatroomController.prototype.isConnected = function () {
	return (ChatroomControllerStates.CONNECTED === this._state);
};

ChatroomController.prototype.isDisconnected = function () {
	return (ChatroomControllerStates.DISCONNECTED === this._state);
};

ChatroomController.prototype.getChatId = function () {
	return this._chatId;
};

ChatroomController.prototype.getState = function () {
	return this._state;
};

/**
 * Initiates the state machine to connect to a twilio chatroom.
 * This involves several user interactions, including modals on the page and potentially browser permission checks.
 * Eventually succeeds or fails with onConnected or onError called.
 *
 * 1. Check for browser permissions and available microphones, cameras.
 * 2. Call the view to let the user select their microphone/camera.
 * 3. Connect to twilio chatroom.
 * 4. Connect the local camera/microphone tracks to the user's pip.
 *
 * @param {any} options {skipPermissionsModal: boolean}
 * skipPermissionsModal default false. If true, the connect must come from an action event (click) so that we do not need to popup the permissions modal.
 * @returns {ChatroomController} this - since this may be a very long running task involving user interaction, no promise is used here.
 */
ChatroomController.prototype.connect = function (options) {
	var me = this;
	options = $.extend({}, { skipPermissionsModal: false }, options);

	if (me._state !== ChatroomControllerStates.DISCONNECTED) {
		console.warn(`[call] Attempt to connect to chatroom while already ${me._state}. Ignored.`);
		return me;
	}

	if (null === me.telemetryClientInstanceId) {
		me.telemetryClientInstanceId = me._getTelemetryInstanceId();
	}
	if (!me.telemetryClientInstanceId) {
		me.onError('no_telemetry');
		return me;
	}

	me._state = ChatroomControllerStates.CONNECTING_CHECKING_PERMISSIONS;
	me._log('call_connecting', `[call] Connecting to chatroom ${me._chatroomId} as ${me.telemetryClientInstanceId}. Getting browser media permissions.`, { userAgent: navigator.userAgent });

	me._utils.getBrowserMediaPermissions().then(function (result) {
		if ((['granted'].includes(result.microphone.state)) && ['granted', 'none'].includes(result.camera.state)) {
			me._getBrowserMediaDevices();
		} else {
			me._state = ChatroomControllerStates.CONNECTING_PERMISSIONS_MODAL;
			me._log('call_connecting:permissions_modal', '[call] Showing permissions modal to user.');
			if (options.skipPermissionsModal) {
				me._afterPermissionsModalDismissed();
			} else {
				me.onShowPermissionsModal(); // Then handlePermissionsModalDismissed() called.
			}
		}
	}, function (error) {
		me._state = ChatroomControllerStates.DISCONNECTED;
		me._error('call_connect:failed:unsupported', `[call] Failed to connect to chatroom. Browser denied access to media devices. ${error.name}: ${error.message}`,
			{ error: error.name });
		me.onError('unsupported');
		me.onDisconnected();
	});

	return me;
};

ChatroomController.prototype._afterPermissionsModalDismissed = function () {
	var me = this;

	if (me._state !== ChatroomControllerStates.CONNECTING_PERMISSIONS_MODAL) {
		return me;
	}

	return me._getBrowserMediaDevices();
};

ChatroomController.prototype.handlePermissionsModalDismissed = function () {
	var me = this;

	me._log('call_connecting:permissions_modal:dismissed', '[call] Permissions modal dismissed.');
	return me._afterPermissionsModalDismissed();
};

ChatroomController.prototype.updateMediaDeviceSelections = function () {
	var me = this;

	me._log('call_updating', `[call] Updating media devices for connected call.`);

	if (platformUtils.isIos()) {
		// IOS disables any devices of the same type as the one requested (audio or video),
		// so it's simpler in this case to just disconnect and reconnect from the call.
		// There doesn't appear to be a way around being effectively disconnected anyways, in the sense that audio/video feeds out stop working anyways.
		me.disconnect();
		me.unmute().enableVideo();
		me.connect({ skipPermissionsModal: true });
	} else {
		me._state = ChatroomControllerStates.UPDATING_MEDIA_DEVICES;
		me._utils.getBrowserMediaDevices().then(function (devices) {
			me._log('call_updating:device_modal', '[call] Showing devices modal.');
			me.onShowDevicesModal(devices, me._defaultTrackOptions); // Then handleDevicesModalConfirm or handleDevicesModalCancel
		});
	}
};

ChatroomController.prototype._getBrowserMediaDevices = function () {
	var me = this;
	me._log('call_connecting:get_media_devices', '[call] Getting browser camera/microphone devices.');
	me._state = ChatroomControllerStates.CONNECTING_BROWSER_PERMISSIONS_CHECK;
	me._utils.getBrowserMediaDevices().then(function (devices) {
		if (me._state !== ChatroomControllerStates.CONNECTING_BROWSER_PERMISSIONS_CHECK) {
			return me;
		}

		me._state = ChatroomControllerStates.CONNECTING_DEVICES_MODAL;
		me._log('call_connecting:device_modal', '[call] Showing devices modal.');
		me.onShowDevicesModal(devices, me._defaultTrackOptions); // Then handleDevicesModalConfirm or handleDevicesModalCancel
	}, function (error) {
		me._state = ChatroomControllerStates.DISCONNECTED;
		me._error('call_connect:failed:devices_denied', `[call] Failed to connect to chatroom. Browser denied access to media devices. ${error.name}: ${error.message}`,
			{ error: error.name });
		me.onError('devices_denied');
		me.onDisconnected();
	});
	return me;
};

ChatroomController.prototype.handleDevicesModalCancel = function () {
	var me = this;
	if (me._state !== ChatroomControllerStates.CONNECTING_DEVICES_MODAL) {
		return;
	}
	me._state = ChatroomControllerStates.DISCONNECTED;
	me._log('call_not_connected:user_cancel', `[call] User decided not to connect to chatroom.`);
	me.onDisconnected();
};


ChatroomController.prototype.handleDevicesModalConfirm = function (mediaDevices) {
	var me = this;

	if (ChatroomControllerStates.UPDATING_MEDIA_DEVICES === me._state) {
		me._updateMediaDevicesOnCurrentCall(mediaDevices);
		me._state = ChatroomControllerStates.CONNECTED;
		return me;
	}

	if (me._state !== ChatroomControllerStates.CONNECTING_DEVICES_MODAL) {
		return me;
	}
	me._connect(mediaDevices);

	return me;
};

ChatroomController.prototype._getTrackOptions = function (mediaDevices) {
	var trackOptions = {};
	mediaDevices.forEach(function (device) {
		if ('audioinput' === device.kind) {
			trackOptions.audio = { deviceId: device.deviceId, name: `microphone:${new Date().getTime()}` };
		} else if ('videoinput' === device.kind) {
			trackOptions.video = { deviceId: device.deviceId, name: `userCamera:${new Date().getTime()}` };
		}
	});
	return trackOptions;
};

ChatroomController.prototype._updateMediaDevicesOnCurrentCall = function (mediaDevices) {
	var me = this;

	me._log('call_updating:new_devices', `[call] Connecting new media devices to call.`);

	var trackOptions = me._getTrackOptions(mediaDevices);

	me._chatroom.localParticipant.tracks.forEach(function (pub) {
		if ('audio' === pub.kind) {
			me._chatroom.localParticipant.unpublishTrack(pub.track);
			pub.track.stop();
		} else if (('video' === pub.kind) && me._isCamera(pub.trackName)) {
			window.mobileWebController.chatroomView._setYouPipVideoTrack(null);
			me._chatroom.localParticipant.unpublishTrack(pub.track);
			pub.track.stop();
		}
	});

	if (trackOptions.audio) {
		twilioVideo.createLocalTracks({ audio: trackOptions.audio }).then(function (tracks) {
			me._chatroom.localParticipant.publishTrack(tracks.find(function (x) { return 'audio' === x.kind; }));
		});;
	}
	if (trackOptions.video) {
		twilioVideo.createLocalTracks({ video: trackOptions.video }).then(function (tracks) {
			const track = tracks.find(function (x) { return 'video' === x.kind; });
			me._chatroom.localParticipant.publishTrack(track);
			window.mobileWebController.chatroomView._setYouPipVideoTrack(track);
		});;
	}

	me._defaultTrackOptions = trackOptions;

	return me;
};

/**
* Actually connect to twilio using the specified local media devices.
* @param {any} mediaDevices enumerable of MediaDeviceInfo (https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo) for mic and/or camera.
*/
ChatroomController.prototype._connect = function (mediaDevices) {
	const me = this;
	const chatroomId = me._chatroomId;
	let twilioAccessToken;
	me._state = ChatroomControllerStates.CONNECTING_TO_ROOM;
	me._defaultTrackOptions = me._getTrackOptions(mediaDevices);

	var shortCircuitError = false;
	// 1. Get access token.
	var promise = me._getAccessToken(me.telemetryClientInstanceId, me._chatId);

	// 2. Create the local audio and video input tracks. Resolves to {localAudioTrack, localVideoTrack}
	promise = promise.then(function (token) {
		twilioAccessToken = token;

		me._log('call_connecting:getting_devices', '[call] Connecting to local camera/microphone.');
		return twilioVideo.createLocalTracks(me._defaultTrackOptions);
	}, function (error) {
		if (!shortCircuitError) { shortCircuitError = true; return Promise.reject(error); }
		me._state = ChatroomControllerStates.DISCONNECTED;
		me._error('call_connect:failed:no_token', `[call] Failed to connect to chatroom ${chatroomId}. Failed to get twilio access token. ${error.name}: ${error.message}`,
			{ error: error.name });
		me.onError('no_token');
		me.onDisconnected();
		return Promise.reject(error);
	});

	// 3. given the local tracks, connect to the room. Resolves to twilio room.
	var lt = { localAudioTrack: null, localVideoTrack: null };
	promise = promise.then(function (localTracks) {
		var tracks = [];
		lt.localAudioTrack = localTracks.find(function (x) { return 'audio' === x.kind; }) || null;
		lt.localVideoTrack = localTracks.find(function (x) { return 'video' === x.kind; }) || null;
		if (lt.localAudioTrack) { tracks.push(lt.localAudioTrack); }
		if (lt.localVideoTrack) { tracks.push(lt.localVideoTrack); }

		me._log('call_connecting:twilio', `[call] Connecting to twilio chatroom ${chatroomId}.`);

		return twilioVideo.connect(twilioAccessToken, {
			name: chatroomId,
			tracks: tracks,
			dominantSpeaker: true,
			type: 'peer-to-peer',
			video: {
				height: 225, // Max pip size.
				frameRate: 24,
				width: 225
			}
		});
	}, function (error) {
		if (!shortCircuitError) { shortCircuitError = true; return Promise.reject(error); }
		me._state = ChatroomControllerStates.DISCONNECTED;
		me._error('call_connect:failed:twilio_tracks', `[call] Failed to connect to chatroom ${chatroomId}. Failed to access media devices. ${error.name}: ${error.message}`,
			{ error: error.name });
		me.onError('twilio_tracks');
		me.onDisconnected();
	});

	// 4. Set up the call state, notify the UI.
	return promise.then(function (room) {
		me._chatroom = room;
		me._state = ChatroomControllerStates.CONNECTED;
		me._log('call_connected', `[call] Connected to chatroom ${chatroomId} as ${me.telemetryClientInstanceId}.`);
		me.onConnected(lt); // Update the view.
		me._setupParticipantAndTrackHandlers();
		me._setSelfMicrophoneEnabled(me.selfMicrophoneEnabled);
		me._setSelfCameraEnabled(me.selfCameraEnabled);
	}, function (error) {
		if (!shortCircuitError) { shortCircuitError = true; return Promise.reject(error); }
		me._state = ChatroomControllerStates.DISCONNECTED;
		me._error('call_connect:failed:twilio_connect', `[call] Failed to connect to chatroom ${chatroomId}. Failed to connect to twilio chatroom. ${error.name}: ${error.message}`,
			{ error: error.name });
		me.onError(('NotReadableError' === error.name) ? 'twilio_connect_nre' : 'twilio_connect');
		me.onDisconnected();
		me._cleanupLocalTracksOnConnectionFailure(lt);
	});
};

ChatroomController.prototype._cleanupLocalTracksOnConnectionFailure = function (tracks) {
	if (tracks.localAudioTrack) {
		tracks.localAudioTrack.stop();
	}
	if (tracks.localVideoTrack) {
		tracks.localVideoTrack.stop();
	}
};

ChatroomController.prototype._setupParticipantAndTrackHandlers = function () {
	var me = this;
	var room = me._chatroom;

	room.on('participantConnected', function (participant) {
		me._setupTrackHandlers(participant);
	});
	room.on('participantReconnecting', function (participant) {
		console.log(`[call] ${me._chatroomId} ${participant.identity} is reconnecting.`, Date.now() * .001);
	});
	room.on('participantReconnected', function (participant) {
		console.log(`[call] ${me._chatroomId} ${participant.identity} reconnected.`, Date.now() * .001);
	});
	room.on('participantDisconnected', function (participant) {
		me._log('caller_disconnected', `[call] ${me._chatroomId} ${participant.identity} disconnected.`);
		me.onShowParticipant(participant.identity, null);
		me.onHearParticipant(participant.identity, null);
	});
	room.on('dominantSpeakerChanged', function (participant) {
		me.onDominantSpeakerChanged(participant ? participant.identity : null);
	});
	room.on('disconnected', function (_room, error) {
		if (!error) {
			me._log('call_disconnected:twilio', '[call] Disconnected from twilio chatroom.');
		} else {
			me._error('call_disconnected:twilio:error', `[call] Disconnected from twilio chatroom. ${error.name}: ${error.message}`, { error: { name: error.name, code: error.code } });
			me.onError('disconnected_error');
		}
		me._disconnect({ fromTwilio: true });
	});
	room.on('reconnecting', function (error) {
		if (!error) {
			me._log('call_reconnecting:twilio', '[call] Reconnecting to twilio chatroom.');
		} else {
			me._error('call_reconnecting:twilio:error', `[call] Reconnecting to twilio chatroom. ${error.name}: ${error.message}`, { error: { name: error.name, code: error.code } });
		}
	});
	room.on('reconnected', function () {
		me._log('call_reconnected:twilio', '[call] Reconnected to twilio chatroom.');
	});
	room.participants.forEach(function (participant) {
		me._setupTrackHandlers(participant);
	});
};

ChatroomController.prototype._setupTrackHandlers = function (participant) {
	var me = this;

	var chatroomId = me._chatroomId;

	participant.on('trackSubscribed', function (track) {
		if (track && ('audio' === track.kind)) {
			console.log(`[call] Receiving audio in ${chatroomId} from ${participant.identity}.`, Date.now() * .001);
			me.onHearParticipant(participant.identity, track);
			track.on('disabled', function () {
				me.onMuted(participant.identity, true);
			});
			track.on('enabled', function () {
				me.onMuted(participant.identity, false);
			});
		} else if (track && ('video' === track.kind) && me._isCamera(track.name)) {
			console.log(`[call] Receiving video in ${chatroomId} from ${participant.identity}.`, Date.now() * .001);
			me.onShowParticipant(participant.identity, track);
			track.on('disabled', function () {
				me.onVideoDisabled(participant.identity, true);
			});
			track.on('enabled', function () {
				me.onVideoDisabled(participant.identity, false);
			});
		} else {
			track.unsubscribe();
		}
	});
	participant.on('trackPublished', function (pub) {
		console.log(`[call] Received published track in ${chatroomId}. ${pub.trackName} from ${participant.identity}.`, Date.now() * .001);
	});
};

ChatroomController.prototype._setSelfMicrophoneEnabled = function (value) {
	var me = this;

	me.selfMicrophoneEnabled = value;

	if (me._chatroom) {
		me._chatroom.localParticipant.audioTracks.forEach(function (pub) {
			if (pub.track) {
				value ? pub.track.enable() : pub.track.disable();
			}
		});
	}

	me.onSelfMicrophoneEnabled(value);

	return me;
};

ChatroomController.prototype.toggleSelfMicrophone = function () {
	return this._setSelfMicrophoneEnabled(!this.selfMicrophoneEnabled);
};

ChatroomController.prototype.mute = function () {
	return this._setSelfMicrophoneEnabled(false);
};

ChatroomController.prototype.unmute = function () {
	return this._setSelfMicrophoneEnabled(true);
};

ChatroomController.prototype.toggleSelfCamera = function () {
	return this._setSelfCameraEnabled(!this.selfCameraEnabled);
};

ChatroomController.prototype._setSelfCameraEnabled = function (value) {
	var me = this;

	me.selfCameraEnabled = value;

	if (me._chatroom) {
		me._chatroom.localParticipant.videoTracks.forEach(function (pub) {
			if (pub.track && me._isCamera(pub.track.name)) {
				value ? pub.track.enable() : pub.track.disable();
			}
		});
	}

	me.onSelfCameraEnabled(value);

	return me;
};

ChatroomController.prototype.disableVideo = function () {
	return this._setSelfCameraEnabled(false);
};

ChatroomController.prototype.enableVideo = function () {
	return this._setSelfCameraEnabled(true);
};

ChatroomController.prototype._tearDown = function (fromTwilio) {
	this._state = ChatroomControllerStates.DISCONNECTED;
	this.onDisconnected(fromTwilio);
	this._log('call_disconnected', `[call] Disconnected from chatroom ${this._chatroomId}.`);
};

ChatroomController.prototype.disconnect = function () {
	if (this._state !== ChatroomControllerStates.CONNECTED) {
		return;
	}
	this._disconnect({initiatedByUser: true});
};

ChatroomController.prototype._disconnect = function (options) {
	options = options || {};
	var eventName = options.initiatedByUser ? 'call_disconnecting:user_initiated' : 'call_disconnecting:automatic';
	this._log(eventName, `[call] Disconnecting from chatroom ${this._chatroomId}.`);

	this._state = ChatroomControllerStates.DISCONNECTED;
	this._chatroom.localParticipant.audioTracks.forEach(function (t) { t.track && t.track.stop(); });
	this._chatroom.localParticipant.videoTracks.forEach(function (t) { t.track && t.track.stop(); });
	if (!options.fromTwilio) {
		this._chatroom.disconnect();
	}
	this._chatroom = null;
	this._tearDown(options.fromTwilio);
};


ChatroomController.prototype.isInRoom = function (clientInstanceId) {
	var me = this;
	if (!me._chatroom) {
		return false;
	}

	if (me._chatroom.localParticipant.identity === clientInstanceId) {
		return true;
	}
	return Array.from(me._chatroom.participants.values()).find(function (p) { return p.identity === clientInstanceId; });
};

ChatroomController.prototype.switchRoom = function (chatId) {
	var me = this;

	var newChatroomId = window.siteSettings.TwilioRoomIdFormat.replace('{0}', chatId);;
	me._log('call:switch_room', `[call] Switching to room to ${newChatroomId}.`);

	if (me._chatroom) {
		me._disconnect({initiatedByUser: true});
	}

	me._chatId = chatId;
	me._chatroomId = newChatroomId;
};

ChatroomController.prototype._isCamera = function (trackName) {
	return trackName && trackName.startsWith('userCamera');
};

/**
 * For the given clientInstanceId of the participant in the current room, return the video track for their camera, if
 * we're subscribed and it's enabled, else null.
 * @param {any} clientInstanceId
 * @returns {RemoteVideoTrack} track or null if there aren't any camera tracks matching the requirements.
 */
ChatroomController.prototype.getCameraTrackForParticipant = function (clientInstanceId) {
	var me = this;
	var track = null;
	if (me._chatroom) {
		me._chatroom.participants.forEach(function (part) {
			if (part.identity !== clientInstanceId) {
				return true;
			}
			part.videoTracks.forEach(function (pub) {
				if (pub && pub.track && me._isCamera(pub.trackName) && pub.isTrackEnabled && pub.isSubscribed) {
					track = pub.track;
					return false;
				}
				return true;
			});
			return false;
		});
	}
	return track;
};

/**
 * This event handler is called (likely multiple times) when audio and video input devices are attached/detached from the system.
 * A single device can generate multiple events immediately, so throttle any UI update that occurs.
 *
 * @param {Event} _evt
 */
ChatroomController.prototype._onMediaDevicesChange = function (_evt) {
	var me = this;

	if (!me._mediaDeviceChangeTimer) {
		me._mediaDeviceChangeTimer = setTimeout(function () {
			me._mediaDeviceChangeTimer = null;
			me._updateMediaDeviceLists();
		}, 300);
	}

	return true;
};

ChatroomController.prototype._updateMediaDeviceLists = function () {
	var me = this;
	me._utils.getBrowserMediaDevices().then(function (devices) {
		me._log('media_devices:change', '[call] Media devices have changed.');
		me.onDevicesChange(devices, me._defaultTrackOptions);
	});
	return me;
};

export { ChatroomController, ChatroomControllerStates }
