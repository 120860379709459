import { IrUtils } from "./utils.es13"

/**
 * Easing algorithms
 * All of the form fn(t) where t is a value from 0 to 1 indicating the
 * normalized point in time for which we're returning an eased value.
*/

// no easing, no acceleration
function linear(t) { return t };
// accelerating from zero velocity
function easeInQuad(t) { return t * t };
// decelerating to zero velocity
function easeOutQuad(t) { return t * (2 - t) };
// acceleration until halfway, then deceleration
function easeInOutQuad(t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t };
// accelerating from zero velocity 
function easeInCubic(t) { return t * t * t };
// decelerating to zero velocity 
function easeOutCubic(t) { return (--t) * t * t + 1 };
// acceleration until halfway, then deceleration 
function easeInOutCubic(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
// accelerating from zero velocity 
function easeInQuart(t) { return t * t * t * t };
// decelerating to zero velocity 
function easeOutQuart(t) { return 1 - (--t) * t * t * t };
// acceleration until halfway, then deceleration
function easeInOutQuart(t) { return t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t };
// accelerating from zero velocity
function easeInQuint(t) { return t * t * t * t * t };
// decelerating to zero velocity
function easeOutQuint(t) { return 1 + (--t) * t * t * t * t };
// acceleration until halfway, then deceleration 
function easeInOutQuint(t) { return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t };


/*
 * Animation Methods
*/

/**
 * General purpose function to return an eased value given a a 0-1 progress time, a start value,
 * and an end value, with an optional algorithm to use.
 * @param {Number} t normalized time value in the range 0 to 1
 * @param {Number} start the output value that should be true at time = 0
 * @param {Number} target the output value that should be true at time = 1
 * @param {Function} [method=Easing.algs.linear] a reference to the easing algorithm function above (or custom) to use
 */
function ease(t, start, target, method) {
	method = method || Easing.algs.linear;

	// eliminate rounding problems at the edges
	if (t === null || !IrUtils.isNumber(t) || !IrUtils.isNumber(start) || !IrUtils.isNumber(target)) {
		return null;
	} else if (t < 0) {
		return start;
	}
	else if (t >= 1.0) {
		return target;
	}

	// return an eased value
	const dt = method(t);
	return (dt * (target - start)) + start;
};


/**
 * Perform a 1-dimensional Bézier easing with 1 or more control points
 * @param {Number} t normalized time value in the range 0 to 1
 * @param {Array.<Number>} pts
 * @param {Function} [method=Easing.algs.linear] a reference to the easing algorithm function above (or custom) to use
 */
function bezierEase1D(t, pts, method) {
	method = method || Easing.algs.linear;

	// Check input
	const allPtsAreNumbers = pts.reduce((acc, cur) => { return acc && IrUtils.isNumber(cur); }, true);
	if (!IrUtils.isNumber(t) || pts.length == 0 || !allPtsAreNumbers || typeof(method) !== 'function') {
		return null;
	}

	let dt = method(t);
	// eliminate rounding problems at the edges
	dt = (dt < 0) ? 0 : dt;
	dt = (dt > 1) ? 1 : dt;


	if (pts.length > 1) {
		const nPts = [];
		for (let i = 1; i < pts.length; i++) {
			const start = pts[i - 1];
			const end = pts[i];
			const nPtsVal = (dt * (end - start)) + start;
			nPts.push(nPtsVal);
		}

		return this.bezierEase1D(t, nPts, method);
	} else {
		return pts[0];
	}
};

const Easing = {
	bezierEase1D: bezierEase1D,
	ease: ease,
	algs: {
		easeInCubic: easeInCubic,
		easeInOutCubic: easeInOutCubic,
		easeOutCubic: easeOutCubic,
		easeInQuad: easeInQuad,
		easeInOutQuad: easeInOutQuad,
		easeOutQuad: easeOutQuad,
		easeInQuart: easeInQuart,
		easeInOutQuart: easeInOutQuart,
		easeOutQuart: easeOutQuart,
		easeInQuint: easeInQuint,
		easeInOutQuint: easeInOutQuint,
		easeOutQuint: easeOutQuint,
		linear: linear
	}
};

export { Easing };
