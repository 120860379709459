import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { getStreetView } from "../utils/map-utils.es6"
import { setView } from "../telemetry/room"

export const MapSearch = ({ onMapDblClick }) => {
	const mainContainerRef = useRef();
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [isMapInitialized, setIsMapInitialized] = useState(false);

	const initializeMap = () => {
  		const mapsViewer = EveryScape.SyncV2.GMap({ container: mainContainerRef.current, tileset: 'roadmap' });
		mapsViewer.events.on('dblclick', (args) => {
			getStreetView(args).then((view) => {
				setView(view);
				onMapDblClick?.();
			});
		});
		window.bottomDrawerMapsViewer = mapsViewer;
		window.bottomDrawerView.addBuilingMapMarkers();
		window.bottomDrawerView.onMapSearch = search;

		// Default to centering on selected building if no initial location
		const selectedBuilding = window.mobileWebController?.contentModel.getSelectedBuilding();
		window.bottomDrawerView.centerMapOnBuilding(selectedBuilding);

		setIsMapInitialized(true);
	}

	const updatePlaceMarkerIcon = (place, icon, zIndex) => {
		const marker = window.bottomDrawerMapsViewer.getMarkerByLabel(place.place_id);
		marker.setIcon(icon);
		marker.setZIndex(zIndex);
	}

	const onPlaceSelect = place => {
		const latLng = place.geometry?.location;
		window.bottomDrawerMapsViewer.centerOnPosition(latLng);
		getStreetView(latLng).then(setView);

		if (place.place_id !== selectedPlace?.place_id) {
			selectedPlace && updatePlaceMarkerIcon(selectedPlace, "/images/noun-map-marker.svg", 0);
			setSelectedPlace(place);
		}
	}

	useEffect(() => {
		if (selectedPlace) {
			updatePlaceMarkerIcon(selectedPlace, "/images/noun-map-marker-selected.svg", 1);
			$(`[data-map-search-placeid=${selectedPlace.place_id}]`)[0]?.scrollIntoView({
				behavior: "smooth"
			});
		}
	}, [selectedPlace]);

	const search = query => {
		setSearchQuery(query);
		window.bottomDrawerMapsViewer.clearMarkers();
		window.bottomDrawerView.addBuilingMapMarkers();
		setSearchResults([]);
		const selectedBuilding = window.mobileWebController?.contentModel.getSelectedBuilding();
		const location = selectedBuilding?.location?.calculated;

		if (query) {
			window.mobileWebController.searchMap(query, location)
				.then(results => {
					if (results?.length) {
						results.map(r => {
							window.bottomDrawerMapsViewer.addMarker(
								r.name, r.place_id, r.geometry.location, () => onPlaceSelect(r), true, false
							);
						});
						window.bottomDrawerMapsViewer.fitMarkersBounds();
						setSearchResults(results);
						onPlaceSelect(results[0]);
					}
				});
		} else {
			// Default to centering on selected building if there's no search
			window.bottomDrawerView.centerMapOnBuilding(selectedBuilding);
		}
	}

	useLayoutEffect(() => {
		if (mainContainerRef.current) {
			initializeMap();
		}
	}, []);

	const handleInputChange = event => search(event.target.value);

  	return (
		<div className="relative flex flex-col h-full">
			<span id="MapSearchTerm" className="p-2 pr-7">
				{searchQuery==="my location" ? "Map view of current location" : `Showing results for '${searchQuery}'`}
			</span>
			<div className="relative flex-shrink-0">
				<div ref={mainContainerRef} id="MapContainer" className="flex-shrink-0 w-full p-2 bg-black h-[25vh] rounded-2xl" />
				<input
					type="text"
					className="
						absolute top-[10px] right-[10px] p-2
						text-black transition-all duration-300 ease-in-out
						bg-white border border-transparent rounded-sm shadow-sm
						focus:outline-none focus:border-blue-500 focus:left-[10px] focus:right-[10px] focus:w-auto"
					placeholder="Search"
					style={{ width: '70px' }}
					value={searchQuery}
					onChange={handleInputChange}
				/>
			</div>
			<PlaceList places={searchResults} onPlaceSelect={onPlaceSelect} />
	  	</div>
	);
};

const Place = ({ place, onSelect }) => {
	const photoUrl = place.photos?.length ?
		place.photos[0].getUrl() :
		place.icon;

	return <div className="flex flex-row justify-start mb-2 overflow-hidden bg-brandMenu rounded-2xl"
		onClick={() => onSelect(place)}
		data-map-search-placeid={place.place_id}>
		<div className="w-[70px] h-[70px] flex-shrink-0"
			style={{ backgroundImage: `url(${photoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
		</div>
		<div className="flex flex-col justify-start pl-1">
			<span className="p-1">{place.name}</span>
			<span className="p-1 text-sm">{place.vicinity}</span>
		</div>
	</div>
};

const PlaceList = ({ places, onPlaceSelect }) => {
	return <div id="MapSearchPlaceList" className="mt-2 flex-grow-1">
		{places.map((place, i) => <Place key={i} place={place} onSelect={onPlaceSelect} />)}
	</div>
};
