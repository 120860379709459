import { addCommasToNumber, formatFloor, formatDate } from "../utils/ui-helpers.es6.js"
import { IrUtils } from "../utils/utils.es13.js"
import { UNIT_TYPES } from "../models/content-model.es6.js"

const CLICK = 'click';
const SELECTED = 'selected';

const FEATURES_LIST = [
	{ attr: 'Pets', label: 'Pets Allowed' },
	{ attr: 'Specials', label: 'Specials' },
	{ attr: 'Details', label: 'Details' },
	{ attr: 'Deposit', label: 'Deposit' },
	{ attr: 'Utilities', label: 'Utilities' }
];
const FEATURES_UNIT_TYPES_LIST = ['amenity', 'sublease', 'marketable', 'unknown'];

class BottomDrawerTab {
	constructor() {
		this.tabs = [];
		this.selectedTab = null;
		for (const inTab of arguments) {
			const tab = {
				$button: $(inTab.button),
				$content: $(inTab.content),
				enabled: inTab.enabled, // Function call to check enabled status
				_enabled: false, // Current enabled state
				onSelected: inTab.onSelected,
				precedence: inTab.precedence || 0
			};
			this.tabs.push(tab);
			tab.$button.unbind(CLICK).on(CLICK, (_evt) => { this._setSelectedTab(tab); });
		}
		this.update(true);
		this._setSelectedTab();
	}

	/**
	 * Update which tabs are enabled or not and also the selected tab.
	 */
	update(ignoreAutoselection) {
		let bestEnabledTab = null;
		for (const tab of this.tabs) {
			tab._enabled = tab.enabled();
			if (tab._enabled && (!bestEnabledTab || (tab.precedence > bestEnabledTab.precedence))) { bestEnabledTab = tab; }
			if ((this.selectedTab === tab) && !tab._enabled) { this.selectedTab = null; }

			// Hide content section for disabled tabs.
			if (!tab._enabled) { tab.$content.hide(); }

			tab.$button.toggle(tab._enabled);
		}
		if (!ignoreAutoselection && !this.selectedTab) {
			this._setSelectedTab(bestEnabledTab);
		}
		return this;
	}

	_setSelectedTab(selectedTab) {
		this.selectedTab = selectedTab;
		for (const tab of this.tabs) {
			const selected = (tab === selectedTab);
			tab.$button.toggleClass(SELECTED, selected);
			tab.$content.toggle(selected);
			if (selected && tab.onSelected) { tab.onSelected(); }
		}
		return this;
	}
}

/**
 * Return a div containing a listing's features.
 * @param {object} building
 * @param {Set[number]} uniqueListingIds
 * @returns {HTMLElement[]}
 */
function buildFeatures(building, uniqueListingIds) {
	if (!building) { return; }
	const result = $('<div>');
	FEATURES_UNIT_TYPES_LIST.forEach((unitType) => {
		const propertyUnits = building.UnitTypes[unitType];
		if (!propertyUnits) { return; }
		propertyUnits.forEach((propertyUnit) => {
			propertyUnit.Listings?.forEach((listing) => {
				if (IrUtils.isNully(listing.Id) || uniqueListingIds.has(listing.Id)) { return; }
				uniqueListingIds.add(listing.Id);
				const $spaceFeatures = $('<div>').addClass('spaceFeatures').data('listingId', listing.Id)
					.attr('listingId', listing.Id);
				FEATURES_LIST.forEach((feature) => {
					const val = listing[feature.attr];
					if (val) {
						$spaceFeatures.append(
							$('<div>').addClass('featureDiv')
								.append($('<span>').addClass('featureLabel').text(feature.label + ':'))
								.append($('<span>').addClass('featureValue').text(val)));
					}
				});
				if ($spaceFeatures.children().length > 0) {
					result.append($spaceFeatures);
				}
			});
		});
	});
	return result.children();
}

function buildMobileListing(listing, projectType) {
	let newCard = $($('#MultifamilyListingCard')[0].innerHTML).clone();
	populateSpaceInfo(listing, newCard, projectType);
	newCard.data('listingId', listing.Id);
	return newCard[0];
}

// { propertyName [string], textName [optional string], formatterFunction [optional string=>string]}
/**
 * Creates an object that describes how to translate building properties to HTML in the infoSection.
 * @param {string} property Name of the property/member of a building object
 * @param {string=} label Text label used, defaults to propertyName
 * @param {object=} options
 * @param {function(string)=>string} options.format Optional function to format the building property to text.
 * @param {function(string)=>boolean} options.shouldDisplay Test to see if the value should be displayed
 * @returns {object} {property:, label:, format:, shouldDisplay:}
 */
function createBuildingInfoAttribute(property, label, options) {
	return { property: property, label: label || property, format: options?.format, shouldDisplay: options?.shouldDisplay }
}
const firstBuildingAttributes = [
	createBuildingInfoAttribute('Landlord'),
	createBuildingInfoAttribute('FloorCount', 'Floors', { shouldDisplay: (val) => !!val }),
	createBuildingInfoAttribute('SquareFeet', 'Size SQ FT', { format: addCommasToNumber, shouldDisplay: (val) => !!val }),
	createBuildingInfoAttribute('Parking'),
	createBuildingInfoAttribute('BuiltYear', 'Built'),
	createBuildingInfoAttribute('RenovatedYear', 'Renovated')
];
const rentalBuildingAttributes = [
	createBuildingInfoAttribute('Phone'),
	createBuildingInfoAttribute('OnSiteManagement', 'On Site Property Management'),
	createBuildingInfoAttribute('Elevator'),
	createBuildingInfoAttribute('Recycling'),
	createBuildingInfoAttribute('AreaAmenities', 'Area Amenities'),
	createBuildingInfoAttribute('IncludedParking', 'Included Parking'),
	createBuildingInfoAttribute('OfficeHours', 'Office Hours'),
	createBuildingInfoAttribute('PublicTransitAccess', 'Access To Public Transportation'),
	createBuildingInfoAttribute('Concierge'),
	createBuildingInfoAttribute('TrashRemoval', 'Trash Removal'),
	createBuildingInfoAttribute('OnSiteMaintenance', 'On SiteMaintenance')
];
function appendRowToBuildingInfoSection(building, descriptor, $buildingDataSection) {
	let val = building[descriptor.property];
	const shouldDisplay = descriptor.shouldDisplay ? descriptor.shouldDisplay(val) : !IrUtils.isNullyOrEmptyString(val);
	if (shouldDisplay) {
		const $label = $('<div>').addClass('infoLabel secondaryColor').text(descriptor.label);
		if (descriptor.format) {
			val = descriptor.format(val);
		}
		const $val = $('<div>').addClass('infoValue').text(val);
		const $row = $('<div>').addClass('infoRow').append($label).append($val);
		$buildingDataSection.append($row);
	}
}
/**
 * Create and return an infoSection div for a building, or null if there isn't anything to display.
 * @param {object} building
 * @returns {HTMLElement=} Null if there's no info to format.
 */
function buildBuildingInfoSection(building) {
	if (!building) { return null; }

	const $buildingDataSection = $('<div>').addClass('buildingDataSection buildingContent');

	firstBuildingAttributes.forEach((descriptor) => {
		appendRowToBuildingInfoSection(building, descriptor, $buildingDataSection);
	});

	if ('RENT' === building.PropertyTypeCode) {
		rentalBuildingAttributes.forEach((descriptor) => {
			appendRowToBuildingInfoSection(building, descriptor, $buildingDataSection);
		});
	}

	if (!IrUtils.isNullyOrEmptyString(building.Notes)) {
		const $notes = $('<p>').addClass('aboutBuilding').text(building.Notes);
		$buildingDataSection.append($notes);
	}

	return (0 === $buildingDataSection.children().length) ? null :
		$('<div>').addClass('infoSection brandMenuColors').append($buildingDataSection);
}

function formatBuildingAddress(building) {
	/* We often display building addresses on two lines,
	 * or sometimes with a name
	 * This is to format them nicely.
	*/

	let streetAddress = building.Address1 ? building.Address1 : "";
	let city = building.Locality ? building.Locality : "";
	let state = building.Region ? building.Region : "";

	let firstLine = "";
	let secondLine = "";

	if (!!building.Name) {
		firstLine = building.Name;
		secondLine = streetAddress + ', ' + city + ', ' + state;
	} else {
		//if the building doesn't have a name, we put the street address on the first line instead
		firstLine = streetAddress;
		secondLine = city + ', ' + state;
	}
	return {
		firstLine: firstLine,
		secondLine: secondLine
	};
}

/**
 * Create a tab group. Tab buttons are toggled with a 'selected' CSS class added to them.
 * Content is toggled on/off in various ways.
 * @param {*} buttonsParent Simply attached to the tabGroup if needed.
 * @param {TabInfo[]} tabList Array of {button, toggle}. button is a string or jQuery selected for the tab button.
 * toggle is either a function(boolean) to be called to toggle on/off the tab's content, or a string or jQuery selector
 * to be toggled on/off when a tab in the group is selected.
 * @param {number=} initialTabIndex Initial tab selected in the list (by array index).
 */
function initializeBottomDrawerTabComplex(buttonsParent, tabList, initialTabIndex) {
	const tabGroup = {
		buttonsParent: buttonsParent,
		tabs: []
	};
	let initialTab = null;
	tabList.forEach((t, idx) => {
		const toggleFunction = ('function' === typeof (t.toggle)) ? t.toggle : (on) => { $(t.toggle).toggle(on); };
		const tab = { $button: $(t.button), toggle: toggleFunction };
		tabGroup.tabs.push(tab);
		tab.select = (showGrid) => {
			tabGroup.tabs.forEach((tt) => {
				if (tt !== tab) {
					tt.$button.removeClass(SELECTED);
					tt.toggle(false);
				}
			});
			tab.$button.addClass(SELECTED);
			tab.toggle(true, showGrid);
		};
		tab.$button.unbind(CLICK).on(CLICK, (_) => {
			tab.select(true);
		});

		if (idx === initialTabIndex) {
			initialTab = tab;
		} else {
			// Start in off position.
			tab.$button.removeClass(SELECTED);
			tab.toggle(false); 
		}
	});
	if (initialTab) {
		initialTab.$button.addClass(SELECTED);
		initialTab.toggle(true);
	}
	tabGroup.rerender = function () {
		tabGroup.tabs.forEach((tt) => {
			tt.toggle(tt.$button.hasClass(SELECTED));
		});
	};
	return tabGroup;
}

function makeListCell(contentItem, onclick) {
	const itemCell = document.createElement('div');
	itemCell.ContentReferenceId = contentItem.contentReferenceId;
	itemCell.ContentReferenceType = contentItem.contentReferenceType;
	itemCell.classList.add('item-cell', 'oneLineText', 'brandContentColors');
	itemCell.innerText = contentItem.name;

	$(itemCell).unbind('click').click(() => { onclick(contentItem); });
	return itemCell;
}

function populateAdditionalMCInfo(listing) {
	//MC classes
	let $AdditionalMCInfo = $("#AdditionalMCInfo");
	let $termHolder = $AdditionalMCInfo.find(".termHolder");
	let $term = $AdditionalMCInfo.find(".term");
	let $leaseTypeHolder = $AdditionalMCInfo.find(".leaseTypeHolder");
	let $leaseType = $AdditionalMCInfo.find(".leaseType");
	let $leaseEndDateHolder = $AdditionalMCInfo.find(".leaseEndDateHolder");
	let $leaseEndDate = $AdditionalMCInfo.find(".leaseEndDate");
	let $TIAHolder = $AdditionalMCInfo.find(".TIAHolder");
	let $TIA = $AdditionalMCInfo.find(".TIA");
	let $conditionHolder = $AdditionalMCInfo.find(".conditionHolder");
	let $condition = $AdditionalMCInfo.find(".condition");
	//show $AdditionalMCInfo if any info in it has a value, otherwise hide it.
	$AdditionalMCInfo.toggleClass('hidden',
		!(
			!!listing.TermLength ||
			!!listing.LeaseExpirationDate ||
			!!listing.LeaseType ||
			!IrUtils.isNullyOrEmptyString(listing.QuotedTIA) ||
			!!listing.Condition
		)
	);

	$termHolder.hide();
	$leaseEndDateHolder.hide();
	$leaseTypeHolder.hide();
	$TIAHolder.hide();
	$conditionHolder.hide();

	$term.text("");
	$leaseEndDate.text("");
	$leaseType.text("");
	$TIA.text("");
	$condition.text("");

	//the following are all located within $AdditionalMCInfo
	//term
	if (!!listing.TermLength) {
		$term.text(listing.TermLength);
		$termHolder.show();
	}
	//lease end date
	if (!!listing.LeaseExpirationDate) {
		$leaseEndDate.text(formatDate(listing.LeaseExpirationDate));
		$leaseEndDateHolder.show();
	}
	//lease type
	if (listing.LeaseType) {
		$leaseType.text(listing.LeaseType);
		$leaseTypeHolder.show();
	}

	//quoted tia
	if (!IrUtils.isNullyOrEmptyString(listing.QuotedTIA)) {
		$TIA.text(listing.QuotedTIA);
		$TIAHolder.show();
	} 

	//condition of existing space
	if (listing.Condition) {
		$condition.text(listing.Condition);
		$conditionHolder.show();
	}
	return this;
}

function populateUDGInfo(listing) {
	$("#UDGItemsTitle").text(listing.Name);
	return this;
}

function getListingFormattedAttributes(listing, projectType) {
	const dynamicListingData = window.contentModel.getDynamicListingData(listing);
	let rentMin = listing.RentMin;
	let rentMax = listing.RentMax;
	let price = listing.Price;
	if (dynamicListingData) {
		rentMin = rentMax = 0;
		dynamicListingData.units.forEach((u) => {
			if (!rentMin || (u.Price < rentMin)) { rentMin = u.Price; }
			if (u.Price > rentMax) { rentMax = u.Price; }
		});
		if (rentMin === rentMax) {
			price = rentMin;
		}
	}

	if (listing?.PropertyUnitType?.toLowerCase() != UNIT_TYPES.MARKETABLE.toLowerCase()) {
		//amenity or unknown
		return { name: listing.Name };
	} else {
		const attributes = {
			available: null,
			baths: null,
			beds: null,
			dynamicListingData: dynamicListingData,
			externalUrl: null,
			name: null,
			price: null,
			squareFeet: null,
		};

		switch (projectType) {
			case "Marketing Campaign":
				if (!!listing.FloorName) {
					attributes.name = formatFloor(listing.FloorName);
					if (!!listing.SuiteName) {
						attributes.name += (", " + listing.SuiteName);
					}
				} else if (!!listing.SuiteName) {
					attributes.name = listing.SuiteName;
				}

				//price (per SF)
				if (!!listing.Price) {
					attributes.price = `$${listing.Price} / SF`;
				}

				break;
			case "Multi Family":
			default:
				if (!!listing.FloorplanName) {
					attributes.name = listing.FloorplanName;
					if (!!listing.Unit) {
						attributes.name += (', ' + listing.Unit);
					}
				} else if (!!listing.Unit) {
					attributes.name = listing.Unit;
				}

				if (price) {
					attributes.price = `$${price}`;
				} else if (rentMin || rentMax) {
					let formattedRent = "";
					if (rentMin) {
						formattedRent = `$${rentMin}`;
						if (rentMax) {
							formattedRent += ` - $${rentMax}`;
						}
					} else {
						formattedRent = `$${rentMax}`;
					}
					attributes.price = formattedRent;
				}

				if (listing.Bedrooms) { attributes.beds = Math.round(+listing.Bedrooms); }
				if (listing.Bathrooms) { attributes.baths = +listing.Bathrooms; }
				break;
		}

		if (!!listing.SquareFeet) {
			attributes.squareFeet = addCommasToNumber(listing.SquareFeet, false) + ' SF';
		}

		if (dynamicListingData) {
			if (dynamicListingData.units.length) {
				attributes.available = `${dynamicListingData.units.length} Unit${dynamicListingData.units.length > 1 ? 's' : ''} Available`;
			}
		} else if (listing.AvailableDate) {
			const listingAvailableDate = new Date(listing.AvailableDate);
			if (listingAvailableDate <= Date.now()) {
				if (listing.AvailableUnitCount) {
					attributes.available = `${listing.AvailableUnitCount} Unit${listing.AvailableUnitCount > 1 ? 's' : ''} Available`;
				} else {
					attributes.available = 'Immediately Available';
				}
			} else {
				attributes.available = `Available ${listingAvailableDate.toLocaleDateString()}`;
			}
		}
		return attributes;
	}
}

function populateSpaceInfo(listing, card, projectType) {
	const $cardName = card.find('.listingName');
	const $cardRent = card.find('.rent');
	const $cardSF = card.find('.squareFeet');
	const $cardBeds = card.find('.beds');
	const $cardBaths = card.find('.baths');
	const $cardAvailable = card.find(".available");
	const $cardExternalUrl = card.find(".externalUrl");


	let hideEverything = function () {
		$cardName.hide();
		$cardRent.hide();
		$cardSF.hide();
		$cardBeds.hide();
		$cardBaths.hide();
		$cardAvailable.hide();
		$cardExternalUrl.hide();
	};
	hideEverything();

	const attributes = getListingFormattedAttributes(listing, projectType);

	if (attributes.name) {
		$cardName.text(attributes.name).show();
	}

	if (attributes.price) {
		$cardRent.text(attributes.price).show();
	}

	$cardBeds.text(attributes.beds ? `Beds ${listing.Bedrooms}` : '').show();
	$cardBaths.text(attributes.baths ? `Baths ${listing.Bathrooms}` : '').show();

	if (attributes.squareFeet) {
		$cardSF.text(attributes.squareFeet).show();
	}

	if (attributes.available) {
		$cardAvailable.text(attributes.available).show();
	}

	if (attributes.dynamicListingData) {
		if (attributes.dynamicListingData.units?.length) {
			$cardExternalUrl.show().unbind('click').click((evt) => {
				evt.stopPropagation();
				window.mobileWebController.recordCallToAction('CheckAvailability');
				window.bottomDrawerView.onCheckAvailabilityClick(attributes.dynamicListingData, listing);
				return false;
			});
		}
	} else if (listing.ExternalUrl) {
		$cardExternalUrl.show().unbind('click').click((evt) => {
			evt.stopPropagation();
			window.mobileWebController.recordCallToAction('LeaseNow');
			window.open(listing.ExternalUrl, '_leaseNow');
			return false;
		});
	}

	return card;
}

export { BottomDrawerTab, buildBuildingInfoSection, buildFeatures, buildMobileListing, formatBuildingAddress, initializeBottomDrawerTabComplex, makeListCell, populateUDGInfo, getListingFormattedAttributes }
