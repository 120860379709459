import { TranslatedMessageParagraph } from './TranslatedMessageParagraph.mjs';
import { IrUtils } from '../../utils/utils.es13.js';

class TranslatedMessage {
	constructor({ isAnswer, paragraphs, suggestions, _id, questionId, problem } = {}) {
		this._id = _id;
		this.isAnswer = !!isAnswer;
		this.problem = problem;
		if (this.isAnswer) {
			this.questionId = questionId;
		}
		this.paragraphs = paragraphs || [];
		this.selectedFeatureIndex = -1; // For the tour cycling through the features in an AI response.

		this.featureIds = this.paragraphs.map(p => p.featureIds).flat();
		// Feature IDs that aren't consecutively duplicated. Affects "next" functionality.
		this.nextableFeatureIds = this.featureIds.filter((val, i, array) => {
			return (0 == i) || (val != array[i - 1]);
		});
		this.suggestions = suggestions || []; // Text suggestions/prompts.
	}

	get currentFeatureId() {
		return this.featureIds[this.selectedFeatureIndex];
	}

	get isQuestion() {
		return !this.isAnswer;
	}

	/**
	 * Get the paragraph of the currently selected feature.
	 * @returns {TranslatedMessageParagraph}
	 */
	get selectedFeatureParagraph() {
		let pi = 0;
		for (let s = this.selectedFeatureIndex; (s > 0) && (pi < this.paragraphs.length); ++pi) {
			s -= this.paragraphs[pi].featureIds.length;
		}
		return this.paragraphs[pi];
	}

	/**
	 * Where the answer is just a text string, not HTML from the AI. Newlines translated to breaks.
	 * Example is case of the intro message.
	 * @param {string} message
	 * @returns
	 */
	static fromStringAnswer(message, suggestions) {
		return new TranslatedMessage({
			isAnswer: true,
			paragraphs: [new TranslatedMessageParagraph({ html: IrUtils.sanitizeHtmlEntities(message).replaceAll(/\r?\n/g, '<br/>') })],
			suggestions: suggestions
		});
	}

	static fromQuestion(message, extraProps) {
		return new TranslatedMessage({
			isAnswer: false,
			...extraProps,
			paragraphs: [new TranslatedMessageParagraph({ html: IrUtils.sanitizeHtmlEntities(message) })]
		});
	}
}

export { TranslatedMessage }
