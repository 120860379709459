/**
 * The participant's state regarding telemetry and voice/video call connection.
 */
class ParticipantState {
	constructor() {
		/** @member {boolean} */
		this.telemetryConnected = false;
		/** @member {boolean} */
		this.callConnected = false;
		/** @member {boolean} */
		this.audioBroadcasting = false;
		/** @member {boolean} */
		this.audioMuted = false;
		/** @member {boolean} */
		this.videoBroadcasting = false;
		/** @member {boolean} */
		this.videoMuted = false;
		/** @member {boolean} */
		this.sharingScreen = false;
	}

	/**
	 * Compare to participant states.
	 * @param {ParticipantState} participant
	 * @return {boolean}
	 */
	equals(participant) {
		return (participant.toCompressedString() == this.toCompressedString());
	}

	/**
	 * Create a ParticipantState from a compressed representation (1's and 0's).
	 * @param {string} participantState
	 * @return {ParticipantState}
	 */
	static fromCompressedString(participantState) {
		const result = new ParticipantState();
		if ('string' !== typeof (participantState)) {
			return result;
		}
		const parts = participantState.split('');
		result.telemetryConnected = '1' === parts[0];
		result.callConnected = '1' === parts[1];
		result.audioBroadcasting = '1' === parts[2];
		result.audioMuted = '1' === parts[3];
		result.videoBroadcasting = '1' === parts[4];
		result.videoMuted = '1' === parts[5];
		result.sharingScreen = '1' === parts[6];
		return result;
	}

	/**
	 * Create a compressed representation of the participant state.
	 * @return {string}
	 */
	toCompressedString() {
		return [
			oneOrZero(this.telemetryConnected),
			oneOrZero(this.callConnected),
			oneOrZero(this.audioBroadcasting),
			oneOrZero(this.audioMuted),
			oneOrZero(this.videoBroadcasting),
			oneOrZero(this.videoMuted),
			oneOrZero(this.sharingScreen)
		].join('');
	}
}

function oneOrZero(b) {
	return !b ? '0' : '1';
}

export { ParticipantState }
