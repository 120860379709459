import React, { createContext, useState, useCallback } from "react";
import { AiApi } from "../api/ai-service-api.es13";

const ai = new AiApi();

export const ChatContext = createContext({
  debug: false,
});

export const ChatProvider = ({
  children,
}) => {
  const [debug, setDebug] = useState(false);
  const [submitChatMessage, setSubmitChatMessageFn] = useState(() => {});
  const recordProblem = async (message, problem) => {
	const response = await ai.recordProblem(message._id, problem);
	if (response.result) message.problem = problem;
  }

  return (
    <ChatContext.Provider
      value={{ debug, setDebug, recordProblem, submitChatMessage, setSubmitChatMessageFn }}
    >
      {children}
    </ChatContext.Provider>
  );
};
