class TranslatedMessageParagraph {
	constructor({ featureIds, html, isHidden } = {}) {
		// During typing-effect, how much is exposed so far.
		this.featureIds = featureIds || [];
		this.html = html;
		this.isHidden = !!isHidden;
	}
}

export { TranslatedMessageParagraph }
