import { Easing } from "./animation-easing.es6"
import { IrUtils } from "./utils.es13"

class ArrayStreamTween {
	/**
	 * A tween of an array of values that is described by one or more steps whose target can be continuously modified.
	 * @param {Array} startArray An array of numeric values that define the start position of the animation
	 * @param {Array} targetArray An array of numeric values that define the initial target of the animation
	 * @param {Number} [durationMs=500] The initial number of milliseconds that the animation will run (optional)
	 * @param {Function} [easingFunction=Easing.algs.easeInOutQuad] An easing function to use on each column (optional)
	 * @param {Number} [startTimeMs=Date.now()] The value to use as the start time (optional)
	 * @param {Map.TweenValueBoundary>} boundaryMap A map of boundary objects where the key corresponds to the column index in the values arrays
	 * @returns {ArrayStreamTween}
	 * */
	constructor(startArray, targetArray, durationMs, easingFunction, startTimeMs, boundaryMap) {
		this.startTimeMs = isNaN(startTimeMs) ? Date.now() : startTimeMs;
		durationMs = durationMs || 500;
		this.complete = false;
		this._boundaryMap = boundaryMap;
		this._ease = easingFunction || Easing.algs.easeInOutQuad;
		this._steps = [
			new ArrayTween(startArray, targetArray, this.startTimeMs, this.startTimeMs + durationMs, this._ease, this._boundaryMap)
		];
	}

	/**
	 * Smoothly change the destination and finish time of the current animation.
	 * @param {Array} newTargetArray the array of numerical values that define the new destination
	 * @param {Number} durationMs the duration to extend the animation to reach the new destination
	 * @param {Number} [currentTimeMs=Date.now()] the value to use as the current time (optional)
	 * @returns {ArrayStreamTween} The current animation object
	 * */
	retarget(newTargetArray, durationMs, currentTimeMs) {
		currentTimeMs = isNaN(currentTimeMs) ? Date.now() : currentTimeMs;
		const targetTimeMs = currentTimeMs + durationMs;
		const startArray = this.getValues(currentTimeMs);
		const newStep = new ArrayTween(startArray, newTargetArray, currentTimeMs, targetTimeMs, this._ease, this._boundaryMap);
		if (this.complete) {
			this._steps = [newStep];
			this.complete = false;
		} else {
			this._steps.push(newStep);
		}
		return this;
	}

	/**
	 * Retrieve the positional values for the current time.
	 * @param {Number} [currentTimeMs=Date.now()] the value to use as the current time (optional)
	 * @returns {Array} An array of the calculated position values
	 * */
	getValues(currentTimeMs) {
		const target = this._getCurrentTarget();
		if (this.complete) {
			return target.targetValues;
		} else {
			currentTimeMs = isNaN(currentTimeMs) ? Date.now() : currentTimeMs;
			this._stepsHousekeeping(currentTimeMs);
			if (currentTimeMs >= target.targetTimeMs) {
				this.complete = true;
				return target.targetValues;
			} else {
				const currentWidth = target.targetValues.length;
				const startTimeMs = this._steps[0].startTimeMs;
				const normalizedTime = (currentTimeMs - startTimeMs) / (target.targetTimeMs - startTimeMs);
				const result = [];
				for (let i = 0; i < currentWidth; i++) {
					const column = this._getColumn(i, currentTimeMs);
					const bezier = Easing.bezierEase1D(normalizedTime, column, this._ease)
					result.push(bezier);
				}
				return result;
			}
		}
	}

	/**
	 * Removes all but the most recent expired steps (if any) from this._steps
	 * @param {Number} currentTimeMs Integer time value for which to retrieve data. Default scale is ms.
	 * @returns
	 * */
	_stepsHousekeeping(currentTimeMs) {
		// Last in wins when defining the end of the animation, so cap steps' target times accordingly
		const maxTargetTime = this._getCurrentTarget().targetTimeMs;
		this._steps.forEach(x => x.targetTimeMs = x.targetTimeMs > maxTargetTime ? maxTargetTime : x.targetTimeMs);

		// Remove expired steps from the list, except for the last one (if any), which we retain as either a
		// control point or as the ending point if it turns out that everything has expired.
		const expiredSteps = this._steps.filter(x => x.targetTimeMs <= currentTimeMs);
		const activeSteps = this._steps.filter(x => x.targetTimeMs > currentTimeMs);
		if (expiredSteps.length > 0) {
			activeSteps.unshift(expiredSteps[expiredSteps.length - 1]);
		}
		this._steps = activeSteps;
	}

	/**
	 * Retrieve the current (based on time) value at index for each step in this._steps
	 * @param {Number} index Integer array index
	 * @param {Number} currentTimeMs Integer time value for which to retrieve data. Default scale is ms.
	 * @returns {Array}
	 * */
	_getColumn(index, currentTimeMs) {
		const defaultValue = this._getCurrentTarget().targetValues[index];
		return this._steps.map(x => isNaN(x.targetValues[index]) ? defaultValue : x.getValueAtTime(index, currentTimeMs));
	}

	/**
	 * Retrieve the current overall target.
	 * @returns {ArrayTween}
	 * */
	_getCurrentTarget() {
		return this._steps[this._steps.length - 1];
	}
}


class ArrayTween {
	/**
	 * A simple tween with a starting array, a target array, and time values defined
	 * @param {Array.<Number>} startValues An array of numeric values defining the starting values
	 * @param {Array.<Number>} targetValues An array of numeric values defining the target
	 * @param {Number} startTimeMs An integer value defining the start time in milliseconds
	 * @param {Number} targetTimeMs An integer value defining the time in milliseconds at which the transformation should complete
	 * @param {Function} easeFunction An easing function.
	 * @param {Map.TweenValueBoundary>} boundaryMap A map of boundary objects where the key corresponds to the column index in the values arrays
	 * @returns {ArrayTween}
	 * */
	constructor(startValues, targetValues, startTimeMs, targetTimeMs, easeFunction, boundaryMap) {
		this.startValues = startValues;
		this.targetValues = targetValues;
		this.startTimeMs = startTimeMs;
		this.targetTimeMs = targetTimeMs;
		this._boundaryMap = boundaryMap;
		this._ease = easeFunction;
	}

	/**
	 * @property {Number} duration An integer value describing the difference between startTime and targetTime
	 * @readonly
	 * */
	get durationMs() {
		return this.targetTimeMs - this.startTimeMs;
	}

	/**
	 * Retrieve the eased value for a particular dimension at a given time value
	 * @param {Number} index Integer array index
	 * @param {Number} timeMs Integer time value for which to retrieve data.
	 * @returns {Number}
	 * */
	getValueAtTime(index, timeMs) {
		const normalized = this.durationMs === 0 ? 1 : (timeMs - this.startTimeMs) / this.durationMs;
		const eased = normalized <= 0 ? 0 : normalized >= 1 ? 1 : this._ease(normalized);

		const boundary = this._boundaryMap?.get(index);

		if (boundary) {
			const boundDifference = boundary.getDifference(this.startValues[index], this.targetValues[index]);
			return boundary.getBoundValue((eased * boundDifference) + this.startValues[index]);
		} else {
			return (eased * (this.targetValues[index] - this.startValues[index])) + this.startValues[index];
		}
	}

	/**
	 * Retrieve the eased values for all dimensions at a given time value
	 * @param {Number} timeMs Integer time value for which to retrieve data.
	 * @returns {Array.<Number>}
	 * */
	getValuesAtTime(timeMs) {
		const result = [];
		for (let i = 0; i < this.targetValues.length; i++) {
			result.push(this.getValueAtTime(i, timeMs));
		}
		return result;
	}
}

class TweenValueBoundary {
	/**
	 * Defines a boundary on a tween value
	 * @param {Number} lowerBound Defines the lowest possible value
	 * @param {Number} upperBound Defines the highest possible value
	 * @param {Boolean} wraps Indicates whether values that pass a boundary should wrap to the other end
	 * @returns {TweenValueBoundary}
	 * */
	constructor(lowerBound, upperBound, wraps) {
		if (lowerBound > upperBound) {
			this.lowerBound = upperBound;
			this.upperBound = lowerBound;
		} else {
			this.lowerBound = lowerBound;
			this.upperBound = upperBound;
		}
		this.wraps = !!wraps;
	}

	/**
	 * Translates a given number to a number within the defined boundary
	 * @param {Number} input the input value
	 * @returns {Number}
	 * */
	getBoundValue(input) {
		if (!IrUtils.isNumber(input)) {
			return null;
		}

		let output = input - this.lowerBound;
		const width = this.upperBound - this.lowerBound;

		if (this.wraps) {
			output = ((output % width) + width) % width;
		} else if (output < 0) {
			output = 0;
		} else if (output > width) {
			output = width;
		}

		return output + this.lowerBound;
	}

	/**
	 * Returns the best difference (b - a) between two values, taking into account the boundary and wrapping behavior
	 * @param {Number} a The first value to compare
	 * @param {Number} b The second value to compare
	 * @returns {Number} The effective difference between the two values
	 * */
	getDifference(a, b) {
		if (!IrUtils.isNumber(a) || !IrUtils.isNumber(b)) {
			return null;
		}

		a = this.getBoundValue(a);
		b = this.getBoundValue(b);

		if (this.wraps) {
			const width = this.upperBound - this.lowerBound;
			if ((b + width - a) % width <= width / 2) {
				return (b - a + width) % width;
			} else {
				return (b - a - width) % width;
			}
		} else {
			return b - a;
		}
	}
}

export { ArrayTween, ArrayStreamTween, TweenValueBoundary }
