import $ from 'jquery';

const CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
const defaultApiAjaxArgs = { dataType: 'json', method: 'POST' };

class AiService {
	constructor(options) {
		this._rootUrl = options?.settings?.aiService?.url;
		this._apiUrl = this._rootUrl + 'api/v0/';
	}

	/**
	 * Returns a Promise with the audio URL for the TTS.
	 * @param {string} text
	 * @param {string=} voice
	 * @returns {Promise<string>} The URL
	 */
	getTtsAudioUrl(text, voice) {
		const params = {
			text: text
		};
		if (voice) {
			params.voice = voice;
		}
		return new Promise((resolve, reject) => {
			$.ajax(this._apiUrl, $.extend({}, defaultApiAjaxArgs, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'get-tts',
					id: Math.random(),
					params: params
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			}).done((data, status, jqXhr) => {
				if (!data?.result?.id) {
					reject(jqXhr, status);
				} else {
					resolve(`${this._rootUrl}text-to-speech/${data.result.id}.mp3`);
				}
			});
		});
	}
}

export { AiService }
