const PI = Math.PI;
const EARTH_RADIUS_METERS = 6378000;

function d2r(degrees) {
	return degrees * (PI / 180);
}

function r2d(radians) {
	return radians * (180 / PI);
}

/**
 * 
 * @param {{x,y}} a
 * @param {{x,y}} b
 */
function radianDistance(a, b, radius = 1) {
	return (Math.acos(Math.cos(a.x) * Math.cos(b.x) +
		Math.sin(a.x) * Math.sin(b.x) * Math.cos(a.y - b.y))) * radius;
}

function degreeDistance(a, b, radius = 1) {
	return r2d(radianDistance({ x: d2r(a.x), y: d2r(a.y) }, { x: d2r(b.x), y: d2r(b.y) }, radius));
}

const Spherical = {
	EARTH_RADIUS_METERS: EARTH_RADIUS_METERS,
	d2r: d2r,
	r2d: r2d,
	radianDistance: radianDistance,
	degreeDistance: degreeDistance
}

export { Spherical }
