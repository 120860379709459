/**
 * A chat message (text, sent by, at what location, when, etc).
 * @class
 */
class ChatMessage {
	/**
	 * @param {object} message - A chat message object from an API request or from callosum (handles both formats).
	 */
	constructor(message) {
		/** @member {number} id */
		this.id = message.Id ?? message.uniqueId;
		/** @member {string} */
		this.chatText = message.ChatText ?? message.chatText;
		/** @member {string} */
		this.contentPath = message.ContentPath ?? message.contentPath;
		/** @member {string} */
		this.createdDateTime = message.CreatedDateTime ?? message.createdDateTime;
		/** @member {string} */
		this.participantId = message.ParticipantId ?? message.participantId;
		/** @member {string} */
		this.name = message.Name ?? message.name;
		/** @member {number} */
		this.x = message.Hlook ?? message.hlookat;
		/** @member {number} */
		this.y = message.Vlook ?? message.vlookat;
		/** @member {number} */
		this.fov = message.Fov ?? message.fov;
		/** @member {string} */
		this.thumbUrl = message.ThumbUrl ?? message.thumbUrl;

		/** @member {boolean} */
		this.hasAnsweredQuestion = false;

		const options = message.chatQuestion?.Options ?? message.chatQuestion?.options;
		/** @member {boolean} */
		this.hasQuestion = !!options?.length;
		/** @member {string} */
		this.question = message.chatQuestion?.Message;
		/** @member {number} */
		this.defaultOptionId = message.chatQuestion?.DefaultOptionId;
		/** @member {any} */
		this.responseOptions = options;
		/** @member {boolean} */
		this.askedQuestion = !!message.askedQuestion;

	}
}

export { ChatMessage }
