import React from "react";

export default function SendIcon(props) {
	if (props.isMic) {
		return (
			<svg className={props.className} height="18px" viewBox="0 -960 960 960" width="19px">
				<path
					d="M480-384q-50 0-85-35t-35-85v-240q0-50 35-85t85-35q50 0 85 35t35 85v240q0 50-35 85t-85 35Zm-36 240v-99q-98.8-13.1-163.4-87.05Q216-404 216-504h72q0 79.68 56.23 135.84 56.22 56.16 136 56.16Q560-312 616-368.16q56-56.16 56-135.84h72q0 100-64.6 173.95Q614.8-256.1 516-243v99h-72Z"
					fill="currentColor"
				/>
			</svg>
		);
	} else {
		return (
			<svg className={props.className} width="19px" height="18px">
				<g transform="matrix(1 0 0 1 -8 -8 )">
					<path
						d="M 18.519662921348313 9.872191011235955  L 1.1207865168539326 17.780056179775283  C 0.853932584269663 17.89634831460674  0.5870786516853934 17.89634831460674  0.3735955056179776 17.721910112359552  C 0.1601123595505619 17.54747191011236  0 17.314887640449438  0 17.02415730337079  L 0 11.209550561797753  L 8.058988764044944 9  L 0 6.790449438202248  L 0 1.0339887640449443  C 0 0.7432584269662925  0.10674157303370788 0.5106741573033711  0.3735955056179775 0.3362359550561801  C 0.6404494382022472 0.16179775280898906  0.853932584269663 0.16179775280898906  1.1207865168539326 0.2780898876404498  L 18.519662921348313 8.244101123595506  C 18.83988764044944 8.418539325842698  19 8.651123595505616  19 9.05814606741573  C 19 9.465168539325845  18.83988764044944 9.697752808988763  18.519662921348313 9.872191011235955  Z "
						fillRule="nonzero"
						fill="currentColor"
						stroke="none"
						transform="matrix(1 0 0 1 8 8 )"
					/>
				</g>
			</svg>
		);
	}
}
