import { switchTabsByIndex } from "../utils/ui-helpers.es6.js";
/**
 * @file Functionality surrounding invinting via SMS/email.
 */

/** Phone number regex for .trim'd string. Not sure where this came from (-Mike). */
const PHONE_NUMBER_REGEX = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

function normalizePhoneNumber(phoneNumber) {
	return phoneNumber.replace(/[+\-()\. ]/g, '').trim();
}

/**
 * Given user input with a list of phone numbers, return an array of normalized phone numbers
 * or an error.
 * @param {string} phoneNumbers User input phone numbers (separated by comma) field.
 * @return {{phoneNumbers: string[], success: boolean}} result
 * result.success - were there at least 1 valid phone number provided, and no errors.
 */
function getPhoneNumbersFromString(phoneNumbers) {
	const result = {
		phoneNumbers: phoneNumbers.split(',')
			.map(pn => pn.trim())
			.filter(pn => pn.length > 0)
	};

	result.success = (result.phoneNumbers.length > 0) &&
		!result.phoneNumbers.some(pn => !PHONE_NUMBER_REGEX.test(pn));

	result.phoneNumbers = result.phoneNumbers.map(pn => normalizePhoneNumber(pn))

	return result;
}

function getEmailAddressesFromString(emailAddresses) {
	const result = {
		emailAddresses: emailAddresses.split(',')
			.map(ea => ea.trim())
			.filter(ea => ea.length > 0)
	};

	result.success = (result.emailAddresses.length > 0) &&
		!result.emailAddresses.some(ea => !/@/.test(ea)); // TODO: weak validation

	return result;

}

function prepEmailContent(chatInviteInfo, fromDisplayName, fromUsername) {
	const emailContent = {
		contentName: chatInviteInfo.Name,
		contentTypeName: 'the Infinityy Room for',
		footerName: fromDisplayName ? fromDisplayName : fromUsername,
		subject: fromDisplayName ? `${fromDisplayName} has invited you to ${chatInviteInfo.Name}` : `You have been invited to ${chatInviteInfo.Name}`
	};
	return emailContent;
}

const EmailPreferenceEnum = {
	Unknown: 0,
	DesktopClient: 1,
	WebGmail: 2,
	WebOutlook: 3,
	WebYahoo: 4,
	WebAOL: 5
};

function sendEmailMessage(messageContent, url, emailAddress) {
	let mailTo = null;
	let params = null;
	const body = `Hello ${emailAddress},\n\nPlease click the link below to explore ${messageContent.contentTypeName}:\n${messageContent.contentName}\n\n${url}\n\nSincerely,\n${messageContent.footerName}`;
	switch (EmailPreferenceEnum.DesktopClient/*window.mobileWebController.userModel.preferredEmailClient*/) {
		case EmailPreferenceEnum.DesktopClient:
			if (navigator.userAgent.match(/Android/i)) {
				params = {
					view: 'cm',
					fs: 1,
					to: emailAddress,
					subject: messageContent.subject,
					body: body
				};
				mailTo = 'https://mail.google.com/mail/';
				break;
			} else {
				params = {
					subject: messageContent.subject,
					body: body
				};
				mailTo = `mailto:${emailAddress}`;
				break;
			}
			break;
		case EmailPreferenceEnum.WebGmail:
			params = {
				view: 'cm',
				fs: 1,
				to: emailAddress,
				subject: messageContent.subject,
				body: body
			};
			mailTo = 'https://mail.google.com/mail/';
			break;
		case EmailPreferenceEnum.WebOutlook:
			params = {
				subject: messageContent.subject,
				body: body,
				to: emailAddress,
				path: '/mail/action/compose'
			};
			mailTo = 'https://outlook.office.com/owa/'
			break;
		case EmailPreferenceEnum.WebYahoo:
			params = {
				to: emailAddress,
				subject: subject,
				body: `Hello ${emailAddress},<br><br>Please click the link below to explore ${messageContent.contentTypeName}:<br>${messageContent.contentName}<br><br>${url}<br><br>Sincerely,<br>${messageContent.footerName}`
			};
			mailTo = 'https://compose.mail.yahoo.com/';
			break;
		default:
			params = {
				subject: messageContent.subject,
				body: body
			};
			mailTo = `mailto:${emailAddress}`;
			break;
	}
	mailTo = encodeURI(mailTo) + '?' + $.param(params);
	if (navigator.userAgent.match(/(iPhone|iPad|iPod)/i)) {
		window.location.href = mailTo;
	} else {
		window.open(mailTo);
	}
}

function openInviteDialog(params) {
	window.mobileWebController.headerMenuModel.modalOpen = "invite";
	window.mobileWebController.headerMenuView.update();

	const { phone, email } = params;

	if (phone) {
		$("#RecipientUsersPhoneNumber").val(phone);
	}
	if (email) {
		$("#RecipientUsersEmails").val(email);
	}

	const tabIndex = phone ? 2 : (email ? 3 : 1);
	switchTabsByIndex(tabIndex, "#InviteTabs", "#InviteSections");
}

export { getPhoneNumbersFromString, getEmailAddressesFromString, prepEmailContent, sendEmailMessage, openInviteDialog }
