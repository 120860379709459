/**
 * Tools for generating (or retrieving the current) clientInstanceId.
 * 
 * NOTE: External dependencies on window.name.
 */
let clientInstanceId = null;
const clientInstanceIdLength = 36;

const generateUUID = function () { // Public Domain/MIT
	let d = new Date().getTime();
	// Time in microseconds since page-load or 0 if unsupported
	let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = Math.random() * 16;
		if (d > 0) { // Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else { // Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
};

/**
 * Gets the existing clientInstanceId for this browser tab, or creates, sets (on the tab), and returns a new one.
 * @param {string=} tabIdPrefix string prefixed to the clientInstanceId in the browser tab's window.name (so as to
 * identify) it as storing the clientInstanceId for this site (as opposed to some other site setting window.name).
 * @return {string} clientInstanceId for this browser tab
 */
const getTabClientInstanceId = function (tabIdPrefix) {
	if (!tabIdPrefix) { tabIdPrefix = ''; }

	const uniqueTabId = window.name;

	if (clientInstanceId) {
		return clientInstanceId;
	}

	if (uniqueTabId.startsWith(tabIdPrefix) &&
		(uniqueTabId.length === (clientInstanceIdLength + tabIdPrefix.length)))
	{
		clientInstanceId = uniqueTabId.substring(tabIdPrefix.length);
		return clientInstanceId;
	}
	try {
		clientInstanceId = crypto.randomUUID();
	} catch (_ /* likely unsupported browser or browser security context/environment */) {
		clientInstanceId = generateUUID();
	}

	window.name = tabIdPrefix + clientInstanceId;

	return clientInstanceId;
};

export { generateUUID, getTabClientInstanceId }
