import { PipModel } from "./pip-model.es6.js"
import { PipView } from "./pip-view.es6.js"

/**
 * View for all the pips.
 */
class PipsView {
	constructor(model, clientInstanceId) {
		this.clientInstanceId = clientInstanceId;
		this.model = model;
		this.pips = new Map();
		this._$pipsContainer = $('#TopOfViewerPips');
		this._$pipStatus = $('#MobilePipStatus');
		this._$youPipContainer = $('#YouPipContainer');
		this._$otherPipContainer = $('#InnerPipsContainer');
		this._$whoWhereToggle = $('#ToggleAvatarMode');
		this.onWhoWhereToggleClick = function () { return true; }
		if (!window.useWhereMode) {
			this._$whoWhereToggle.hide();
		}
	}

	getPipView(clientInstanceId) {
		return this.pips.get(clientInstanceId);
	}

	initializeView() {
		for (const id of this.model.pips.keys()) {
			this._addPip(id);
		}
		this._$whoWhereToggle.unbind('click').click(() => {
			if (this.onWhoWhereToggleClick) { this.onWhoWhereToggleClick(); }
		});
		this._updateView();
		return this;
	}

	update(diff) {
		for (const id of diff.added) { this._addPip(id); }
		for (const id of diff.updated) { this.updatePip(id); }
		for (const id of diff.removed) { this._removePip(id); }
		this._updateView();
		return this;
	}

	updatePip(id) {
		const pip = this.pips.get(id);
		if (pip) { pip.update(); }
		return this;
	}

	_updateView() {
		const $pipRelated = $(this._$pipsContainer).add(this._$pipStatus);
		this.model.pips.size > 1 ? $pipRelated.show() : $pipRelated.hide();
		window.mobileWebController.mainContainerFirstPanel?.resize(this.model.pips.size > 1 ? 65 : 55);
		return this;
	}

	_addPip(clientInstanceId) {
		let pipView;
		if (!this.pips.has(clientInstanceId)) {
			const pipModel = this.model.getPip(clientInstanceId);
			if (pipModel) {
				pipView = new PipView(pipModel, clientInstanceId == window.clientInstanceId);
				this.pips.set(clientInstanceId, pipView);
				pipView.render(pipView.isYouPip ? this._$youPipContainer : this._$otherPipContainer);
				console.debug('[pips]', `Adding pip view ${clientInstanceId}.`);
			}
		} else {
			this.pips.get(clientInstanceId).update();
		}
		return this;
	}

	_removePip(clientInstanceId) {
		const pipView = this.pips.get(clientInstanceId);
		if (pipView) {
			pipView.remove();
			console.debug('[pips]', `Removing pip view ${clientInstanceId}.`);
			this.pips.delete(clientInstanceId);
		}
		return this;
	}
}

export { PipsView }
