// Our Library for Pip Rendering
EveryScape = EveryScape || {};

EveryScape.Pip = function () {
	var LAYOUTMODE_GRID = 'grid';
	var LAYOUTMODE_SINGLEROW = 'singlerow';
	var LAYOUTMODE_VIEWER_TOP = 'viewer_top';
	var LABEL_INSIDE_TOP = 'inside_top';
	var LABEL_INSIDE_BOTTOM = 'inside_bottom';
	var LABEL_OUTSIDE_ABOVE = 'outisde_top';
	var LABEL_OUTSIDE_BELOW = 'outside_bottom';
	var PIPMODE_AVATAR = 'pipmode_avatar';
	/*
		The above modes change pip sizes based on the container. Documented here:
		LAYOUTMODE_GRID
			The pip size is a square with height & width equal to the width of
			the container divided by number of viewports across. For example, if the 
			container is 100px across, and we want 4 viewports in a row, each pip
			will be 25x25px. As pips are added, the canvas height is resized to 
			accommodate the pips.
		LAYOUTMODE_SINGLEROW
			The pip size is a square with height & width equal to the height of
			the container. For example, if the container's height is 100px, each pip
			will be 100x100px. As pips are added, the container's width is adjusted to
			accommodate the pips.
	*/

	var animation = {
		// no easing, no acceleration
		linear: function (t) { return t },
		// accelerating from zero velocity
		easeInQuad: function (t) { return t * t },
		// decelerating to zero velocity
		easeOutQuad: function (t) { return t * (2 - t) },
		// acceleration until halfway, then deceleration
		easeInOutQuad: function (t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t },
		// accelerating from zero velocity 
		easeInCubic: function (t) { return t * t * t },
		// decelerating to zero velocity 
		easeOutCubic: function (t) { return (--t) * t * t + 1 },
		// acceleration until halfway, then deceleration 
		easeInOutCubic: function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 },
		// accelerating from zero velocity 
		easeInQuart: function (t) { return t * t * t * t },
		// decelerating to zero velocity 
		easeOutQuart: function (t) { return 1 - (--t) * t * t * t },
		// acceleration until halfway, then deceleration
		easeInOutQuart: function (t) { return t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t },
		// accelerating from zero velocity
		easeInQuint: function (t) { return t * t * t * t * t },
		// decelerating to zero velocity
		easeOutQuint: function (t) { return 1 + (--t) * t * t * t * t },
		// acceleration until halfway, then deceleration 
		easeInOutQuint: function (t) { return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t },
		/*
		 These methods are used for animations. They each take the following parameters.
			 start: beginning value (value at t == 0)
			 target: target value (value at t == 1)
			 t: the current time value between 0 & 1
			 method: one of the methods described above, or a custom function that transforms a t value.
			 pts: an array of values
		*/
		ease: function (t, start, target, method) {
			if (t < 0) {
				return start;
			}
			else if (t >= 1.0) {
				return target;
			}

			var dt = method(t);
			return start + (target - start) * dt;
		},

		bezierEase1D: function (t, pts, method) {
			var dt = method(t);
			dt = (dt < 0) ? 0 : dt;
			dt = (dt > 1) ? 1 : dt;

			if (pts.length > 1) {
				var nPts = [];
				var i;
				for (i = 1; i < pts.length; i++) {
					var start = pts[i - 1];
					var end = pts[i];
					var value = start + (end - start) * dt;
					nPts.push(value);
				}

				var value = this.bezierEase1D(t, nPts, method);
				return value;
			}

			var value = pts[0];
			return value;
		}
	}

	// This class is used to simplify animations.
	class AnimTween {
		constructor(start, target, method) {
			if (!method) { method = animation.easeOutCubic; }
			this.start = start;
			this.target = target;
			this.method = method;
			this.time = 0;
		}

		getValue() {
			return animation.bezierEase1D(this.time, [this.start, this.target], this.method);
		}

		setValue(value) {
			this.start = value;
			this.target = value;
			this.time = 0;
		}

		setTarget(value) {
			this.start = this.getValue();
			this.target = value;
			this.time = 0;
		}
	}

	// An object to contain the state of the EveryScape.Pip instance
	var state = {
		clock: new THREE.Clock(),
		elapsedTimeSeconds: 0.0,
		elapsedTimeDampener: 1.0,   // lower is slower, higher is faster
		clickListeners: [],
		container: {
			element: null,
			id: null,
			height: null,
			width: null
		},
		renderer: null,
		layout: {
			mode: LAYOUTMODE_GRID, // Grid or Single Row Mode
			pipSize: 96.0, // height/width of a single pip
			pipMargin: 2.0, // margin on each side of the pip
			viewPortsAcross: 3, // Number of viewports across, in the grid.
			textLabelHeight: 16, // Height of the area for name labels
			pipCellLayoutMode: PIPMODE_AVATAR, // Position of the area for name labels
			avatarImageWidth: 96, // Width of the area for avatar image -- currently in px units not vw
			avatarImageHeight: 96, // Height of the area for avatar image -- currently in px units not vw
			showLabel: false, // Flag whether to display label
			showAvatar: false, // Flag whether to display avatar
			showPlaceholderLabel: true, // Flag whether to display placeholder label
			viewportSize: 96.0, // height/width of the rendered content area
			viewportMargin: 2.0 // clipping margin for the rendered content area
		},
		views: {},

		updateElapsedTime: function () {
			this.elapsedTimeSeconds = this.clock.getDelta() * this.elapsedTimeDampener;
		}
	};

	//Pip avatar images were changed to VW units, so this is to position them
	var convertPixelsToViewerWidths = function (numberOfPixels) {
		return numberOfPixels * 100 / window.innerWidth;
	};

	var removeUnitsFromNumber = function (numberWithUnits, unitType) {
		return numberWithUnits.replace(unitType, "");
	}

	// An object to contain the private methods
	var priv = {
		// PRIVATE: Creates the text label below the viewport.
		createTextLabel: function () {
			var div = document.createElement('div');
			div.className = 'espip-text-label oneLineText';
			div.style.width = 100;
			div.style.height = state.layout.textLabelHeight;
			div.style.top = -1000;
			div.style.left = -1000;
			return {
				element: div,
				setText: function (text, formattedText) {
					if (!formattedText) { formattedText = text; }
					this.element.innerHTML = formattedText;
					this.element.title = text;
				},
				getText: function () {
					return this.element.title;
				},
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				},
				getLabelText: function () {
					return this.element.title;
				}
			};
		},

		reuseTextLabel: function (div) {
			return {
				element: div,
				setText: function (text, formattedText) {
					if (!formattedText) { formattedText = text; }
					this.element.innerHTML = formattedText;
					this.element.title = text;
				},
				getText: function () {
					return this.element.title;
				},
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				},
				getLabelText: function () {
					return this.element.title;
				}
			};
		},

		createMenuItem: function () {
			var menuDiv = document.createElement('div');
			menuDiv.className = 'espip-text-label-menu';
			menuDiv.style.top = -1000;
			menuDiv.style.left = -1000;
			menuDiv.style.width = 30;
			menuDiv.style.height = 30;
			return {
				element: menuDiv,
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				}
			};
		},

		reuseMenuItem: function (div) {
			return {
				element: div,
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				}
			};
		},

		createPlaceholderTextLabel: function () {
			var div = document.createElement('div');
			div.className = 'espip-placeholder-text-label oneLineText';
			div.style.width = 100;
			div.style.height = state.layout.textLabelHeight;
			div.style.top = -1000;
			div.style.left = -1000;
			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
					this.element.name = text;
				},
				getText: function () {
					return this.element.name;
				},
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				},
				getLabelText: function () {
					return this.element.innerHTML;
				}
			};
		},

		reusePlaceholderTextLabel: function (div) {
			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
					this.element.name = text;
				},
				getText: function () {
					return this.element.name;
				},
				setWidth: function (width) {
					this.element.style.width = width + 'px';
					this.element.style.height = state.layout.textLabelHeight + 'px';
				},
				setPosition: function (x, y) {
					this.element.style.left = x + 'px';
					this.element.style.top = y + 'px';
				},
				getLabelText: function () {
					return this.element.innerHTML;
				}
			};
		},

		createHtmlOverlay: function () {
			var div = document.createElement('div');
			div.className = 'espip-html-overlay';
			div.style.width = state.layout.avatarImageWidth;
			div.style.height = state.layout.avatarImageHeight;
			div.style.top = -1000;
			div.style.left = -1000;

			var trackIcons = document.createElement('div');
			trackIcons.className = 'espip-track-icons';
			div.appendChild(trackIcons);

			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
				},
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				}
			};
		},

		reuseHtmlOverlay: function (div) {
			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
				},
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				}
			};
		},

		createHtmlBackground: function () {
			var div = document.createElement('div');
			div.className = 'espip-html-background';
			div.style.width = state.layout.pipSize;
			div.style.height = state.layout.pipSize;
			div.style.top = -1000;
			div.style.left = -1000;

			return {
				element: div,
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				}
			};
		},

		reuseHtmlBackground: function (div) {
			return {
				element: div,
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				}
			};
		},

		createMediaTrackContainer: function (className) {
			var div = document.createElement('div');
			div.classList.add(className);
			div.style.position = 'absolute';
			div.style.width = '100%';
			div.style.height = '100%';
			div.style.top = 0;
			div.style.left = 0;
			div.style.margin = 0;
			div.style.padding = 0;
			div.style.pointerEvents = 'none';
			div.style.zIndex = 20;

			return div;
		},

		createAvatarImage: function () {
			var div = document.createElement('div');
			div.className = 'espip-avatar-image';
			div.style.width = state.layout.avatarImageWidth;
			div.style.height = state.layout.avatarImageHeight;
			div.style.top = -1000;
			div.style.left = -1000;
			var videoContainer = priv.createMediaTrackContainer('espip-video-track');
			div.appendChild(videoContainer);

			var screenVideoContainer = priv.createMediaTrackContainer('espip-screen-video-track');
			div.appendChild(screenVideoContainer);
			var audioContainer = priv.createMediaTrackContainer('espip-audio-track');
			audioContainer.style.opacity = 0;
			audioContainer.style.height = '1px';
			audioContainer.style.width = '1px';
			div.appendChild(audioContainer);

			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
				},
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				},
				setImageUrl: function (imageUrl) {
					this.element.style.backgroundImage = 'url(' + imageUrl + ')';
				}
			};
		},

		reuseAvatarImage: function (div) {
			return {
				element: div,
				setText: function (text) {
					this.element.innerHTML = text;
				},
				setHeight: function (height, unitType) {
					this.element.style.height = height + unitType;
				},
				setWidth: function (width, unitType) {
					this.element.style.width = width + unitType;
				},
				setPosition: function (x, y, xUnits, yUnits) {
					if (xUnits !== 'vw') {
						this.element.style.left = x + 'px';
					} else {
						this.element.style.left = (convertPixelsToViewerWidths(x) - removeUnitsFromNumber(this.element.style.width, 'vw')) + 'vw';
					}
					if (yUnits !== 'vw') {
						this.element.style.top = y + 'px';
					} else {
						this.element.style.top = (convertPixelsToViewerWidths(y) - removeUnitsFromNumber(this.element.style.height, 'vw')) + 'vw';
					}
				},
				setImageUrl: function (imageUrl) {
					this.element.style.backgroundImage = 'url(' + imageUrl + ')';
				}
			};
		},

		createPipCover: function () {
			var div = document.createElement('div');
			div.className = 'espip-cover-image';
			div.style.position = 'absolute';
			div.style.width = 100;
			div.style.height = 100;
		},

		// PRIVATE: Loads a texture into a custom material.
		loadTexture: function (path) {
			var texture = new THREE.TextureLoader().load(path);
			var uniforms = {
				texture1: { type: "t", value: texture },
				vpCenterX: { value: state.layout.pipSize },
				vpCenterY: { value: state.layout.pipSize },
				pipSize: { value: state.layout.pipSize },
				margin: { value: state.layout.pipMargin }
			};

			var material = new THREE.ShaderMaterial({
				uniforms: uniforms,
				vertexShader: EveryScape.Pip.Shaders.vertShader,
				fragmentShader: EveryScape.Pip.Shaders.fragShader,
				blending: THREE.NormalBlending,
				depthTest: false,
				transparent: true
			});
			material.updateUniforms = function (vpx, vpy) {
				material.uniforms.vpCenterX.value = vpx;
				material.uniforms.vpCenterY.value = vpy;
				material.uniforms.pipSize.value = state.layout.pipSize;
				material.uniforms.margin.value = state.layout.pipMargin;
			};

			return material;
		},

		// PRIVATE: Returns a list of objects to render, in the order we desire to render them.
		objectsToRender: function () {
			var prefixes = Object.values(state.views).filter(function (value) {
				return value.type === 'prefix' || value.type === 'prefix2d';
			});

			var suffixes = Object.values(state.views).filter(function (value) {
				return value.type === 'suffix';
			});

			var other = Object.values(state.views).filter(function (value) {
				return value.type !== 'prefix' && value.type !== 'prefix2d' && value.type !== 'suffix';
			});

			return prefixes.concat(other).concat(suffixes);
		},

		// PRIVATE: Handles mouse click messages
		onCanvasClick: function (e) {
			var x = e.layerX;
			var y = e.layerY;

			var col = Math.floor(x / state.layout.pipSize);
			var row;

			if (state.layout.showLabel) {
				row = Math.floor(y / (state.layout.pipSize));
			} else {
				row = Math.floor(y / state.layout.pipSize);
			}

			var index = row * state.layout.viewPortsAcross + col;

			var objects = priv.objectsToRender();
			if (index < objects.length) {
				var obj = objects[index];

				for (var i = 0; i < state.clickListeners.length; i++) {
					state.clickListeners[i](obj);
				}
			}
		},

		// PRIVATE: Updates the canvas size to be a grid that is numViewportsAcross
		updateCanvasSize: function () {
			var numViews = Object.keys(state.views).length;
			var layout = state.layout;

			if (layout.mode === LAYOUTMODE_GRID) {
				// console.log("updateCanvasSize grid");
				// First, get the pip size && proposed canvas height
				layout.pipSize = Math.floor(state.container.width / layout.viewPortsAcross);
				var rows = Math.ceil(numViews / layout.viewPortsAcross) || 1;
				var canvasHeight;
				switch (state.layout.pipCellLayoutMode) {
					case LABEL_INSIDE_BOTTOM:
					case LABEL_INSIDE_TOP:
						canvasHeight = layout.pipSize * rows;
						break;
					case LABEL_OUTSIDE_ABOVE:
					case LABEL_OUTSIDE_BELOW:
					case PIPMODE_AVATAR:
						canvasHeight = layout.pipSize * rows;
						break;
					default:
						canvasHeight = layout.pipSize * rows;
						console.log('WARNING: pipCellLayoutMode: ' + state.layout.pipCellLayoutMode + ' is not an option');
				}

				// Update the renderer size
				state.renderer.setSize(layout.pipSize * layout.viewPortsAcross, canvasHeight);
			}
			else if (layout.mode === LAYOUTMODE_SINGLEROW) {
				//  console.log("updateCanvasSize singlerow");
				// First, get the pip size && proposed canvas height
				layout.pipSize = state.container.height;
				var canvasWidth = layout.pipSize * numViews;
				var canvasHeight = layout.pipSize;
				// Update the renderer size
				state.renderer.setSize(canvasWidth, canvasHeight);
			}
			else if (layout.mode === LAYOUTMODE_VIEWER_TOP) {
				//  console.log("updateCanvasSize viewer_top");
				// First, get the pip size && proposed canvas height
				layout.pipSize = state.container.height;
				var canvasWidth = layout.pipSize * numViews;
				var canvasHeight = layout.pipSize;
				// Update the renderer size
				state.renderer.setSize(canvasWidth, canvasHeight);
			}
			// Update the views
			for (var i = 0, keys = Object.keys(state.views); i < keys.length; i++) {
				var view = state.views[keys[i]];
				view.width = layout.pipSize;
				view.height = layout.pipSize;
				view.camera.aspect = view.width / view.height;
			}
		},

		// PRIVATE: Checks if the canvas should be resized, and if so, it does it.
		updateSize: function () {
			var container = state.container;
			if (container.width !== container.element.clientWidth
				|| container.height !== container.element.clientHeight) {

				container.width = container.element.clientWidth;
				container.height = container.element.clientHeight;

				this.updateCanvasSize();
			}
		}

	};

	// An object to contain the public methods
	var pub = {
		clearClickListeners: function () {
			state.clickListeners = [];
		},

		// Creates a button that is appended to the beginning
		// of the widget.
		// Parameters:
		//  id      -   The id of the button.
		//  type    -   Either 'prefix' or 'suffix' (depending on where you want it)
		//              'pip' makes it act like a pip.
		//              'pip2d' is for a 2d pip.
		//  textureLocation   -   The address of the texture to display.
		updateView: function (id, type, textureLocation) {
			var previousView = state.views[id];

			if (previousView && previousView.textureLocation === textureLocation) {
				return previousView;
			}

			var l = state.layout;
			var view = {
				id: id,
				left: 0,	// Gets updated during render.
				top: 0,
				width: l.pipSize,
				height: l.pipSize,
				eye: [0, 0, 0],
				up: [0, 1, 0],
				type: type,
				renderer: null,
				mediaIndex: '', // compare (via getMediaIndex) to decide whether to run updateView or just change position
				textureLocation: textureLocation,

				// Yaw / Pitch
				yawDegrees: new AnimTween(90, 90),
				pitchDegrees: new AnimTween(0, 0),

				// Field of View
				fov: new AnimTween(60, 60),

				// Zoom
				zoomFactor: new AnimTween(1.0, 1.0),

				// 2D Position
				position2D: [new AnimTween(0, 0), new AnimTween(0, 0)], // [x, y]

				setPosition2D: function (x, y) {
					this.position2D[0].setValue(x);
					this.position2D[1].setValue(y);
					return this;
				},

				gotoPosition2D: function (x, y) {
					this.position2D[0].setTarget(x);
					this.position2D[1].setTarget(y);
					return this;
				},

				setFov: function (fov) {
					this.fov.setValue(fov);
					return this;
				},

				gotoFov: function (fov) {
					this.fov.setTarget(fov);
					return this;
				},

				setZoomFactor: function (zoom) {
					this.zoomFactor.setValue(zoom);
					return this;
				},

				gotoZoomFactor: function (zoom) {
					this.zoomFactor.setTarget(zoom);
					return this;
				},

				setMediaIndex: function (mediaIndex) {
					this.mediaIndex = mediaIndex;
					return this;
				},

				getMediaIndex: function () {
					return this.mediaIndex;
				},

				setYawPitchDegrees: function (yaw, pitch) {
					this.yawDegrees.setValue(yaw);
					this.pitchDegrees.setValue(pitch);
					return this;
				},

				gotoYawPitchDegrees: function (yaw, pitch) {
					var currentYaw = this.yawDegrees.getValue();

					var d0 = Math.abs(yaw - currentYaw);
					var d1 = Math.abs((yaw + 360) - currentYaw);
					var d2 = Math.abs((yaw - 360) - currentYaw);

					if (d1 < d0 && d1 < d2) {
						this.yawDegrees.setTarget(yaw + 360);
					}
					else if (d2 < d0 && d2 < d1) {
						this.yawDegrees.setTarget(yaw - 360);
					}
					else {
						this.yawDegrees.setTarget(yaw);
					}

					this.pitchDegrees.setTarget(pitch);

					return this;
				},

				dispose: function () {
					if (this.scene) {
						for (var i = this.scene.children.length - 1; i >= 0; i--) {
							var child = this.scene.children[i];

							if (child instanceof THREE.Sprite) {
								if (child.material.map) {
									child.material.map.dispose();
								}
							}

							if (child instanceof THREE.Mesh) {
								for (var m = 0; m < child.material.length; m++) {
									var material = child.material[m];

									if (material.map) {
										material.map.dispose();
									}

									if (material.uniforms.texture1.value) {
										material.uniforms.texture1.value.dispose();
									}

									material.dispose();
								}
							}

							this.scene.remove(child);
						}

						if (this.scene instanceof THREE.Scene) {
							//this.scene.dispose();
						}

						this.scene = null;
					}

					if (this.renderer) {
						this.renderer.dispose();
					}
				},

				render: function (vpx, vpy) {
					var view = this;
					var camera = view.camera;

					var left = view.left;
					var top = view.top;
					var width = view.width;
					var height = view.height;

					var viewportLeft = left;
					var viewportTop = top;
					var viewportWidth = width;
					var viewportHeight = height;

					state.layout.viewportSize = state.layout.pipSize;
					state.layout.viewportMargin = state.layout.pipMargin;

					var avatarMargin = 10;
					var avatarLeft = left + avatarMargin;
					var avatarTop = top + height - avatarMargin - state.layout.avatarImageHeight;
					var avatarWidth = state.layout.avatarImageWidth;
					var avatarHeight = state.layout.avatarImageHeight;


					if (state.layout.pipCellLayoutMode == PIPMODE_AVATAR) {
						viewportHeight = avatarHeight;
						viewportLeft = left;
						viewportWidth = avatarWidth;
						viewportTop = height - avatarHeight;

						state.layout.viewportSize = avatarWidth > avatarHeight ? avatarHeight : avatarWidth;

						// it seems like pip viewers have double margins? I made the avatar vid the same to match. -Miles
						var margin = state.layout.pipMargin * 2;
						avatarHeight = height - margin;
						avatarLeft = left + (margin / 2);
						avatarTop = top + (margin / 2);
						avatarWidth = width - margin;

					//	view.image && view.image.element && view.image.element.classList.add('fullsize');
					//	view.htmlOverlay && view.htmlOverlay.element && view.htmlOverlay.element.classList.add('fullsize');
					//} else {
					//	view.image && view.image.element && view.image.element.classList.remove('fullsize');
					//	view.htmlOverlay && view.htmlOverlay.element && view.htmlOverlay.element.classList.remove('fullsize');
					}

					this.renderer.setViewport(viewportLeft, viewportTop, viewportWidth, viewportHeight);
					this.renderer.setScissor(viewportLeft, viewportTop, viewportWidth, viewportHeight);
					this.renderer.setScissorTest(true);

					if (view.camera instanceof THREE.PerspectiveCamera) {
						// Update Yaw/Pitch
						this.yawDegrees.time += state.elapsedTimeSeconds;
						this.pitchDegrees.time += state.elapsedTimeSeconds;

						// Position the camera
						var phi = THREE.Math.degToRad(90 - this.pitchDegrees.getValue());
						var theta = THREE.Math.degToRad(this.yawDegrees.getValue());

						var target = new THREE.Vector3();
						target.x = 500 * Math.sin(phi) * Math.cos(theta);
						target.y = 500 * Math.cos(phi);
						target.z = 500 * Math.sin(phi) * Math.sin(theta);

						// Correct the camera
						camera.lookAt(target);

						// Field of View
						this.fov.time += state.elapsedTimeSeconds;
						camera.fov = this.fov.getValue();
					}
					else if (view.camera instanceof THREE.OrthographicCamera) {
						// Zoom factor
						this.zoomFactor.time += state.elapsedTimeSeconds;
						camera.zoom = this.zoomFactor.getValue();

						// Position
						this.position2D[0].time += state.elapsedTimeSeconds;
						this.position2D[1].time += state.elapsedTimeSeconds;

						this.camera.position.x = this.position2D[0].getValue();
						this.camera.position.y = this.position2D[1].getValue();
					}

					camera.aspect = viewportWidth / viewportHeight;
					camera.updateProjectionMatrix();

					// Set the uniform on each surface
					for (var i = 0; i < view.scene.children.length; i++) {
						var child = view.scene.children[i];

						for (var m = 0; m < child.material.length; m++) {
							var material = child.material[m];
							material.updateUniforms(vpx, vpy);
						}
					}

					// Render the scene
					this.renderer.render(view.scene, camera);
					//if (state.layout.showLabel) {
					//	var labelMargin = 12;

					//	switch (state.layout.pipCellLayoutMode) {
					//		case LABEL_INSIDE_BOTTOM:
					//		case LABEL_INSIDE_TOP:
					//			view.label.setPosition(left + labelMargin, top + labelMargin);
					//			view.label.setWidth(width - labelMargin * 2);
					//			break;
					//		case LABEL_OUTSIDE_ABOVE:
					//		case LABEL_OUTSIDE_BELOW:
					//		case PIPMODE_AVATAR:
					//			view.label.setPosition(left, top + labelMargin);
					//			view.label.setWidth(width);
					//			break;
					//		default:
					//			view.label.setPosition(left + labelMargin, top + labelMargin);
					//			view.label.setWidth(width - labelMargin * 2);
					//			console.log('WARNING: pipCellLayoutMode: ' + state.layout.pipCellLayoutMode + ' is not an option');
					//	}
					//	var menuMargin = 12;
					//	view.menu.setPosition(left + menuMargin, top + menuMargin);
					//}


					//if (!state.layout.showAvatar
					//	|| (state.layout.mode == LAYOUTMODE_SINGLEROW && state.layout.pipCellLayoutMode != PIPMODE_AVATAR)) {
					//	view.image.element.style.display = 'none';

					//	view.htmlOverlay.element.classList.add('fullsize');
					//	view.htmlOverlay.setPosition(left, top, 'px', 'px');
					//	view.htmlOverlay.setWidth(width, 'px');
					//	view.htmlOverlay.setHeight(height, 'px');

					//	view.htmlBackground.setPosition((left), (top), 'px', 'px');
					//	view.htmlBackground.setWidth((width), 'px');
					//	view.htmlBackground.setHeight((height), 'px');

					//} else {
					//	if (state.layout.pipCellLayoutMode == PIPMODE_AVATAR) {
					//		view.image.setPosition(avatarLeft, avatarTop, 'px', 'px');
					//		view.image.setWidth(avatarWidth, 'px');
					//		view.image.setHeight(avatarHeight, 'px');
					//	} else {
					//		view.image.setPosition(avatarLeft, avatarTop, 'px', 'px');
					//		view.image.setWidth(avatarWidth, 'px');
					//		view.image.setHeight(avatarHeight, 'px');
					//	}
					//	view.image.element.style.display = 'block';

					//	if (state.layout.pipCellLayoutMode == PIPMODE_AVATAR) {
					//		view.htmlOverlay.setPosition(avatarLeft, avatarTop, 'px', 'px');
					//		view.htmlOverlay.setWidth(avatarWidth, 'px');
					//		view.htmlOverlay.setHeight(avatarHeight, 'px');

					//		view.htmlBackground.setPosition((left), (top), 'px', 'px');
					//		view.htmlBackground.setWidth((width), 'px');
					//		view.htmlBackground.setHeight((height), 'px');
					//	} else {
					//		view.htmlOverlay.setPosition(avatarLeft, avatarTop, 'px', 'px');
					//		view.htmlOverlay.setWidth(avatarWidth, 'px');
					//		view.htmlOverlay.setHeight(avatarHeight, 'px');

					//		view.htmlBackground.setPosition((left), (top), 'px', 'px');
					//		view.htmlBackground.setWidth((width), 'px');
					//		view.htmlBackground.setHeight((height), 'px');
					//	}
					//}

					//if (state.layout.showPlaceholderLabel) {
					//	var placeholderLabelMargin = 12;
					//	view.placeholderLabel.setPosition(left + placeholderLabelMargin, top + (height / 2) - state.layout.textLabelHeight);
					//	view.placeholderLabel.setWidth(width - labelMargin * 2);
					//}
					return this;
				},

				setDisplayName: function (text, formattedText) {
					this.label.setText(text, formattedText);
					return this;
				},

				getDisplayName: function () {
					return this.label.getText();
				},

				setAvatarImage: function (imageUrl) {
					this.image.setImageUrl(imageUrl);
					return this;
				},

				setPlaceholderText: function (text) {
					this.placeholderLabel.setText(text);
					return this;
				},

				showPlaceholderLabel: function (display) {
					//state.layout.showPlaceholderLabel = display;
					if (display) {
						this.placeholderLabel.element.setAttribute('display', 'block');
					} else {
						this.placeholderLabel.element.setAttribute('display', 'none');
					}

					return this;
				}
			};

			// Setup the camera
			if (type == 'pip2d' || type == 'prefix2d') {
				var camera = new THREE.OrthographicCamera(-100, 100, 100, -100, 1, -500);
				view.camera = camera;
			}
			else {
				var camera = new THREE.PerspectiveCamera(view.fov, window.innerWidth / window.innerHeight, 1, 10000);
				camera.position.fromArray(view.eye);
				camera.up.fromArray(view.up);
				view.camera = camera;
			}

			// Setup the scene
			var scene = new THREE.Scene();
			var geometry = null;    // Set in the if statement below
			var materials = null;   // Set in the if statement below

			if (type == 'pip2d' || type == 'prefix2d') {
				geometry = new THREE.PlaneGeometry(200, 200);
				geometry.scale(1, 1, -1);
				materials = [priv.loadTexture(textureLocation)];

				// Fix the aspect ratio of the image by loading it and using the 
				// aspect ratio of the loaded image.
				var img = new Image();
				img.onload = function () {
					var aspectRatio = this.width / this.height;
					geometry.scale(aspectRatio, 1, -1);
				}
				img.src = textureLocation;
			}
			else {
				geometry = new THREE.BoxBufferGeometry(300, 300, 300, 7, 7, 7);
				geometry.scale(-1, 1, 1);

				if (textureLocation) {
					if (textureLocation.includes(".png") || textureLocation.includes(".jpg")) {
						materials = [
							priv.loadTexture(textureLocation), // right
							priv.loadTexture(textureLocation), // left
							priv.loadTexture(textureLocation), // top
							priv.loadTexture(textureLocation), // bottom
							priv.loadTexture(textureLocation), // front
							priv.loadTexture(textureLocation)  // back
						];
					} else {
						materials = [
							priv.loadTexture(textureLocation + '/MOBILE_R.jpg'), // right
							priv.loadTexture(textureLocation + '/MOBILE_L.jpg'), // left
							priv.loadTexture(textureLocation + '/MOBILE_U.jpg'), // top
							priv.loadTexture(textureLocation + '/MOBILE_D.jpg'), // bottom
							priv.loadTexture(textureLocation + '/MOBILE_F.jpg'), // front
							priv.loadTexture(textureLocation + '/MOBILE_B.jpg')  // back
						];
					}
				}
			}

			var mesh = null;
			if (materials !== null) {
				mesh = new THREE.Mesh(geometry, materials);
			} else {
				mesh = new THREE.Mesh(geometry);
			}

			scene.add(mesh);
			view.scene = scene;

			//if (state.layout.showLabel) {
			//	var existingLabel = document.getElementById(id);
			//	if (existingLabel === null) {
			//		// Create the text label and menu
			//		view.label = priv.createTextLabel();
			//		view.menu = priv.createMenuItem();
			//		view.label.element.setAttribute("id", id);
			//		view.menu.element.setAttribute("id", id + "-menu");
			//		state.container.element.appendChild(view.label.element);
			//		state.container.element.appendChild(view.menu.element);
			//	} else {
			//		view.label = priv.reuseTextLabel(existingLabel);
			//		var existingMenu = document.getElementById(id + "-menu");
			//		view.menu = priv.reuseMenuItem(existingMenu);
			//	}
			//}

			//if (state.layout.showAvatar) {
			//	var existingImage = document.getElementById(id + "-avatar");
			//	if (existingImage === null) {
			//		// Create the avatar img
			//		view.image = priv.createAvatarImage();
			//		view.image.element.setAttribute("id", id + "-avatar");
			//		state.container.element.appendChild(view.image.element);
			//		//EveryScape.SyncV2.Events.emit("PipAvatarCreated-" + id, view.image);
			//	} else {
			//		view.image = priv.reuseAvatarImage(existingImage);
			//	}
			//}

			//var existingHtmlOverlay = document.getElementById(id + "-pip-overlay");
			//if (existingHtmlOverlay === null) {
			//	view.htmlOverlay = priv.createHtmlOverlay("espip-html-overlay")
			//	view.htmlOverlay.element.setAttribute("id", id + "-pip-overlay");
			//	state.container.element.appendChild(view.htmlOverlay.element);
			//} else {
			//	view.htmlOverlay = priv.reuseHtmlOverlay(existingHtmlOverlay);
			//}

			//var existingHtmlBackground = document.getElementById(id + "-pip-background");
			//if (existingHtmlBackground === null) {
			//	view.htmlBackground = priv.createHtmlBackground("espip-html-background");
			//	view.htmlBackground.element.setAttribute("id", id + "-pip-background");
			//	state.container.element.appendChild(view.htmlBackground.element);
			//} else {
			//	view.htmlBackground = priv.reuseHtmlBackground(existingHtmlBackground);
			//}

			//var exisitngPlaceholderLabel = document.getElementById(id + "-placeholder-label");
			//if (exisitngPlaceholderLabel === null) {
			//	view.placeholderLabel = priv.createPlaceholderTextLabel();
			//	view.placeholderLabel.element.setAttribute("id", id + "-placeholder-label");
			//	state.container.element.appendChild(view.placeholderLabel.element);
			//} else {
			//	view.placeholderLabel = priv.reusePlaceholderTextLabel(exisitngPlaceholderLabel);
			//}

			// Dispose of a previous view if it exists
			if (previousView) {
				previousView.dispose();
			}

			// Add the view
			state.views[id] = view;
			priv.updateCanvasSize();

			return view;
		},

		// Returns the view with the given id
		getViewForId: function (id) {
			return state.views[id];
		},

		// Returns a Map of all the current views
		getViews: function () {
			const mapObject = new Map();
			for (const [key, value] of Object.entries(state.views)) {
				mapObject.set(key, value);
			}
			return mapObject;
		},

		// Initializer. Takes the name of the container element in which
		// we will render the pips.
		initialize: function (containerId, displayLabel, displayAvatar) {
			var c = state.container;
			c.element = document.getElementById(containerId);
			c.height = c.element.clientHeight;
			c.width = c.element.clientWidth;
			c.id = containerId;

			if (displayLabel && exists(displayLabel)) {
				state.layout.showLabel = displayLabel;
			}

			if (displayAvatar && exists(displayAvatar)) {
				state.layout.showAvatar = displayAvatar;
			}

			state.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
			state.renderer.setPixelRatio(1);
			priv.updateCanvasSize();
			c.element.appendChild(state.renderer.domElement);

			state.renderer.domElement.addEventListener("click", priv.onCanvasClick, false);
			//state.renderer.domElement.style.zIndex = 5;
		},

		// Register a method to act as a listener for mouse clicks.
		// It returns a 'view', and the listener should be in the form:
		//		'function(obj)'
		registerClickListener: function (listener) {
			state.clickListeners.push(listener);
		},

		// Removes a view with the given id from the widget.
		removeView: function (id) {
			if (state.views[id]) {
				var view = state.views[id];
				view.label?.element && state.container.element.removeChild(view.label?.element);
				view.menu?.element && state.container.element.removeChild(view.menu?.element);
				if (view.image?.element && ($(view.image.element).find('audio,video').length > 0)) {
					console.warn(`Removing pip for ${id} that has audio/video elements in it. This may not be desired`);
				}
				view.image?.element && state.container.element.removeChild(view.image?.element);
				view.htmlOverlay?.element && state.container.element.removeChild(view.htmlOverlay?.element);
				view.htmlBackground?.element && state.container.element.removeChild(view.htmlBackground?.element);
				view.placeholderLabel?.element && state.container.element.removeChild(view.placeholderLabel?.element);
				delete state.views[id];
				view = null;

				priv.updateCanvasSize();
			}
		},

		// When called, this will render the current frame to the widget.
		render: function () {
			state.updateElapsedTime();

			var layout = state.layout;
			priv.updateSize();
			var objects = priv.objectsToRender();

			var numViews = objects.length;
			var pipSize = layout.pipSize;
			var halfPipSize = pipSize / 2.0;

			if (numViews === 0) {
				state.renderer.clear();
			} else if (layout.mode === LAYOUTMODE_GRID) {
				// console.log("render grid");
				var viewportsAcross = layout.viewPortsAcross;
				var numRows = Math.ceil(numViews / viewportsAcross);

				for (var i = 0; i < numViews; i++) {
					var view = objects[i];

					var row = Math.floor(i / viewportsAcross);
					var col = (i % viewportsAcross);

					switch (layout.pipCellLayoutMode) {
						case LABEL_INSIDE_BOTTOM:
						case LABEL_INSIDE_TOP:
							view.left = col * pipSize;
							view.top = row * pipSize;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize,
								halfPipSize + (numRows - row - 1) * pipSize); //pass the center point into render
							break;
						case LABEL_OUTSIDE_ABOVE:
						case LABEL_OUTSIDE_BELOW:
						case PIPMODE_AVATAR:
							view.left = col * pipSize;
							view.top = row * pipSize;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize); //pass the center point into render
							break;
						default:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize);	//pass the center point into render
							console.log('WARNING: pipCellLayoutMode: ' + layout.pipCellLayoutMode + ' is not an option');
					}
				}
			}
			else if (layout.mode === LAYOUTMODE_SINGLEROW) {
				// console.log("render singlerow");
				for (var i = 0; i < numViews; i++) {
					var view = objects[i];
					var col = i;

					switch (layout.pipCellLayoutMode) {
						case LABEL_INSIDE_BOTTOM:
						case LABEL_INSIDE_TOP:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize); //pass the center point into render
							break;
						case LABEL_OUTSIDE_ABOVE:
						case LABEL_OUTSIDE_BELOW:
						case PIPMODE_AVATAR:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize); //pass the center point into render
							break;
						default:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize);	//pass the center point into render
							console.log('WARNING: pipCellLayoutMode: ' + layout.pipCellLayoutMode + ' is not an option');
					}
				}
			}
			else if (layout.mode === LAYOUTMODE_VIEWER_TOP) {
				//  console.log("render viewer_top");
				for (var i = 0; i < numViews; i++) {
					var view = objects[i];
					var col = i;

					switch (layout.pipCellLayoutMode) {
						case LABEL_INSIDE_BOTTOM:
						case LABEL_INSIDE_TOP:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize); //pass the center point into render
							break;
						case LABEL_OUTSIDE_ABOVE:
						case LABEL_OUTSIDE_BELOW:
						case PIPMODE_AVATAR:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize); //pass the center point into render
							break;
						default:
							view.left = col * pipSize;
							view.top = 0;
							view.renderer = state.renderer;
							view.render(view.left + halfPipSize, halfPipSize);	//pass the center point into render
							console.log('WARNING: pipCellLayoutMode: ' + layout.pipCellLayoutMode + ' is not an option');
					}
				}
			}
		},

		// Sets the background color of the pip & buttons.
		setBackgroundColor: function (r, g, b) {
			state.renderer.setClearColor(new THREE.Color('rgb(' + r + ',' + g + ',' + b + ')'));
		},

		// Sets a uniform margin on each side of the pip.
		setPipMargin: function (margin) {
			state.layout.pipMargin = margin;
		},

		// Returns the uniform margin size for the pips.
		getPipMargin: function () {
			return state.layout.pipMargin;
		},

		// Sets the label height for the pip names.
		setTextLabelHeight: function (height) {
			state.layout.textLabelHeight = height;
			priv.updateSize();
		},

		// Sets whether the label should be inside or below the pip
		setPipCellLayoutMode: function (mode) {
			mode = mode.toUpperCase();
			switch (mode) {
				case 'LABEL_INSIDE_BOTTOM':
					// TODO
					break;
				case 'LABEL_INSIDE_TOP':
					state.layout.pipCellLayoutMode = LABEL_INSIDE_TOP;
					priv.updateCanvasSize();
					break;
				case 'LABEL_OUTSIDE_ABOVE':
					// TODO
					break;
				case 'LABEL_OUTSIDE_BELOW':
					state.layout.pipCellLayoutMode = LABEL_OUTSIDE_BELOW;
					priv.updateCanvasSize();
					break;
				case 'PIPMODE_AVATAR':
					state.layout.pipCellLayoutMode = PIPMODE_AVATAR;
					priv.updateCanvasSize();
					break;
				default:
					console.log('WARNING: pipCellLayoutMode ' + mode + ' is an invalid mode in EveryScape.Pip');
			}

		},

		// Sets the number of columns in the widget.
		setViewportsAcross: function (numViewports) {
			state.layout.viewPortsAcross = numViewports;
			priv.updateCanvasSize();
		},

		setLayoutMode: function (mode) {
			switch (mode) {
				case LAYOUTMODE_GRID:
					// console.log("setLayoutMode Grid");
					state.layout.mode = LAYOUTMODE_GRID;
					priv.updateCanvasSize();
					break;
				case LAYOUTMODE_SINGLEROW:
					// console.log("setLayoutMode Singlerow");
					state.layout.mode = LAYOUTMODE_SINGLEROW;
					priv.updateCanvasSize();
					break;
				case LAYOUTMODE_VIEWER_TOP:
					// console.log("setLayoutMode viewer_top");
					state.layout.mode = LAYOUTMODE_VIEWER_TOP;
					priv.updateCanvasSize();
					break;
				default:
					console.log('WARNING: ' + mode + ' is an invalid layout mode in EveryScape.Pip');
			}
		},

		getLayoutMode: function () {
			return state.layout.mode;
		},

		// any value ( 0 < v < 1) will slow down animation.
		// any value > 1 will speed up animation.
		setAnimationDampener: function (value) {
			state.elapsedTimeDampener = value;
		}
	};

	return pub;
};

// An object that contains the shader instructions used above.
EveryScape.Pip.Shaders = {
	fragShader: `
        uniform sampler2D texture1;
        uniform float vpCenterX;	// Center of the viewport width
        uniform float vpCenterY;	// Center of the viewport height
        uniform float pipSize;		// The size of the pip.
        uniform float margin;       // The margin on each side of the pip.

		varying vec2 vUv;
		
		float opacityAtCoord_Circle(vec4 fragCoord) {
            float len = length( vec2(fragCoord.x, fragCoord.y) - vec2(vpCenterX, vpCenterY));
            float radius = pipSize / 2.0;
            float beginFadeEdge = radius * 0.9;
            float endFadeEdge = radius * 0.93;

            if (len > beginFadeEdge ) {
                if (len > endFadeEdge) {
                    return 0.0;
                }
                else {
                    float a = ((len - beginFadeEdge) / (endFadeEdge - beginFadeEdge));
                    return 1.0 - a * a;
                }
			}
			
			return 1.0;
		}

		float opacityForCircle(float radius, vec2 center, vec2 fragCoord) {
            float len = length( fragCoord - center);
            float beginFadeEdge = radius * 0.99;
            float endFadeEdge = radius;

            if (len > beginFadeEdge ) {
                if (len > endFadeEdge) {
                    return 0.0;
                }
                else {
                    float a = ((len - beginFadeEdge) / (endFadeEdge - beginFadeEdge));
                    return 1.0 - a * a;
                }
			}
			
			return 1.0;
		}
		
		float opacityAtCoord_Square(vec4 fragCoord, float cornerRadius) {
			float x = fragCoord.x;
			float y = fragCoord.y;

			float size = pipSize;

			float minX = (vpCenterX - size / 2.0) + margin;
			float maxX = (vpCenterX + size / 2.0) - margin;
			float minY = (vpCenterY - size / 2.0) + margin;
			float maxY = (vpCenterY + size / 2.0) - margin;

			if (x > maxX || x < minX || y > maxY || y < minY) {
				return 0.0;
			}
			else if (x < (minX + cornerRadius)) {
				if (y < (minY + cornerRadius)) {
					vec2 center = vec2(	minX + cornerRadius, 
										minY + cornerRadius);
					return opacityForCircle(cornerRadius, center, vec2(x,y));
				}
				else if (y > (maxY - cornerRadius)) {
					vec2 center = vec2(	minX + cornerRadius, 
										maxY - cornerRadius);
					return opacityForCircle(cornerRadius, center, vec2(x,y));
				}
			}
			else if (x > (maxX - cornerRadius)) {
				if (y < (minY + cornerRadius)) {
					vec2 center = vec2(	maxX - cornerRadius, 
										minY + cornerRadius);
					return opacityForCircle(cornerRadius, center, vec2(x,y));
				}
				else if (y > (maxY - cornerRadius)) {
					vec2 center = vec2(	maxX - cornerRadius, 
										maxY - cornerRadius);
					return opacityForCircle(cornerRadius, center, vec2(x,y));
				}
			}

			return 1.0;
		}

        void main() {
			gl_FragColor = texture2D(texture1, vUv);
			gl_FragColor.a = opacityAtCoord_Square(gl_FragCoord, 12.0);
        }`,
	vertShader: `
        varying vec2 vUv;

        void main() {
            vUv = uv;
            gl_Position =   projectionMatrix *
                            modelViewMatrix *
                            vec4(position,1.0);
        }`
};
